<template>
  <AppCard
    :title="$t('hub_apps_step_title_tv')"
    :description="$t('hub_apps_step_lead_tv')"
    :graphicBgColor="graphicBgColor"
  >
    <template v-slot:graphic>
      <TvAppGraphic />
    </template>

    <template v-slot:actions>
      <template v-if="isSubscribed">
        <a
          :href="appStoreList.apple"
          class="hub__btn hub__btn--icon-only btn--animated"
        >
          <AppleIcon />
        </a>

        <a
          :href="appStoreList.google"
          class="hub__btn hub__btn--icon-only btn--animated"
        >
          <AndroidIcon />
        </a>
      </template>

      <a
        v-else
        href="/subscribe"
        class="hub__btn hub__btn--subscribe tw-bg-green"
      >
        <span>{{ $t("hub_apps_step_sub_btn") }}</span>
      </a>
    </template>
  </AppCard>
</template>

<script>
import AppCard from "./AppCard.vue";
import TvAppGraphic from "../graphics/TvAppGraphic.vue";
import AndroidIcon from "../graphics/AndroidIcon.vue";
import AppleIcon from "../graphics/AppleIcon.vue";

export default {
  name: "TvAppCard",
  components: {
    AppCard,
    TvAppGraphic,
    AppleIcon,
    AndroidIcon,
  },
  props: {
    isSubscribed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appStoreList: {
        apple: "https://apps.apple.com/us/app/kidjo-tv-for-kids/id1095795448",
        google:
          "https://play.google.com/store/apps/details?id=net.kidjo.app.android",
        amazon:
          "https://www.amazon.com/Kidjo-Kids-Have-Fun-Learn/dp/B06X99VRWG",
        samsung: "samsungapps://ProductDetail/net.kidjo.app.samsung",
      },
      graphicBgColor: {
        from: "gradientLightBlue",
        via: "gradientDarkBlue",
        to: "gradientDarkBlue",
      },
    };
  },
};
</script>
