<template>
	<div class="one-row">
		
		<div class="subscription-content">
			<div class="subscription-header">
				<span class="step">{{ $t('subscription_pages_steps_text', {step: active_step}) }}</span>
				<span v-for="(title, index) in titles" :key="index">
					<span class="primary" v-if="title.status">{{ title.name }}</span>
				</span>
			</div>

			
			<div v-show="active_step == 1">
				<div class="separator-with-text"><span>{{ $t('plan_select_page_then_gray_separator') }}</span></div>
				
				<div class="selectable-features">
					<div class="feature" :class="paymentPlan.status == true ? 'active' : ''" @click="selectPlan()">
						<div class="box">
							<span class="check-hole"><img src="../assets/images/ic_check.png" v-show="paymentPlan.status == true"></span>

							<span class="title">{{ paymentPlan.product }}</span>
							<span class="price">{{ paymentPlan.price }}</span>
						</div>
					</div>

				</div>
				<div class="feature-continue">
					<button @click="showPaymentOptions" class="green-button btn-gn animated-button">{{ $t('plan_select_page_start_button') }}</button>
					<span>{{ $t('plan_select_page_under_start_button_text') }}</span>
				</div>
			</div>
			<div v-show="active_step == 2">
				<div class="payment-methods">
					<span class="payment-header"><img src="../assets/images/lock.png"> {{ $t('payment_select_page_secure_payment_text') }}</span>
					<div class="cupons-check" v-if="has_coupon == false && showCouponError != true">
						<div class="promo-text">
							<span><img src="../assets/images/ic_tag.png"> Code promo</span>	
						</div>
						<div class="check-form">
							<form @submit.prevent="checkCoupon">
								<input :class="coupon_error == true ? 'has-error' : ''" type="text" name="coupon" placeholder="RFZTH5" v-model="coupon">
								<button type="submit"><img src="../assets/images/ic_check_white.png"></button>
							</form>
						</div>
					</div>
					<div class="cupons-check" v-else-if="showCouponError != true">
						<span class="coupon">
							{{ $t('plan_select_page_promo_with_coupon', {couponBonus: getCouponDuration}) }} {{ getCoupon != '' ? getCouponProduct : '' }}
						</span>
					</div>
					<div class="cupons-check" v-if="showCouponError">
						<span class="coupon">{{ $t('coupons_popup_title') }} {{ getCouponProduct }}</span>
					</div>
					<div class="payment-methods-options" @click="showCardForm">
						<div  class="flex-grid payment-methods-option">
							<span class="text">{{ $t('payment_select_page_card_option_text') }}</span>
							<span class="image"><img src="../assets/images/cards.svg" alt="Credit or debit cards"></span>
							<span class="arrow"><img src="../assets/images/arrow-right.svg"></span>	
						</div>

						<div class="flex-grid payment-methods-option paypal">
							<span class="image"><img src="../assets/images/pay_pal_full_color_horizontal.svg" alt="PayPall"></span>
							<span class="text"></span>
							<span class="arrow"><img src="../assets/images/arrow-right.svg"></span>
						</div>
						<div id="paypalButton" class="hide-over-button"></div>
						
					</div>
				</div>
			</div>
			<div v-show="active_step == 3">
				<div class="subscription-content">

					<div class="card-details">
						<span class="details-header"><img src="../assets/images/cards.svg"></span>
						
						<div class="card-form">
							<div class="card-input" id="cardHolderName"></div>
							<div class="card-input" id="creditCardNumber"></div>
							<div class="card-input" id="expireDate"></div>
							<div class="card-input" id="cvv"></div>
						</div>

						<div class="modify-plan">
							<span>{{ activePlan }}</span>
							<span><a @click="showPlanSelectForm">{{ $t('card_details_page_modify_plan_link') }}</a></span>
						</div>
					</div>

					<div class="continue">
						<button @click.prevent="payWithCreditCard" class="green-button animated-button credit-card-button">
							<span class="loading" style="margin:auto" v-if="cc_button_loading"></span>
							<span v-else>{{ $t('card_details_page_activate_button') }}</span>
						</button>
						<a class="back-link" @click="showPaymentOptions">{{ $t('card_details_page_back_to_payment_options') }}</a>
						<span class="small-text">{{ $t('card_details_page_small_text') }}</span>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import { client, hostedFields, paypalCheckout, dataCollector } from 'braintree-web';
	import SubscriptionService from '../services/subscription.service';
	import paypal from 'paypal-checkout';

	import PaymenthPlansService from '../services/paymenth.plans.service';
    import AuthService from '../services/auth.service';

	export default {

		data() {
			return {
				paymentPlan: '',

				titles: [
					{name: this.$t('plan_select_page_title'), status: true, step: 1},
					{name: this.$t('payment_select_page_title'), status: false, step: 2},
					{name: this.$t('card_details_page_title'), status: false, step: 3},
				],

				cc_button_loading: false,

				active_step: 1,
				error: "",

				client_token: '',
				paypalNonce: '',
				device_data_paypal: '',
				braintreeNonce: '',
				device_data_braintree: '',
				hostedFieldsInstance: false,

				paypal_instaled: false,
				braintree_instaled: false,

				subscriptions: [],

				coupon: '',
				couponDurationCode: '',
				coupon_error: false,
				has_coupon: false,
				couponProduct: '',
				showCouponError: false
			};
		},

		computed: {
			activePlan: function() {

				return this.$t(this.paymentPlan.product + ' - ' + this.paymentPlan.price);
			},

			getCoupon: function() {
				if (this.coupon) return this.coupon;

				return '';
			},

			getCouponDuration: function() {
				const duration_period = {
					day: this.$t('day'),
					days: this.$t('days'),
					month: this.$t('month'),
					months: this.$t('months'),
				};

				const value = this.couponDurationCode;

				const duration = value.replace(/\D/g, '');
        let suffix = 'month';

        if (value.includes('D')) {
          suffix = 'day';
        }

        if (duration !== '1') {
          suffix += 's';
        }

        return `${duration} ${duration_period[suffix]}`;
			},

			getCouponProduct: function() {
				if (this.couponProduct == "KIDJO_TV") {
					return "- Kidjo TV";
				} else if (this.couponProduct == "KIDJO_BOOKS") {
					return "- Kidjo Stories";
				} else {
					return "- Kidjo TV + Kidjo Stories";
				}
			}

		},

		methods: {
			checkCoupon: function() {
				if (this.coupon != '') {
					SubscriptionService.checkCoupon(this.coupon)
						.then((response) => {
							if (response.status == 201) {
								this.saveCoupon(response.data);
								
								if (response.data.couponType == "FREE_ACCESS_COUPON") {
									this.removeCouponFromStorage();

									return this.$router.push('/products').catch(()=>{});
								}

								var forProduct = 'combo';

								if (response.data.couponProduct == "KIDJO_BOOKS") {
									forProduct = 'books';
								} else if (response.data.couponProduct == "KIDJO_TV") {
									forProduct = 'tv';
								}

								if (this.subscriptionFor == "combo") {
									this.couponProduct = "KIDJO_TV_BOOKS";
								} else {
									if (this.subscriptionFor != forProduct) {
										// show message for user
										this.showCouponError = true;

										setTimeout(() => {
											this.showCouponError = false;
											this.removeCoupon();
										}, 3000);
									}
								}

								this.has_coupon = true;
							}
						}).catch((error) => {
							this.removeCoupon();
							if (error.response.data.code == 3) {
								return this.$parent.showFlashMessage(this.$t('error_message_coupon_invalid'));
							}

							
							if (error.response.data.code == 4 || error.response.data.code == 5 || error.response.data.code == 6) {
								var product = '';
								if (error.response.data.couponProduct == "KIDJO_TV") {
									product = "- Kidjo TV";
								} else if (error.response.data.couponProduct == "KIDJO_BOOKS") {
									product = "- Kidjo Stories";
								} else {
									product = "- Kidjo TV + Kidjo Stories";
								}

								return this.$parent.showFlashMessage(this.$t('еrror_coupon_invalid_for_this_app') + product);
							}
						});
				} else {
					this.coupon_error = true;
				}
			},

			saveCoupon: function(data) {
				this.coupon = data.couponSerialNumber;
				this.couponDurationCode = data.couponDurationCode;
				this.couponProduct = data.couponProduct;
			},

			removeCoupon: function() {
				this.coupon = '';
				this.couponDurationCode = '';
				this.couponProduct = '';
				this.has_coupon = false;
			},

			removeCouponFromStorage: function() {
				localStorage.removeItem('coupon');
				localStorage.removeItem('couponDurationCode');
				localStorage.removeItem('couponProduct');
			},

			showPlanSelectForm: function() {
				this.active_step = 1;
				this.changeActiveTitle();
			},

			showPaymentOptions: function() {
				if (this.paymentPlan.status == true) {
					this.active_step = 2;
					this.changeActiveTitle();

					if (!this.paypal_instaled) {
						this.$parent.loading = true;
					}
				} else {
					this.$parent.showFlashMessage(this.$t('select_plan_please_select_plan'));
				}
				
			},

			showCardForm: function() {
				this.active_step = 3;
				this.changeActiveTitle();
				this.scrollDown();

				if (!this.braintree_instaled) {
					this.$parent.loading = true;
				}
			},

			changeActiveTitle: function(){
				for (var i = 0; i < this.titles.length; i++) {
					this.titles[i].status = false;

					if (this.titles[i].step == this.active_step) {
						this.titles[i].status = true;
					}
				}
			},

			selectPlan: function(planIndex) {
				this.paymentPlan.status = !this.paymentPlan.status;
			},

			payWithCreditCard: function() {
				var self = this;
				this.hostedFieldsInstance.tokenize().then(payload => {
					self.braintreeNonce = payload.nonce;
					self.sendNonce(payload.nonce, payload.details.cardHolderName);
				}).catch(error => {
					self.handleBraintreeErrors(error.code);
				});
			},

			sendNonce: function(nonce, name) {
				this.cc_button_loading = true;
				SubscriptionService.subscribe(nonce, name, this.device_data, this.paymentPlan.planId, this.getCoupon, this.paymentPlan.for)
					.then((response) => {
						if (response.status == 200) {
							if(localStorage.getItem('app')) { 
									var appName = localStorage.getItem('app')
									localStorage.removeItem('app')
									AuthService.generateRedirectKey()
									.then((response) => {
										if (response.status === 200) {
											this.cc_button_loading = false;
											this.$parent.loading = false;
											window.location.replace(this.routeBackToWebApp(appName) + '?key='+response.data.redirectKey);
										}
									}).catch((error) => {
										this.$parent.loading = false;
										this.cc_button_loading = false;
									});
							}  else {
								this.$router.push({name: 'home'}).catch(()=>{});
								this.cc_button_loading = false;
							}
						}
					}).catch((error) => {
						this.cc_button_loading = false;
						this.$parent.loading = false;

						if(error.response.status == 400) {
							this.$parent.showFlashMessage(error.response.data.errorMessage);
						}
					});
			},
            routeBackToWebApp: function(appName) {
				const actionMap = {
					['games']: process.env.VUE_APP_GAMES_APP_URL,
					['stories']: process.env.VUE_APP_STORIES_APP_URL,
					['tv']: process.env.VUE_APP_WEB_APP_URL,
					['kidjo-world']: process.env.VUE_APP_WEB_APP_LINK
				};
				return actionMap[appName];		
			},
			handleBraintreeErrors: function(code) {
				if (code == "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR") {
					this.$parent.showFlashMessage(this.$t('braintree_error_message_network_error'));
				}
				if (code == "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE") {
					this.$parent.showFlashMessage(this.$t('braintree_error_message_duplicated_payment_error'));
				}

				if (code == "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED") {
					this.$parent.showFlashMessage(this.$t('braintree_error_message_cvv_verification_failed'));
				}

				if (code == "HOSTED_FIELDS_FAILED_TOKENIZATION") {
					this.$parent.showFlashMessage(this.$t('braintree_error_message_faled_tokenization'));
				}

				if (code == "HOSTED_FIELDS_FIELDS_EMPTY") {
					this.$parent.showFlashMessage(this.$t('braintree_error_message_empty_fields'));
				}

				if (code == "HOSTED_FIELDS_FIELDS_INVALID") {
					this.$parent.showFlashMessage(this.$t('braintree_error_message_invalid_fields'));
				}
			},

			genereteClientToken: function() {
				SubscriptionService.generateToken()
					.then((response) => {
						if (response.status == 200) {
							this.client_token = response.data;
							this.initPaypal();
							this.initBraintree();
						}
					}).catch((error) => {
						if (error.response.status == 400) {
							if (error.response.data.code == 1) {
								this.$parent.showFlashMessage(this.$t('braintree_error_message_problem_generate_client_token'));
							}	
						}
						console.log(error);
					});
			},

			initBraintree: function() {
				var self = this;

				client.create({
					authorization: self.client_token
				}, function(err, clientInstance) {
					if (err) {
						console.error(err);
						return;
					}
					dataCollector.create({
						client: clientInstance,
						kount: true
					}, function (err, dataCollectorInstance) {
						if (err) {
							return;
						}
						var myDeviceData = dataCollectorInstance.deviceData;
						self.device_data_braintree = myDeviceData;
					});
					hostedFields.create({
						client: clientInstance,
						styles: {
							'color': '#a7948d',
							'font-family': 'BreeRegular',
							'font-weight': 600,
							'font-size': '18px',
							'opacity': 1,
							'font-variant-numeric': 'tabular-nums'
						},
						fields: {
							cardholderName: {
								selector: '#cardHolderName',
								placeholder: 'Card Holder Name'
							},
							number: {
								selector: '#creditCardNumber',
								placeholder: 'Card number'
							},
							cvv: {
								selector: '#cvv',
								placeholder: 'Cryptogram (CVV)'
							},
							expirationDate: {
								selector: '#expireDate',
								placeholder: 'Expiration date (MM / YY)'
							}
						}
					}, function(err, hostedFieldsInstance) {
						console.log(err);
						self.hostedFieldsInstance = hostedFieldsInstance;
						self.braintree_instaled = true;
						self.$parent.loading = false;
					});
				});
			},

			initPaypal: function() {
				var self = this;
				client.create({
					authorization: self.client_token
				}, function (clientErr, clientInstance) {
					if (clientErr) {
						console.error('Error creating client:', clientErr);
						return;
					}

					paypalCheckout.create({
						client: clientInstance
					}, function (paypalCheckoutErr, paypalCheckoutInstance) {
						dataCollector.create({
							client: clientInstance,
							paypal: true
						}, function (err, dataCollectorInstance) {
							if (err) {
								return;
							}
							var myDeviceData = dataCollectorInstance.deviceData;
							self.device_data_paypal = myDeviceData;
						});
						if (paypalCheckoutErr) {
							console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
							return;
						}
						paypal.Button.render({
							env: 'sandbox', // 'production' or 'sandbox'
							style: {
								label: 'paypal',
								size: 'responsive',
								shape: 'rect',
								color: 'white',
								tagline: false
							},
							payment: function () {
								return paypalCheckoutInstance.createPayment({
									flow: 'vault',
								});
							},
							onAuthorize: function (data, actions) {
								return paypalCheckoutInstance.tokenizePayment(data)
									.then(function (payload) {
										self.paypalNonce = payload.nonce;
										self.$parent.loading = true;
										self.sendNonce(payload.nonce,'')
									});
							},
							onCancel: function (data) {
								console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
							},
							onError: function (err) {
								console.error('checkout.js error', err);
							}
						}, '#paypalButton').then(function () {
							self.paypal_instaled = true;
							self.$parent.loading = false;
						});

					});

				});
			},

			scrollDown: function() {
				if(window.innerWidth <= 768) {
					setTimeout(function() {
						window.scrollTo(0, 120);
					}, 1000)
				}
			},

			setPlan: function() {
				let durationCode = (this.subscriptions[0].paymentStateId == "Free_Campaign" ? 'P1M' : this.subscriptions[0].durationCode);
				var type = '';

				if (this.subscriptions[0].subscriptionType == 'kidjo_tv') {
					type = 'kidjo_books';
				} else {
					type = 'kidjo_tv';
				}

				var plan = PaymenthPlansService.getPromoPlan(durationCode, type);

				this.paymentPlan = plan;
				this.paymentPlan.status = true;
			},
		},
		mounted: function() {
			this.subscriptions = this.$store.getters.getSubscriptionsList;

			for (var i = 0; i < this.subscriptions.length; i++) {
				if(this.subscriptions[i].subscriptionType == 'kidjo_tv_books') {
					return this.$router.push({name: 'home'});
				}
			}

			this.setPlan();

			if (this.$parent.appStyle == 'profile') {
				this.$parent.changeAppStyle('subscribe');
			}

			this.genereteClientToken();
			this.scrollDown();
		}
	}
</script>
<style lang="sass" scoped>
	.selectable-features
		justify-content: center

	.hide-over-button
		opacity: 0
		margin-top: -65px

		@media (max-width: 768px)
			margin-top: -60px

		@media (max-width: 768px)
			margin-top: -55px
</style>