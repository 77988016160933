export default () => {
    const plans = [
        {
            nameKey: 'monthly_plan',
            durationCode: 'P1M',
            status: true,
            plans: [
                {
                    planId: "kidjo_server_plan_5_monthly_7f",
                    old: "kidjo_server_plan_2_monthly_7f",
                    price: "4.99 $",
                    product: "Kidjo TV",
                    status: true,
                    for: 'tv',
                    promoTitle: "subscribe_page_product_tv_title"
                },
                {
                    planId: "kidjo_books_server_plan_6_monthly_7f",
                    old: "kidjo_books_server_plan_6_monthly_7f",
                    price: "4.99 $",
                    product: "Kidjo Stories",
                    status: true,
                    for: 'books',
                    promoTitle: "subscribe_page_product_books_title"
                },
                {
                    planId: "kidjo_games_server_plan_6_monthly_7f",
                    old: "kidjo_games_server_plan_6_monthly_7f",
                    price: "4.99 $",
                    product: "Kidjo Games",
                    status: true,
                    for: 'games',
                    promoTitle: "subscribe_page_product_games_title",
                }
            ],
            comboPlans: {
                'tv_books': {
                    for: 'books',
                    price: '7.99 $',
                    planId: 'kidjo_tv_and_books_server_plan_12_monthly_7f',
                    promoTitle: "subscribe_page_product_books_title",
                    status: true,
                },
                'tv_games': {
                    for: 'games',
                    price: '7.99 $',
                    planId: 'kidjo_tv_and_games_plan_13_monthly_7f',
                    status: true,
                    promoTitle: "subscribe_page_product_games_title",
                },
                'books_tv': {
                    for: 'tv',
                    price: '7.99 $',
                    planId: 'kidjo_tv_and_books_server_plan_12_monthly_7f',
                    promoTitle: "subscribe_page_product_tv_title",
                    status: true,
                },
                'books_games': {
                    for: 'games',
                    price: '7.99 $',
                    planId: 'kidjo_books_and_games_plan_14_monthly_7f',
                    status: true,
                    promoTitle: "subscribe_page_product_games_title",
                },
                'games_tv': {
                    for: 'tv',
                    price: '7.99 $',
                    planId: 'kidjo_tv_and_games_plan_13_monthly_7f',
                    promoTitle: "subscribe_page_product_tv_title",
                    status: true,
                },
                'games_books': {
                    for: 'books',
                    price: '7.99 $',
                    planId: 'kidjo_books_and_games_plan_14_monthly_7f',
                    promoTitle: "subscribe_page_product_books_title",
                    status: true,
                },

                'tv_books_games': {
                    planId: 'kidjo_tv_books_and_games_plan_13_monthly_7f',
                    for: 'tvStoriesGamesCombo',
                    price: '9.99 $',
                    status: true,
                }
            },
            addOnPlans:{
                'tv_books': {
                    for: 'books',
                    price: '7.99 $',
                    planId: 'default_one_product_2',
                    promoTitle: "subscribe_page_product_books_title",
                    status: true,
                },
                'tv_games': {
                    for: 'games',
                    price: '7.99 $',
                    planId: 'default_one_product_2',
                    status: true,
                    promoTitle: "subscribe_page_product_games_title",
                },
                'books_tv': {
                    for: 'tv',
                    price: '7.99 $',
                    planId: 'default_one_product_2',
                    promoTitle: "subscribe_page_product_tv_title",
                    status: true,
                },
                'books_games': {
                    for: 'games',
                    price: '7.99 $',
                    planId: 'default_one_product_2',
                    status: true,
                    promoTitle: "subscribe_page_product_games_title",
                },
                'games_tv': {
                    for: 'tv',
                    price: '7.99 $',
                    planId: 'default_one_product_2',
                    promoTitle: "subscribe_page_product_tv_title",
                    status: true,
                },
                'games_books': {
                    for: 'books',
                    price: '7.99 $',
                    planId: 'default_one_product_3',
                    promoTitle: "subscribe_page_product_books_title",
                    status: true,
                },

            }

        },
        {
            nameKey: 'annual_plan',
            durationCode: 'P1Y',
            status: false,
            plans: [
                {
                    planId: "kidjo_server_plan_3_12months_7f",
                    old: "kidjo_server_plan_3_12months_7f",
                    price: "34.90 $",
                    product: "Kidjo TV",
                    status: true,
                    for: 'tv',
                    promoTitle: "subscribe_page_product_tv_title"
                },
                {
                    planId: "kidjo_books_server_plan_8_12months_7f",
                    old: "kidjo_books_server_plan_8_12months_7f",
                    price: "34.90 $",
                    product: "Kidjo Stories",
                    status: true,
                    for: 'books',
                    promoTitle: "subscribe_page_product_books_title"
                },
                {
                    planId: "kidjo_games_server_plan_8_12months_7f",
                    old: "kidjo_games_server_plan_8_12months_7f",
                    price: "34.90 $",
                    product: "Kidjo Games",
                    status: true,
                    for: 'games',
                    promoTitle: "subscribe_page_product_games_title",
                }
            ],
            comboPlans: {
                'tv_books': {
                    for: 'tvBooksCombo',
                    price: '55.90 $',
                    planId: "kidjo_tv_and_books_server_plan_11_12months_7f"
                },
                'books_games': {
                    for: 'storiesGamesCombo',
                    price: '55.90 $',
                    planId: "kidjo_books_and_games_plan_14_12months_7f"
                },
                'tv_games': {
                    for: 'tvGamesCombo',
                    price: '55.90 $',
                    planId: "kidjo_tv_and_games_plan_13_12months_7f"
                },
                'tv_books_games': {
                    for: 'tvBooksGamesCombo',
                    price: '70.90 $',
                    planId: "kidjo_tv_books_and_games_plan_13_12months_7f"
                }
            },
            addOnPlans: {
                'tv_books': {
                    for: 'books',
                    price: '55.90 $',
                    planId: 'default_one_product_yearly_2',
                    promoTitle: "subscribe_page_product_books_title",
                    status: true,
                },
                'tv_games': {
                    for: 'games',
                    price: '55.90 $',
                    planId: 'default_one_product_yearly_2',
                    status: true,
                    promoTitle: "subscribe_page_product_games_title",
                },
                'books_tv': {
                    for: 'tv',
                    price: '55.90 $ $',
                    planId: 'default_one_product_yearly_2',
                    promoTitle: "subscribe_page_product_tv_title",
                    status: true,
                },
                'books_games': {
                    for: 'games',
                    price: '55.90 $ $',
                    planId: 'default_one_product_yearly_2',
                    status: true,
                    promoTitle: "subscribe_page_product_games_title",
                },
                'games_tv': {
                    for: 'tv',
                    price: '55.90 $ $',
                    planId: 'default_one_product_yearly_2',
                    promoTitle: "subscribe_page_product_tv_title",
                    status: true,
                },
                'games_books': {
                    for: 'books',
                    price: '55.90 $ $',
                    planId: 'default_one_product_yearly_2',
                    promoTitle: "subscribe_page_product_books_title",
                    status: true,
                },

            }
        }
    ];

    return plans;
}
