<template>
  <label class="root">
    <input type="checkbox" v-model="checked" :disabled="disabled"/>
    <span class="checkmark"></span>
  </label>
</template>

<script>

export default {
  name: "Checkbox",
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed:{
    checked:{
      get(){
        return this.isChecked
      },
      set(value){
        this.$emit('onChange', value)
      }
    }
  },
};
</script>

<style lang="scss">
.root {
  position: relative;
  cursor: pointer;
}

.root input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 2px solid #6d4c41;
  background-color: #fff; /* Default background color */
}

.root input:checked ~ .checkmark {
  background-color: #ffac00; /* Background color when checkbox is checked */
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.root input:checked ~ .checkmark:after {
  display: block;
}

.root .checkmark:after {
  width: 10px;
  height: 15px;
  border-radius: 1px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: translateY(-2px) rotate(45deg);
}
</style>
