<template>
  <div
    class="root tw-py-2.5 tw-px-2 tw-flex tw-flex-row tw-items-center tw-justify-center"
    :class="`tw-flex-${orientationMapped} tw-w-${width} tw-h-${height}`"
  >
    <div
      class="tw-mx-4 tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-w-92px tw-h-92px tw-rounded-2xl graphic"
      :class="graphicBgGradient"
    >
      <slot name="graphic" />
    </div>
    <div class="tw-flex tw-flex-col" :class="contentAlignment">
      <div class="tw-my-2.5" :class="textAlignment">
        <p class="tw-font-nunito-extrabold tw-leading-4">{{ title }}</p>
        <span class="tw-block description">{{ description }}</span>
      </div>
      <div class="tw-flex tw-justify-center">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    graphicBgColor: {
      type: Object,
      default: undefined,
    },
    orientation: {
      type: String,
      default: "vertical",
    },
  },
  computed: {
    orientationMapped() {
      const orientationMapping = {
        vertical: "col",
        horizontal: "row",
      };

      return orientationMapping[this.orientation];
    },
    graphicBgGradient() {
      if (!this.graphicBgColor) {
        return undefined;
      }

      const { from, via, to } = this.graphicBgColor;

      return `tw-bg-gradient-to-b tw-from-${from} tw-via-${via} tw-to-${to}`;
    },
    width() {
      return this.orientationMapped === "row" ? "307px" : "150px";
    },
    height() {
      return this.orientationMapped === "row" ? "139px" : "234px";
    },
    contentAlignment() {
      return this.orientationMapped === "row" ? "tw-items-start" : undefined;
    },
    textAlignment() {
      return this.orientationMapped === "row" ? "tw-text-left" : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  background-color: rgba(41, 182, 246, 0.2);
  border-radius: 10px;
}
.graphic {
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset;
  border: solid #ffffff;
}
.description {
  font-size: 0.87rem;
}
</style>
