// URLS
export const GET_PLANS_URL = "/plans";
export const BRAINTREE_SUBSCRIPTION_URL = "/braintree/subscription";
export const USER_SUBSCRIPTIONS = "/subscriptions";
export const VALIDATE_COUPON="/coupon/validate/:couponCode";
export const TV_PROMO_CARDS_URL="/licenses";
export const STORIES_PROMO_CARDS_URL="/v1/licenses";
export const GAMES_PROMO_CARDS_URL="/v1/promotional-cards";
export const NOTIFY_UN_SUBSCRIPTION = "/notification/unsubscription";



// Promocode

export const DESCRIPTION_TRANSLATION_KEY = {
    tv: "subscribe_page_product_tv_title",
    books: "subscribe_page_product_books_title",
    games: "subscribe_page_product_games_title",
}

export const PRODUCT_TYPES = {
    'kidjo_tv': 'tv',
    'kidjo_games': 'games',
    'kidjo_books': 'books',
    'kidjo_tv_books': 'tv_books',
    'kidjo_tv_games': 'tv_games',
    'kidjo_books_games': 'books_games',
    'kidjo_tv_books_games': 'tv_books_games'
}

export const TV_COMBOS = ["kidjo_tv", "kidjo_tv_books", "kidjo_tv_games", "kidjo_tv_books_games"];

export const BOOK_COMBOS = ["kidjo_books", "kidjo_tv_books", "kidjo_books_games", "kidjo_tv_books_games"];

export const GAMES_COMBO = ["kidjo_games", "kidjo_tv_games", "kidjo_books_games", "kidjo_tv_books_games"];

export const PRODUCT_LABELS = {
    'tv': "TV",
    'books': "Stories",
    'games': "Games"
}


export function getSubscribedProducts(subscription) {
    let product = new Set();
    subscription.forEach((sub => {
        if (TV_COMBOS.includes(sub)) {
            product.add("tv")
        }

        if (BOOK_COMBOS.includes(sub)) {
            product.add("books")
        }

        if (GAMES_COMBO.includes(sub)) {
            product.add("games")
        }
    }));
    return Array.from(product);
}

export function getErrorString(status, code = null) {
    const actionMap = {
        [400]: () => {
            let msg = 'error_message_something_went_wrong';
            if (code === 2) {
                msg = 'error_message_email_invalid';
            } else if (code === 3) {
                msg = 'error_message_coupon_invalid'
            } else if (code === 5) {
                msg = 'error_message_email_already_taken'
            }
            return msg;
        },
        [404]: () => 'forgotten_password_error_error_email_not_found',
    }
    return status ? actionMap[status]() :  'error_message_something_went_wrong';
}