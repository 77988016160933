/**
 * @description Is null
 * @param val
 * @returns {boolean}
 */
export const isNull = (val) => val === null;

/**
 * @description Is array
 * @param val
 * @returns {boolean}
 */
export const isArr = (val) => Array.isArray(val);

/**
 * @description Is object
 * @param val
 * @returns {boolean}
 */
export const isObj = (val) =>
    typeof val === "object" && !isArr(val) && !isNull(val);

/**
 * @description Is number
 * @param val
 * @returns {boolean}
 */
export const isNum = (val) => typeof val === "number" && !Number.isNaN(val);

/**
 * @description Is function
 * @param val
 * @returns {boolean}
 */
export const isFunc = (val) => typeof val === "function";

/**
 * @description Is string
 * @param val
 * @returns {boolean}
 */
export const isStr = (val) => typeof val === "string";

/**
 * @description Is undefined
 * @param val
 * @returns {boolean}
 */
export const isUndef = (val) => typeof val === "undefined";

/**
 * @description Is boolean
 * @param val
 * @returns {boolean}
 */
export const isBool = (val) => typeof val === "boolean";

/**
 * @description To int
 * @param val
 * @returns {number}
 */
export const toInt = (val) => parseInt(val, 10);

/**
 * @description Is non empty string
 * @param val
 * @returns {boolean|boolean}
 */
export const isNonEmptyStr = (val) => isStr(val) && val !== "";

/**
 * @description Is non empty array
 * @param val
 * @returns {boolean|boolean}
 */
export const isNonEmptyArr = (val) => isArr(val) && val.length > 0;

/**
 * @description Has object field
 * @param obj
 * @param prop
 * @returns {boolean}
 */
export const hasObjField = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);

/**
 * @description Has object fields
 * @param obj
 * @param props
 * @returns {boolean}
 */
export const hasObjFields = (obj, props) => {
    if (!isArr(props)) {
        throw new Error("props should be array");
    }

    let hasMissingField = false;
    props.forEach((prop) => {
        hasMissingField = !hasObjField(obj, prop);
    });
    return !hasMissingField;
};

/**
 * @description Has object method
 * @param obj
 * @param method
 * @returns {boolean}
 */
export const hasObjMethod = (obj, method) =>
    hasObjField(obj, method) && isFunc(obj[method]);

/**
 * @description Get object keys
 * @param obj
 * @returns {Array}
 */
export const getObjKeys = (obj) => Object.keys(obj);

/**
 * @description Has object key
 * @param obj
 * @param key
 * @returns {boolean}
 */
export const hasObjKey = (obj, key) => getObjKeys(obj).includes(key);

/**
 * @description Is empty object
 * @param obj
 * @returns {boolean}
 */
export const isEmptyObj = (obj) => getObjKeys(obj).length === 0;

/**
 * @description Each object key
 * @param obj
 * @param callback
 */
export const eachObjKey = (obj, callback) => {
    getObjKeys(obj).forEach((key, index) => callback(key, obj[key], index));
};

/**
 * @description Each object value
 * @param obj
 * @param callback
 */
export const eachObjValue = (obj, callback) => {
    eachObjKey(obj, (key, value, index) => callback(value, key, index));
};

/**
 * @description Converts a snake_case string into a camelCase string
 * @param snakeText
 * @returns {string}
 */
export const snakeToCamel = (snakeText) => {
    const snakeParts = snakeText.split("_");

    if (snakeParts.length === 1) {
        return snakeText.charAt(0).toLowerCase() + snakeText.substr(1);
    }

    let camelText = "";
    snakeParts.forEach((part, index) => {
        let firstChar = part.charAt(0);
        firstChar = index === 0 ? firstChar.toLowerCase() : firstChar.toUpperCase();
        camelText += firstChar + part.substr(1);
    });
    return camelText;
};

/**
 * @description Converts an object with snake_case keys to an object with camelCase keys
 * @param snakeObj
 * @returns {{}}
 */
export const mapSnakeToCamel = (snakeObj) => {
    if (isArr(snakeObj)) {
        return snakeObj.map((obj) => {
            obj = isObj(obj) ? mapSnakeToCamel(obj) : obj;
            return obj;
        });
    }

    const camelObj = {};

    Object.keys(snakeObj).forEach((key) => {
        const camelKey = snakeToCamel(key);
        camelObj[camelKey] = snakeObj[key];

        if (isObj(camelObj[camelKey])) {
            camelObj[camelKey] = mapSnakeToCamel(camelObj[camelKey]);
        } else if (isArr(camelObj[camelKey])) {
            camelObj[camelKey] = camelObj[camelKey].map((obj) => {
                obj = isObj(obj) ? mapSnakeToCamel(obj) : obj;
                return obj;
            });
        }
    });
    return camelObj;
};

/**
 * @description Capitalize
 * @param text
 * @returns {string}
 */
export const capitalize = (text) =>
    `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

/**
 * @description Migrate object shape
 * @param fromObj
 * @param toObj
 * @param areEqual
 * @returns {{}}
 */
export const migrateObjShape = (fromObj, toObj, areEqual) => {
    const migratedState = {};

    eachObjKey(fromObj, (key, fromObjField) => {
        if (isUndef(toObj[key])) {
            migratedState[key] = isObj(fromObjField)
                ? migrateObjShape(fromObjField, {})
                : fromObjField;
        } else if (!areEqual(fromObj[key], toObj[key])) {
            migratedState[key] = fromObj[key];
        } else {
            migratedState[key] = toObj[key];
        }
    });

    return migratedState;
};

/**
 * @description Get aspect ratio height
 * @param width
 * @param ratioWidth
 * @param ratioHeight
 * @returns {number}
 */
export const getAspectRatioHeight = (width, ratioWidth, ratioHeight) =>
    (parseInt(width, 10) / ratioWidth) * ratioHeight;

/**
 * @description Debounce
 * @param id
 * @param timeout
 * @returns {function}
 */
export const debounce = (id, timeout = 150) => {
    const timers = {};

    return (callback) => {
        if (timers[id]) {
            clearTimeout(timers[id]);
        }
        timers[id] = setTimeout(callback, timeout);
    };
};

/**
 * @description Converts duration of seconds to minutes rounded to a whole number equal or greater than 1
 * @param {number} seconds
 * @returns {number}
 */
export const secondsToMinutes = (seconds) => {
    const minutes = seconds / 60;
    return minutes < 1 ? 1 : Math.round(minutes);
};

/**
 * @description Converts duration of seconds to an object containing hours, minutes and seconds
 * @param {number} duration
 * @returns {Object}
 */
export const secondsToFormattedTime = (duration) => {
    const hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((duration % (60 * 60)) / 60);
    const seconds = Math.floor(duration % 60);

    return {hours, minutes, seconds};
};

/**
 * @description Pads a 1-digit number with a zero
 * @param {number} number
 * @returns {string}
 */
export const padNumber = (number) =>
    number < 10 ? `0${number}` : String(number);

/**
 * @description Generates a version 4 universally unique identifier (UUID v4)
 * @returns {string}
 */
export const uuidv4 = () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });


export const showOnDev = () => process.env.VUE_APP_SHOW_ON_ENV === "dev"
/**
 * @description Indicates if an element should be visible on stage env
 * @returns {boolean}
 */
export const showOnStage = () => process.env.VUE_APP_SHOW_ON_ENV === "stage";

/**
 * @description Indicates if an element should be visible on prod env
 * @returns {boolean}
 */
export const showOnProd = () => process.env.VUE_APP_SHOW_ON_ENV === "prod";
