<template>
    <div class="form-container">
      <div class="form-container__img-wrap">
        <img
          src="@/assets/images/multi-devices-icon-alt.png"
          srcset="@/assets/images/multi-devices-icon-alt@2x.png 2x, @/assets/images/multi-devices-icon-alt@3x.png 3x"
        >
      </div>

      <span class="form-container__title" v-html="$t('publisher_index_title')"></span>
      <span class="form-container__lead">{{ $t('publisher_index_lead') }}</span>

      <!-- <div class="form-container__error">
        <span>{{ error.errorMessage }}</span>
      </div> -->

      <div class="form-container__wrap">
        <form class="form" @submit.prevent="onSubmit">
          <div class="textfield-wrap">
            <div class="textfield-wrap__input">
              <div class="textfield-wrap__label"><span>{{ $t('publisher_index_form_phone_label') }}</span></div>
              <div class="textfield-wrap__field">
                <div class="textfield-wrap__overlay-label"><span>@kidjo.tv</span></div>
                <input class="basic-textfield" 
                  type="text" 
                  name="email" 
                  :placeholder="$t('publisher_index_form_phone_placeholder')" 
                  v-model="phone"
                  required 
                >
              </div>
            </div>
            <div class="textfield-wrap__error"><span>{{ phoneErrors[0] }}</span></div>
          </div>

          <div class="textfield-wrap">
            <div class="textfield-wrap__input">
              <div class="textfield-wrap__label"><span>{{ $t('publisher_index_form_coupon_label') }}</span></div>
              <div class="textfield-wrap__field">
                <input class="basic-textfield"
                  type="text" 
                  name="coupon" 
                  :placeholder="$t('publisher_index_form_coupon_placeholder')" 
                  v-model="coupon"
                  required 
                >
              </div>
            </div>
            <div class="textfield-wrap__error"><span>{{ couponErrors[0] }}</span></div>
          </div>

          <button
            class="basic-btn green-btn animated-btn"
            type="submit"

            :class="(isDisabled ? 'basic-btn--disabled' : '')"
          >
            <span v-if="isSubmitted">Loading</span>
            <span v-else>{{ $t('publisher_index_form_submit_btn') }}</span>
          </button>
        </form>
      </div>

    </div>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  // import { required, helpers, minLength } from 'vuelidate/lib/validators';
  import { required, helpers } from 'vuelidate/lib/validators';
  import { isBool } from '@/utils';

  // const prefix = helpers.regex('prefix', /^\+(227|229)/);
  // const symbols = helpers.regex('symbols', /^(?=.*[0-9])[ +0-9]+$/);
  const symbols = helpers.regex('symbols', /^(?=.*[0-9])[ 0-9]+$/);

  export default {
    mixins: [validationMixin],
    validations: {
      phone: {
        required,
        // prefix,
        symbols,
        // minLength: minLength(12),
      },
      coupon: {
        required
      }
    },
    data() {
      return {
        phone: '',
        coupon: '',
        isSubmitted: false,
        error: '',
      }
    },
    computed: {
			canSubmit: function() {
        const { phone, coupon } = this

				return phone !== '' && coupon !== '';
			},
      isDisabled() {
        return !this.canSubmit && !this.isSubmitted;
      },
      userToken() {
        return this.$store.getters['getToken'];
      },
      hasToken() {
        return !isBool(this.userToken);
      },
      phoneErrors() {
        const errors = [];
        if (!this.$v.phone.$dirty) {
          return errors;
        }
        if (!this.$v.phone.required) {
          errors.push(this.$t('error_message_phone_required'));
        }
        // if (!this.$v.phone.prefix) {
        //   errors.push('Must start with +227 (Niger) or +229 (Benin)');
        // }
        // if (!this.$v.phone.symbols) {
        //   errors.push('Must only contain numbers, spaces and a plus sign');
        // }
        // if (!this.$v.phone.minLength) {
        //   errors.push('Must be atleast 12 symbols');
        // }
        if (!this.$v.phone.symbols) {
          errors.push(this.$t('error_message_num_space_only'));
        }
        return errors;
      },
      couponErrors() {
        const errors = [];
        if (!this.$v.coupon.$dirty) {
          return errors;
        }
        if (!this.$v.coupon.required) {
          errors.push(this.$t('error_message_coupon_required'));
        }
        return errors;
      },
    },
    mounted: function() {
      if (this.$parent.tvPlatform == true) {
        this.$router.push({name: 'login'}).catch(()=>{});
      }
    },
    methods: {
      onSubmit() {
        const { phone, coupon } = this;

        this.$v.$touch();

        if (this.$v.$invalid) {
          return false;
        }

        this.isSubmitted = true;

        this.$store.dispatch('publisher/subscribe', { id: 'sevamedia', phone, coupon })
          .then(() => this.$router.push({ name: 'products' }))
          .catch(({ response }) => {
            this.error = response.data;
            this.handleError(this.error.messageCode);
            
            this.isSubmitted = false;
          });
      },
      handleError: function(code) {
        this.$parent.showFlashMessage(this.responseTransformer(code, 'publisher'));
			},
      responseTransformer(code, prefix) {
        return this.$t(`${prefix}_${code.toLowerCase()}`);
      }
    },
  }
</script>

<style lang="scss">
  // DEV NOTE - will be removed after refactoring, those vars are not available globally yet
  $regular-font: 'Nunito-Regular', Arial, sans-serif;
  $bold-font: 'Nunito-ExtraBold', Arial, sans-serif;

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 0 1rem 40px;
    margin: 0 auto;

    &__img-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      padding: 0 10px;

      img {
        width: 100%;
        max-width: 500px;
      }
    }

    &__title {
      display: none;
      margin-bottom: 20px;
      font-size: 22px;
      font-family: $bold-font;
      color: #fff;
      text-shadow: 2px 0 0 rgba(109, 76, 65, 0.5);

      span { color: #fdad00; }

      @media (min-width: 768px) {
        display: block;
        font-size: 30px;
      }
    }

    &__lead {
      max-width: 428px;
      margin-bottom: 24px;
      font-family: $bold-font;
      font-size: 18px;
      color: #ffffff;

      @media (min-width: 768px) {
        font-size: 22px;
      }
    }

    &__error {
      margin-bottom: 24px;

      span {
        font-family: $regular-font;
        font-size: 18px;
        color: #fdad00;
        text-shadow: 2px 0 0 rgba(109, 76, 65, 0.3);
      }
    }

    &__wrap {
      width: 100%;
      max-width: 520px;
    }
  }

  .textfield-wrap {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    &__input {
      border-radius: 10px;
      background: #6d4c41;
    }
    
    &__label {
      padding: 6px 16px;
      text-align: left;

      span {
        font-family: $bold-font;
        font-size: 16px;
        color: #ffffff;
      }
    }

    &__field {
      position: relative;
    }

    &__overlay-label {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-family: $bold-font;
      color: #6d4c41;
    }

    &__error {
      padding-top: 2px;

      span {
        font-family: $bold-font;
        color: #fdad00;
        text-shadow: 1px 0 0 rgb(109 76 65 / 50%);
      }
    }

    @media (min-width: 768px) {
      &__input {
        display: flex;
      }

      &__label {
        width: 40%;
        line-height: 50px;

        span {
          font-size: 18px;;
        }
      }

      &__field {
        width: 60%;
      }
    }
  }

  .basic-textfield {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 3px solid #6d4c41;
    border-radius: 10px;
    padding: 17px 25px;
    font-family: $regular-font;
    font-size: 18px;
    color: #6d4c41;
    outline: none;

    @media (max-width: 768px) {
      padding: 15px 20px;
      border: 2px solid #6d4c41;
    }

    @media (max-width: 520px) {
      padding: 7px 20px;
    }

    &::placeholder {
      color: #6d4c41;
      opacity: 0.7;
    }

    &.has-error {
      border-color: #ff5722;
    }
  }

  .basic-btn {
    width: 90%;
    max-width: 280px;
    padding: 16px 0;
    margin-top: 10px;
    font-size: 24px;
    line-height: 1.1;
    font-family: $bold-font;
    text-decoration: none;
    color: #ffffff;
    background-color: #8bc34a;
    border: 2px solid #6d4c41;
    border-radius: 10px;
    cursor: pointer;
    outline: none;

    &--disabled {
      background-color: #979797;
    }

    @media (max-width: 768px) {
      padding: 10px 0;
    }

    @media (max-width: 520px) {
      width:82%;
      padding: 9px 0;
    }
  }
</style>
