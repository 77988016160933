<template>

	<div
		id="app"
		:class="[
			'app',
			{
				'full-width': isWhiteBackgroundSet,
				'with-background': !isWhiteBackgroundSet && !hasNoLayout,
				'with-no-overflow': isRegisterOrLogin,
			}
		]"
	>
		<!-- Flash Message -->
		<transition name="slide-fade">
			<update-email-overlay v-if="displayEmailModal && showEmailModal" @toggleModalDisplay="toggleModalDisplay"/>
		</transition>

		<!-- Flash Message -->
		<transition name="slide-fade">
			<flash-message :message="message" :type="messageType" v-if='showFlash'></flash-message>
		</transition>

		<!-- Loading Screen -->
		<loading-screen v-if="loading"></loading-screen>
    <!-- Navigation Header -->
    <navigation-header v-if="!hasNoLayout" />

		<div
			:class="{
				'tv-platfrom': tvPlatform,
				'container': !hasNoLayout,
			}"
		>
			<!-- Main Content -->
			<div :class="!hasNoLayout ? 'content' : ''">
				<router-view v-if="initialized"></router-view>
			</div>
		</div>
    <footer
        v-if="!tvPlatform && !hasNoLayout"
        id="footer"
        :class="{
					'light': !isWhiteBackgroundSet,
					'dark': isWhiteBackgroundSet,
					'login-register-footer': isRegisterOrLogin
				}"
    >
      <div class="separator"></div>
      <div class="footer-content">
        <div class="footer-heading">
           <a :href="websiteUrl + '/contact-us'">{{ $t('footer_section_have_questions') }} {{ $t('footer_section_contact_us') }}</a>
        </div>
        <div class="footer-links">
          <a :href="websiteUrl + '/faq'" class="link">{{ $t('footer_section_help_link') }}</a>
          <a :href="websiteUrl + '/terms'" class="link">{{ $t('footer_section_legal_notice_link') }}</a>
          <a :href="websiteUrl + '/privacy'" class="link">{{ $t('footer_section_privacy_link') }}</a>
          <a :href="websiteUrl + '/cookies'" class="link">{{ $t('footer_section_gscs_link') }}</a>
        </div>
      </div>
    </footer>

		<cookie-consent
			:href="websiteUrl + '/privacy'"
			:message="$t('cmp_text')"
			:linkLabel="$t('cmp_more')"
			:cookieExpiryDays="expire">
			<template slot="button">
				<div class="buttons">
					<button
						class="cookie-consent-compliance deny"
						type="submit"
						@click="expire = 0.01"
						tabindex="1"
					>{{ $t('cmp_deny') }}</button>
					<button
						class="cookie-consent-compliance"
						type="submit"
						tabindex="1"
					>{{ $t('cmp_allow') }}</button>
				</div>
			</template>
		</cookie-consent>
	</div>
</template>
<script>
	import AuthService from './services/auth.service';
	import LocationService from './services/location.service';
	import LoadingScreen from './components/LoadingScreen.vue';
	import UpdateEmailOverlay from './components/UpdateEmailOverlay.vue';
	import FlashMessage from './components/FlashMessage.vue';
	import Header from './components/Header.vue';
	import { isBool } from '@/utils';

	export default {
		components: {
			"loading-screen": LoadingScreen,
			"flash-message": FlashMessage,
			"update-email-overlay": UpdateEmailOverlay,
			"navigation-header": Header
		},
		data: function() {
			return {
				initialized: false,
				loading: true,
				lang: 'en',
				countryCode: 'en',
				showFlash: false,
				message: '',
				messageType: '',
				tvPlatform: false,
				showLoginQR: false,
				hasPremium: false,
				/* Two options ['profile, subscribe'] */
				appStyle: 'profile',
				websiteUrl: process.env.VUE_APP_WEBSITE_URL,
				expire: 30,
				publicRoutes: ['login', 'register', 'forgotten-password', 'swisscom', 'publisher-sevamedia', 'intermarche', 'intermarche-alt','promo', 'logout','test'],
				whiteBgRoutes: ['products'],
				noLayoutRoutes: ['intermarche', 'intermarche-alt','test'],
			}
		},
		computed: {
			currentRoute() {
				return this.$route.name;
			},
			isWhiteBackgroundSet() {
				return this.whiteBgRoutes.includes(this.currentRoute);
			},
			isRegisterOrLogin() {
				return this.$route.name === 'register' ||  this.$route.name === 'login'
			},
			displayEmailModal() {
				return !isBool(this.$store.getters.getToken) && this.$store.getters.getShowEmailChecks;
			},
			showEmailModal(){
				return this.$store.getters['getshowEmailModal'];
			},
			hasNoLayout() {
				return this.noLayoutRoutes.includes(this.currentRoute);
			},
		},
		methods: {
			toggleModalDisplay: function(value) {
				this.$store.dispatch('setShowEmailModal', value);
			},
			gotoLogout: function() {
				if (!this.publicRoutes.includes(this.currentRoute)) this.$router.push({name:'logout'});
			},
			gotoLogin: function() {
				if (!this.publicRoutes.includes(this.currentRoute)) this.$router.push({name:'login'});
			},
			setLocale: function() {
				/* get only the lang code - if en_US -> en */
				this.lang = navigator.language.toLowerCase().substring(0, 2);
				this.$i18n.locale = this.lang;
			},
			setCountryCode: function(cc) {
				if (cc !== '') {
					localStorage.setItem('cc', cc);
					this.countryCode = cc.toLowerCase();
				}
			},
			handeError: function() {
				this.$store.dispatch('removeToken');
				this.gotoLogin();
				this.initApp();
			},
			checkPremium: function() {
				var folderId = localStorage.getItem('tvAppFolderId');
				// If coming from Kidjo TV, remember to go back after login
				var params = new URLSearchParams(window.location.search);

				if (this.$store.getters.getPremium) {
					if (folderId) {
						// remove folder id
						localStorage.removeItem('tvAppFolderId');
						return this.retrunToWebApp(folderId)
					}

					if (localStorage.getItem('backToApp')) {
						// remove backToApp term
						localStorage.removeItem('backToApp');
						return this.retrunToWebApp();
					}

					if (!['products', 'subscribe', 'changePlan'].includes(this.$route.name)) {
						if(localStorage.getItem('app')) { 
							AuthService.generateRedirectKey()
							.then((response) => {
								if (response.status === 200) {
									this.$parent.loading = false;
									window.location.replace(this.routeBackToWebApp() + '?key='+response.data.redirectKey);
								}
							}).catch((error) => {
								this.$parent.loading = false;
								console.log(error);
							});
						} else {
							this.$router.push({name: 'home'}).catch(()=>{});
						}
					}
				} else {
					this.$router.push({name: 'subscribe'}).catch(()=>{});
				}

				this.initApp();
			},
			routeBackToWebApp: function() {
				const actionMap = {
					['games']: process.env.VUE_APP_GAMES_APP_URL,
					['stories']: process.env.VUE_APP_STORIES_APP_URL,
					['tv']: process.env.VUE_APP_WEB_APP_URL,
					['kidjo-world']: process.env.VUE_APP_WEB_APP_LINK
				};
				return actionMap[localStorage.getItem('app')];		
			},
			retrunToWebApp: function(id = '') {
				this.loading = true;
				var addOn = '';

				if (id !== '') {
					addOn = 'folder/' + id;
				}

				AuthService.generateRedirectKey()
					.then((response) => {
						if (response.status === 200) {
							this.$parent.loading = false;
							window.location.replace(process.env.VUE_APP_WEB_APP_URL + addOn + '?key='+response.data.redirectKey);
						}
					}).catch((error) => {
						this.$parent.loading = false;
					});
			},
			showFlashMessage: function(content) {
				this.message = content;
				this.showFlash = true;
			},
			hideFlashMessage: function() {
				this.showFlash = false;
			},
			hideEmailModal: function() {
				this.showEmailModal = false;
			},
			initApp: function() {
				this.initialized = true;
				this.loading = false;
			},
			changeAppStyle: function(style) {
				var app = document.getElementById('app');
				var footer = document.querySelector('footer');
				var header = document.getElementById('header');

				if (style === 'profile') {
					app.classList.add('with-background');
					app.classList.remove('withoout-background');
					header.classList.remove('dark');

					if (!this.tvPlatform) {
						footer.classList.remove('dark');
						footer.classList.add('light');
					}
				} else {
					header.classList.add('dark');
					app.classList.remove('with-background');
					app.classList.add('withoout-background');

					if (!this.tvPlatform) {
						footer.classList.remove('light');
						footer.classList.add('dark');
					}
				}

				this.appStyle = style;
			},
			checkDevice: function() {
				var agent = navigator.userAgent;

				// check if the user is not login from Tv device
				if (agent.includes('OMI') || agent.includes('TV Store')) {
					this.tvPlatform = true;
					document.body.classList.add('tv-platform');
				}
			},
			checkForTvAppParams: function() {
				var params = new URLSearchParams(window.location.search);

				if (params.has('tv-app-folder')) {
					localStorage.setItem('tvAppFolderId', params.get('tv-app-folder'));
				}
			}
		},
		mounted: async function() {
			/* The App Start From Here */
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			if(urlParams.get('app')) {
				localStorage.setItem('app', urlParams.get('app'))
			}
			this.checkDevice();
			this.setLocale();
			const cc = await LocationService.getLocationContryCode();
			this.setCountryCode(cc);
			// check if user is from vewd and it's coming from tv app
			this.checkForTvAppParams();

			const params = new URLSearchParams(window.location.search);
			if (params.has('key')) {
				const isVerified = await this.$store.dispatch('checkUserKey', params.get('key'));
				if (isVerified === true && localStorage.getItem('kidj_ut')) {
					this.$router.replace({'query': null});
				} else {
					return this.handeError();
				}
			}

			if (window.location.href.includes( 'logout')) {
				this.gotoLogout();
				this.initApp();
			} else if (this.$store.getters.getToken) {
				this.hasPremium = await this.$store.dispatch('checkUserSubscriptions');
				if (this.hasPremium !== true) {
					if (this.hasPremium.status === 401) {
						return this.handeError();
					}

					if (this.hasPremium.status === 404) {
						this.showFlashMessage(this.$t('profile_page_error_finding_subscription'));
					}

					if (this.hasPremium.status === 400) {
						this.showFlashMessage(this.$t('error_message_something_went_wrong'));
					}
				}
				this.checkPremium();
			} else {
				this.gotoLogin();
				this.initApp();
			}
		},
		unmounted: (() => {
			localStorage.removeItem('app')
		}),
		beforeUnmount: (() => {
			localStorage.removeItem('app')
		})
	}
</script>

<style lang="scss">
	// Main Theme styles
	@import './assets/styles/main.sass';

	// Cookies Styles
	$cookieconstent-background: rgb(79, 79, 79);
	$cookieconstent-padding: 5px;
	@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
	@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom";
	@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition";
	@import "./assets/styles/cookies-override.sass";


	// Flash Message transition styles
	.slide-fade-enter-active {
		transition: all .3s ease;
	}

	.slide-fade-leave-active {
		transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade-enter,
	.slide-fade-leave-to {
		transform: translateX(10px);
		opacity: 0;
	}
</style>
