import { isNonEmptyStr } from '@/utils';

export default {
  data() {
    return {
      partnerId: 'orange',
      isPartnerRegistrationEnabled: process.env.VUE_APP_PARTNER_REGISTRATION_ENABLED === 'true',
    };
  },
  computed: {
    isOrangeRegister() {
      const { params, query } = this.$route;
      return params.partner === this.partnerId && isNonEmptyStr(query.register);
    },
  },
  methods: {
    setOrangeRegisterData() {
      const { params, query } = this.$route;
      const data = {
        partnerName: params.partner,
        userAlias: query.register
      };

      this.$store.dispatch('setPartnerData', data);
    },
  },
  created() {
    if (this.isOrangeRegister && this.isPartnerRegistrationEnabled) {
      this.setOrangeRegisterData();
    }
  },
};
