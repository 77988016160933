<template>
	<div class="overlay-wrap">
		<div class="overlay">
			<div class="box form-box">
				<img src="../assets/logo/close.svg" alt="close" class="close-btn" v-if="!isMondiaUser"  @click="toggleModalDisplay(false)"/>
				<span class="box-title">{{ isMondiaUser ? $t('mondia_email_popup_title') : $t('email_overlay_title') }}</span>
				<span class="box-subtitle">{{ isMondiaUser ? $t('mondia_email_popup_subtitle') : $t('email_overlay_lead2') }}</span>

				<form class="form" @submit.prevent="onSubmit">
					<input
						type="text" 
						name="email"
						v-model="email"
						:placeholder="$t('email_overlay_field_placeholder')" 
						:class="['form-item', { 'has-error': $v.email.$dirty }]"
						required 
					>

					<div class="form-item__error">
						<span>{{ emailErrors[0] }}</span>
					</div>

					<button
						type="submit"
						:class="['green-button', 'submit-button', 'animated-button', { unactive: isDisabled }]"
						:disabled="isDisabled"
					>
						<span class="loading" v-if="isSubmitted"></span>
						<span v-else>{{ $t('email_overlay_btn') }}</span>
					</button>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
	import { validationMixin } from 'vuelidate';
	import { required, email } from 'vuelidate/lib/validators';
	import AuthService from '@/services/auth.service';
	import { getErrorString } from '../utils/constant';

	export default {
		mixins: [validationMixin],
		validations: {
		email: {
			required,
					email,
		}
	},
		data() {
			return {
				email: '',
				isSubmitted: false,
			}
		},
		computed: {
			isDisabled() {
				return this.email === '' && !this.isSubmitted;
			},
			emailErrors() {
				const errors = [];
				if (!this.$v.email.$dirty) {
					return errors;
				}
				if (!this.$v.email.required) {
					errors.push(this.$('email_overlay_error_required'));
				}
				if (!this.$v.email.email) {
					errors.push(this.$('email_overlay_error_required'));
				}
				return errors;
			},
			isMondiaUser() {
				return this.$store.getters.getSubscriptionsStoreId('mondia')
			},
		},
		methods: {
			toggleModalDisplay() {
				this.$emit('toggleModalDisplay', false);
			},
			onSubmit() {
				this.isSubmitted = true;

				this.$v.$touch();

				if (this.$v.$invalid) {
					this.isSubmitted = false;
					return false;
				}

				AuthService.changeProfileInfo('email', this.email)
					.then(() => {
						if(this.isMondiaUser) {
							this.changePasswordPartnersMethod()
						}
						this.$store.dispatch('checkUserSubscriptions');
						this.toggleModalDisplay(false);
					})
					.catch((error) => {
						this.handleErrors(error.response);
						this.isSubmitted = false;
					});
			},
			changePasswordPartnersMethod: function() {
				AuthService.changePasswordPartners(this.email)
					.then((response) => {
						if (response.status == 200) {
							this.$parent.showFlashMessage(this.$t('forgotten_password_sucess'));
						}
					}).catch((error) => {
						this.isSubmitted = false;
					});
			},
			handleErrors: function(response) {
				return this.$parent.showFlashMessage(this.$t(getErrorString(response.status, response?.data?.code)));
			}
		},
	}
</script>

<style lang="scss" scoped>
	$regular-font: 'Nunito-Regular', Arial, sans-serif;

	.overlay-wrap {
		position: fixed;
		z-index: 1000;
		display: flex;
		width: 100vw;
		height: 100vh;
		align-items: center;
		justify-content: center;
		padding: 10px;
		background: rgba(0,0,0,0.4);
	}

	.overlay {
		border-radius: 15px;
		box-shadow: 0px 0px 0px 4px rgba(255,255,255,1);
		text-align: center;
	}

	.form-item__error {
		margin-bottom: 24px;

		span {
			font-family: $regular-font;
			font-size: 18px;
			color: #ff5722;
			text-shadow: 1px 0 0 rgba(0, 0, 0, 0.2);
		}
	}

	@keyframes pulse_button {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}
.close-btn {
	width: 4%;
	cursor: pointer;
	float: right;
	filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(0%) contrast(119%);
}
</style>