import axios from 'axios';
import {NOTIFY_UN_SUBSCRIPTION} from "../utils/constant";
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const userToken = 'kidj_ut';
const BASE_URL = process.env.VUE_APP_API_ACCOUNT_API_BASE_URL;
const API_VERSION = process.env.VUE_APP_API_ACCOUNT_VERSION_V5

class SubscriptionService {

	subscribe(nonce, name, device_data, planId, coupon, subscriptionFor) {
		var request = '/braintree/subscriptions';

		if (subscriptionFor === 'books') {
			request = '/braintree/books/subscriptions';
		} else if(subscriptionFor === 'games'){
			request = '/braintree/games/subscriptions';
		}else if(subscriptionFor === 'combo') {
			request = '/braintree/tv-books/subscriptions';
		}

		var config = {
			method: 'post',
			url: baseUrl + request,
			headers: {
				'Content-Type': 'application/json; Charset=UTF-8',
				'Authorization': "Bearer " + localStorage.getItem('kidj_ut')
			},
			data: {
				'nonce': nonce,
				'name': name,
				'device_data': device_data,
				'iap': planId,
				'coupon': coupon
			}
		};

		return axios(config);
	}
     
	notifyUnSubscribeSwitchUser = function (id) {
		const config = {
			method: 'put',
			url: BASE_URL + API_VERSION + NOTIFY_UN_SUBSCRIPTION + '?id=' + id,
			headers: {
				'Content-Type': 'application/json; Charset=UTF-8',
				'Authorization': "Bearer " + localStorage.getItem('kidj_ut')
			},
		};
		return axios(config);
	}

	generateToken() {
		var auth = {
			headers: {'Authorization': "Bearer " + localStorage.getItem(userToken)}
		}

		return axios.get(baseUrl + '/braintree/generate-token', auth);
	}

	getSubscriptionInfo() {
		var auth = {
			headers: {'Authorization': "Bearer " + localStorage.getItem(userToken)}
		}

		return axios.get(baseUrl + '/users/subscriptions', auth);
	}

	unsubscribe(type, id) {
		var auth = {
			headers: {'Authorization': "Bearer " + localStorage.getItem(userToken)}
		}

		var url = '/braintree/subscriptions/cancel'+'?id='+id;

		if (type == 'swisscom') {
			url = '/swisscom/unsubscribe';
		}

		return axios.get(baseUrl + url, auth);
	}

	checkCoupon(coupon) {

		var config = {
			method: 'post',
			url: baseUrl + '/coupon/validate',
			headers: {
				'Content-Type': 'application/json; Charset=UTF-8',
				'Authorization': "Bearer " + localStorage.getItem('kidj_ut')
			},
			data: {
				'coupon': coupon
			}
		};

		return axios(config);
	}
}


export default new SubscriptionService;
