<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.92 7.677c0-2.18-1.28-4.076-3.165-5.064L13.71.819c.14-.25-.21-.412-.335-.193l-.965 1.808a6.64 6.64 0 0 0-5.415 0L6.025.62C5.905.407 5.55.564 5.69.814l.955 1.799c-1.9.988-3.18 2.883-3.18 5.064H15.92zM6.86 5.384c-.28 0-.51-.244-.51-.535 0-.29.225-.535.51-.535a.53.53 0 0 1 .525.535.53.53 0 0 1-.525.535zm5.685 0a.53.53 0 0 1-.525-.535c0-.29.23-.535.525-.535.28 0 .51.245.51.535 0 .29-.23.535-.51.535zm-10.94 11.24c.77 0 1.375-.627 1.375-1.412v-5.9c0-.769-.62-1.4-1.375-1.4C.84 7.911.1 8.542.1 9.311v5.9c0 .78.74 1.412 1.505 1.412zm16.19 0c.77 0 1.505-.632 1.505-1.412v-5.9c0-.784-.74-1.4-1.505-1.4-.755 0-1.375.616-1.375 1.4v5.9c0 .785.62 1.412 1.375 1.412zm-4.39 5.308V18.82h1.01c.81 0 1.47-.672 1.47-1.508v-9.14H3.52v9.14c0 .836.66 1.508 1.48 1.508h.995l.015 3.113c0 1.88 2.76 1.865 2.76 0V18.82h1.86v3.113c0 1.87 2.775 1.875 2.775 0zM15.92 7.677c0-2.18-1.28-4.076-3.165-5.064L13.71.819c.14-.25-.21-.412-.335-.193l-.965 1.808a6.64 6.64 0 0 0-5.415 0L6.025.62C5.905.407 5.55.564 5.69.814l.955 1.799c-1.9.988-3.18 2.883-3.18 5.064H15.92zM6.86 5.384c-.28 0-.51-.244-.51-.535 0-.29.225-.535.51-.535a.53.53 0 0 1 .525.535.53.53 0 0 1-.525.535zm5.685 0a.53.53 0 0 1-.525-.535c0-.29.23-.535.525-.535.28 0 .51.245.51.535 0 .29-.23.535-.51.535zm-10.94 11.24c.77 0 1.375-.627 1.375-1.412v-5.9c0-.769-.62-1.4-1.375-1.4C.84 7.911.1 8.542.1 9.311v5.9c0 .78.74 1.412 1.505 1.412zm16.19 0c.77 0 1.505-.632 1.505-1.412v-5.9c0-.784-.74-1.4-1.505-1.4-.755 0-1.375.616-1.375 1.4v5.9c0 .785.62 1.412 1.375 1.412zm-4.39 5.308V18.82h1.01c.81 0 1.47-.672 1.47-1.508v-9.14H3.52v9.14c0 .836.66 1.508 1.48 1.508h.995l.015 3.113c0 1.88 2.76 1.865 2.76 0V18.82h1.86v3.113c0 1.87 2.775 1.875 2.775 0z"
      fill="#FFF"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  name: "AndroidIcon",
};
</script>
