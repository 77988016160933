<template>

	<div
		:class="[
			'header',
			{ 'dark': $parent.isWhiteBackgroundSet }
		]"
		id="header"
	>

		<div class="logo">
			<a :href="$parent.tvPlatform ? '/' : $parent.websiteUrl" ><img src="../assets/images/logo.svg"></a>
		</div>

		<div class="navigation-links">
			<!-- Desktop navigation -->
			<router-link :to="$route.name ==='home' ? '/products' : '/'" class="navigation-link nav-link" v-if="$store.getters.getPremium">
				{{ $route.name === 'home' ? $t('navigation_products_link') : $t('navigation_progile_link') }}
			</router-link>
			<span class="navigation-link nav-button animated-button logout" @click="logout" v-if="$store.getters.getToken">{{ $t('logout_button') }}</span>
			<a class="navigation-link nav-button animated-button" v-if="$store.getters.getToken === false && $parent.tvPlatform" @click="exitApp">Close</a>
			<a class="navigation-link nav-button animated-button login" v-if="($route.name === 'swisscom') || ($route.name === 'register')" :href="'/login'">{{ $t('navigation_links_sing_in') }}</a>

			<!-- Mobile navigation toggle -->
			<button class="burger-dropdown" @click="toggleMenu" id="burger" v-if="$store.getters.getToken">
				<span class="one"></span>
				<span class="two"></span>
				<span class="tree"></span>
			</button>

		</div>

		<!-- Mobile menu -->
		<div class="mobile-menu-cover" id="mobile-menu">
			<div class="mobile-menu">
				<a class="menu-link" @click="navigateTo('products')" v-if="$store.getters.getPremium">{{ $t('navigation_products_link')  }}</a>
				<a class="menu-link" @click="navigateTo('home')" v-if="$store.getters.getPremium">{{ $t('navigation_progile_link')  }}</a>
				<div class="center-menu-button">
					<a @click="logout" class="menu-button">{{ $t('logout_button') }}</a>
				</div>

			</div>
		</div>

	</div>
</template>
<script>
	export default {
		methods: {
			// Open / Close Mobile menu
			toggleMenu: function() {
				document.getElementById('burger').classList.toggle("active");
				document.getElementById('mobile-menu').classList.toggle("active");
			},

			// Close Mobile Menu
			closeMenu: function() {
				document.getElementById('burger').classList.remove("active");
				document.getElementById('mobile-menu').classList.remove("active");
			},
      clearLocalStorage:function (){
        localStorage.removeItem('coupon');
        localStorage.removeItem('couponDurationCode');
      },

			// logout from account
			logout: function() {
				localStorage.removeItem('storeId')
				this.$store.dispatch('logout');
                this.clearLocalStorage();
				this.$router.push({name:'login'}).catch(()=>{});

				if (window.innerWidth <= 768) {
					this.closeMenu();
				}
			},

			// Navigato to given page and close the mobile menu
			navigateTo: function(routeName) {
				this.closeMenu();
				this.$router.push({name: routeName}).catch(()=>{});
			},

			// Exit App function used from vewd platform
			exitApp: function() {
				window.close();
			}
		},
	}
</script>
