<template>
	<div class="one-row">
		<div class="box form-box">
			<span class="box-title">{{ $t('change_password_page_box_title') }}</span>
			<form class="form" @submit.prevent="handleSubmit">
				<input class="form-item" type="password" name="old_password" :placeholder="$t('change_password_page_old_password')" v-model="old_password" :class="(old_password_error ? 'has-error' : '')">
				<input class="form-item" type="password" name="new_password" :placeholder="$t('change_password_page_new_password')" v-model="new_password" :class="(new_password_error ? 'has-error' : '')">
				<input class="form-item" type="password" name="confirm_password" :placeholder="$t('change_password_page_confirm_password')" v-model="confirm_password" :class="(confirm_password_error ? 'has-error' : '')">
				<button class="green-button submit-button animated-button" type="submit">
					<span class="loading" v-if="loading"></span>
					<span v-else>{{ $t('change_password_page_submit_button') }}</span>
				</button>
			</form>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import PasswordService from '../services/password.service';
	import AuthService from '../services/auth.service';

	export default {
		data() {
			return {
				old_password: '',
				new_password: '',
				confirm_password: '',
				old_password_error: false,
				new_password_error: false,
				confirm_password_error: false,
				loading: false
			};
		},

		methods: {
			handleSubmit: function() {
				this.loading = true
				var has_error = this.checkFormData();
				var confirmed = this.confirmPassword();

				if (!has_error && confirmed) {
					var oldP = PasswordService.SHA256(this.old_password);
					var newP = PasswordService.SHA256(this.new_password);

					AuthService.changePassword(oldP, newP)
						.then((response) => {
							if (response.status == 200) {
								this.clearForm();
								this.$parent.showFlashMessage(this.$t('change_password_page_success_change'));
								this.$router.push({name: 'home'}).catch(()=>{});
							}
							this.loading = false;
						}).catch((error) => {
							this.handleError(error.response);
							this.loading = false;
						});

				} else {
					this.loading = false;
				}
			},

			handleError: function(response) {
				if (response.status == 400) {
					if (response.data.code == 1) {
						return this.$parent.showFlashMessage(this.$t('change_password_page_error_wrong_old_password'));
					}
					
					return this.$parent.showFlashMessage(this.$t('error_message_something_went_wrong'));
				}

				if (response.status == 404) {
					return this.$parent.showFlashMessage(this.$t('forgotten_password_error_error_email_not_found'));
				}
			},

			checkFormData: function() {
				var has_error = false;

				if (this.old_password == '') {
					has_error = true;
					this.old_password_error = true;
				} else { this.old_password_error = false }

				if (this.new_password == '') {
					has_error = true;
					this.new_password_error = true;
				} else { this.new_password_error = false }

				if (this.confirm_password == '') {
					has_error = true;
					this.confirm_password_error = true;
				} else { this.confirm_password_error = false }

				return has_error;
			},

			confirmPassword: function() {
				if (this.new_password != this.confirm_password) {
					this.confirm_password_error = true;
					this.$parent.showFlashMessage(this.$t('change_password_page_confirm_password_error'));

					return false;
				}

				return true;
			},

			clearForm: function() {
				this.old_password = '';
				this.new_password = '';
				this.confirm_password = '';
				this.old_password_error = false;
				this.new_password_error = false;
				this.confirm_password_error = false;
			}
		}

	}
</script>
<style scoped lang="sass">
	.loading
		width: 25px
		height: 25px
		margin: auto
		border: 5px solid rgba(255,255,255,0.5)
		border-top-color: #ffffff
		animation: 1.5s spin infinite linear
		border-radius: 50%

		@keyframes spin
			to
				transform: rotate(360deg)
</style>