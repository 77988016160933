export default {
  auth: {
    email: '',
    name: '',
    token: false,
    hasPremium: false,
    isPromoAccepted :false
  },
  subscription: {
    list: [],
  },
  publisher: {
    id: null,
    settings: {},
  },
};
