<template>
	<div class="one-row">
		<!-- Pop up form -->
		<popup-form v-if="showPopUpForm" :value="changeValue" :type="changeType" @closePopUp="closeChangeForm"></popup-form>

		<!-- Go to HUB page box -->
		<div class="modal-box-row">
			<div class="box modal-box" :class="$parent.tvPlatform ? 'tv-platfrom' : ''">
				<img src="../assets/images/multi_devices_icon.svg" alt="Kidjo Products" :class="$parent.tvPlatform ? 'tv-platfrom' : ''">
				<span class="box-subtitle">{{ $t('profile_page_modal_box_title') }}</span>
				<router-link class="modal-box-button animated-button" to="/products">{{ $t('profile_page_modal_box_link') }}</router-link>
			</div>
		</div>

		<!-- Profile Info Box -->
		<div class="box profie-box" :class="$parent.tvPlatform ? 'tv-platfrom' : ''">
			<span class="box-title">{{ $t('profile_page_box_title') }}</span>
			<span class="box-subtitle">{{ $t('profile_page_box_subtitle') }}</span>

			<div class="box-card">
				<!-- Base information -->
				<ul class="box-card-details">
					<li class="box-card-detail">
						<span class="primary">{{ $t('name') }}:</span> <span class="secondary">{{ this.accountDetails.name }}</span>
					</li>
					<li class="box-card-detail">
						<a href="#" @click.prevent="showChangeForm('name')">{{ $t('profile_page_change_name_button') }}</a>
					</li>
					<li class="box-card-detail">
						<span class="primary">{{ $t('email') }}:</span> <span class="secondary">{{ this.accountDetails.email }}</span>
					</li>
					<li class="box-card-detail">
						<a href="#" @click.prevent="showChangeForm('email')">{{ $t('profile_page_change_email_button') }}</a>
					</li>
					<li class="box-card-detail">
						<span class="primary">{{ $t('password') }}:</span> <span class="secondary">*******</span>
					</li>
					<li class="box-card-detail">
						<router-link to="/change-password">{{ $t('profile_page_change_password_link') }}</router-link>
					</li>
				</ul>

				<!-- Subscription info -->
				<span v-if="!subscription_has_error && accountDetails.subscriptions.length > 0" >
					<ul class="box-card-details" v-for="(subscription, index) in this.accountDetails.subscriptions" :key="index">

						<div class="box-card-separator"></div>

						<!-- Coupon details -->
						<template v-if="subscription.coupon != null">
							<li class="box-card-detail">
								<span class="primary">{{ $t('profile_page_promo_code') }}:</span>
								<span class="secondary">
                  {{ subscription.coupon.couponSerialNumber }} - {{ promoMessage(subscription) }}
								</span>
							</li>

							<template v-if="subscription.coupon.couponType === 'FREE_ACCESS_COUPON'|| subscription.coupon.couponType === 'UNIQUE_ACCESS_COUPON'">
								<li class="box-card-detail">
									<span class="primary">
										{{ $t('profile_page_active_until') }}:
									</span>
									<span class="secondary">{{ subscription.nextBillingDate | formatDate }}</span>
								</li>

								<li class="box-card-detail">
									<span class="primary">{{ $t('profile_page_payment_method') }}:</span> <span class="secondary">Free access by coupon</span>
								</li>
							</template>
						</template>

						<!-- Next Invoice / show only if the subscription is from brantree -->
						<li class="box-card-detail" v-if="subscription.storeId === 'kidjo'">
							<span class="primary">
								{{ (subscription.isRenewing ? $t('profile_page_next_invoice') : $t('profile_page_active_until')) }}:
							</span>
							<span class="secondary">{{ subscription.nextBillingDate | formatDate }}</span>
						</li>

            <!-- logicom on time promotion paymenth method -->
						<li class="box-card-detail"
                v-if="subscription.storeId === 'playstore' && subscription.paymentStateId === 'Free_Campaign'">
							<span class="primary">{{ $t('profile_page_payment_method') }}:</span> <span
                class="secondary">Logicom</span>
						</li>


            <!-- Paymenth method details -->
						<li class="box-card-detail" v-if="subscription.isRenewing">
							<span class="primary">{{ $t('profile_page_payment_method') }}:</span> <span
                class="secondary">{{ getPaymentMethod(subscription.paymentMethoth, subscription.storeId) }}</span>
						</li>

            <!-- Paymenth Plan details -->
						<li class="box-card-detail">
							<span class="primary">{{ $t('profile_page_plan') }}:</span> <span class="secondary">{{
                getPlanName(subscription.paymentPlanId, subscription.subscriptionType, subscription.storeId, subscription.durationCode)
              }}</span>
						</li>

            <!--Change Plan-->
            <li class="box-card-detail" v-if="$parent.tvPlatform === false && subscription.storeId ==='kidjo'">
							<router-link
                  to="/changePlan"
                  v-if="subscription.isRenewing">
									{{ $t('profile_page_change_plan_link') }}
							</router-link>
						</li>
            <!-- Unsubscribe link -->
						<li class="box-card-detail"
                v-if="subscription.isRenewing && subscription.paymentStateId !== 'Free_Campaign' && $parent.tvPlatform === false">
							<a @click="unsubscribe(subscription)">{{ $t('profile_page_unsubscribe_link') }}</a>
						</li>

            <!-- Resubscribe link / only for subscriptions from brain tree -->
						<li class="box-card-detail" v-if="$parent.tvPlatform === false && subscription.storeId ==='kidjo' && !subscription.isRenewing && subscription.paymentStateId !== 'Free_Campaign'">
							
                <router-link
                  to="/changePlan"
                  class="green-button animated-button button"
                  >
									{{ $t('profile_page_resubscribe_button') }}
                </router-link>
                <!-- <span class="box-subtitle" v-else
                    >{{ $t('subscription_is_still_active') }}</span> -->

              
						</li>
					</ul>
				</span>

      </div>
    </div>
  </div>
</template>
<script>
import PaymentPlansService from '../services/paymenth.plans.service';
import popUpForm from '../components/popUpForm.vue';

export default {
  components: {
    'popup-form': popUpForm
  },

  data() {
    return {
      subscription_has_error: false,
      showPopUpForm: false,
      changeValue: ""
    };
  },
  filters: {
    formatDate(value) {
      return new Date(value).toLocaleDateString();
    },
  },
  computed: {
    accountDetails() {
      return this.$store.getters['getAccountDetails'];
    },
    isSubscriptionExpired(nextBillingDate) {
      const nextBillDate = new Date(nextBillingDate);
      const today = Date();
      return nextBillDate <= today;
    }
  },
  methods: {
    getPlanName: function (id, type, store, durationCode) {
      return PaymentPlansService.searchPlanByType(type);
    },

    getPaymentMethod: function (paymentMethoth, storeId) {
      if (storeId === 'kidjo') {
        if (paymentMethoth === "free") {
          return this.$t('free_trial');
        }
        return paymentMethoth;
      }

      if (storeId === 'swisscom') {
        return 'Swisscom';
      }

      if (storeId === 'ios') {
        return this.$t('profile_page_payment_method_apple');
      }

      if (storeId === 'playstore') {
        return this.$t('profile_page_payment_method_play_store');
      }

      if (storeId === 'documo') {
        return this.$t('profile_page_payment_method_docomo');
      }

      if (storeId === 'samsung') {
        return this.$t('profile_page_payment_method_samsung');
      }
      
      if (storeId === 'mondia') {
        return this.$t('mobile_subscription');
      }

      return '';
    },

    resubscribe: function () {
      this.$store.dispatch("removePremium");
      this.$router.push({name: 'subscribe'}).catch(() => {
      });
    },
    unSubscribeSwitch: function (id, storeId) {
      localStorage.setItem('storeId', 'switch');
      return this.$router.push('/unsubscribe/?id=' + id +'&type=' + storeId );
    },
    unsubscribe: function (subscription) {
      const docomoUrls = {
        ['movistar']: "https://emocion.movistar.es/micuenta",
        ['orange']: 'http://webappsupport.fr/selfCare/login',
        ['default']: "https://support.kidjo.tv/hc/fr/articles/25305202155025-Comment-résilier-mon-abonnement-Kidjo"
      };
      const redirectToUrl = (url) => window.location.replace(url);
      const actionMap = {
        ['virgo']: () => { redirectToUrl("https://support.kidjo.tv/hc/fr/articles/25305202155025-Comment-résilier-mon-abonnement-Kidjo"); },
        ['switch']: () => this.unSubscribeSwitch(subscription.id, subscription.storeId),
        ['kidjo']: () => { this.$router.push({ path: '/unsubscribe/' + subscription.id }); },
        ['swisscom']: () => { this.$router.push({ path: '/unsubscribe', query: { type: 'swisscom' } }); },
        ['ios']: () => { redirectToUrl("https://support.apple.com/HT202039"); },
        ['playstore']: () => { redirectToUrl("https://support.google.com/googleplay/answer/7018481"); },  
        ['docomo']: () => { redirectToUrl(docomoUrls[subscription.platformPurchadeId] || docomoUrls['default']); },
        ['default']: () => { redirectToUrl("https://support.kidjo.tv/hc/en-001/articles/25305202155025-How-can-I-cancel-my-Kidjo-subscription"); }
      }
      const action = actionMap[subscription.storeId] || actionMap['default'];
      return action();
    },
    getCouponDuration: function (subscription) {
      const duration_period = {
        day: this.$t('label_day'),
        days: this.$t('label_days'),
        month: this.$t('month'),
        months: this.$t('label_months'),
      };
      const value = subscription.coupon.couponDurationCode;
      const duration = value.replace(/\D/g, '');

      let suffix = 'month';

      if (value.includes('D')) {
        suffix = 'day';
      }
      if (duration > 1) {
        suffix += 's';
      }

      return {duration,"durationPeriods":duration_period[suffix],"durationPeriod":duration_period[suffix.slice(0,-1)]};
    },
    promoMessage: function (subscription) {
        const {duration,durationPeriod,durationPeriods}=this.getCouponDuration(subscription)
        return `${duration} ${durationPeriods}`
    },
    showChangeForm: function (type) {
      this.changeType = type;
      if (type === 'email') {
        this.changeValue = this.$store.state.legacy.email;
      } else {
        this.changeValue = this.$store.state.legacy.name;
      }
      this.showPopUpForm = true;
    },

    closeChangeForm: function () {
      this.changeValue = "";
      this.showPopUpForm = false;
    }
  },

  mounted: async function () {
    localStorage.removeItem('app')
    if (this.$store.getters.getToken === false) {
      return this.$router.push({name: 'login'});
    }

    this.$parent.loading = true;
    const routeFrom = this.$route.query.from === 'unsubscribe'
    if (routeFrom && localStorage.getItem('storeId') === 'switch') {
      localStorage.removeItem('storeId')
      this.$router.push({name: 'subscribe'}).catch(() => {
      });
    } else {
        this.$parent.hasPremium = await this.$store.dispatch('checkUserSubscriptions');
        if (this.$parent.hasPremium !== true) {
        this.subscription_has_error = true;
        if (this.$parent.hasPremium.status === 404) {
          this.$parent.showFlashMessage(this.$t('profile_page_error_finding_subscription'));
        }

        if (this.$parent.hasPremium.status === 400) {
          this.$parent.showFlashMessage(this.$t('error_message_something_went_wrong'));
        }
          this.$router.push({name: 'subscribe'}).catch(() => {
        });
      }
    }

    if (this.$parent.appStyle === 'subscribe') {
      this.$parent.changeAppStyle('profile');
    }

    // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
    window.scrollTo(0, 0);
    this.$parent.loading = false;
  },
}
</script>
