<template>
	<div class="one-row">
		<div class="box form-box">
			<span class="box-title">{{ $t('forgotten_password_box_title') }}</span>
			<form class="form" @submit.prevent="handleSubmit">
				<input class="form-item" type="email" name="email" :placeholder="$t('forgotten_password_email')" v-model="email" :class="(email_error ? 'has-error' : '')">
				<button class="green-button submit-button animated-button" :class="(!isDisabled ? 'unactive' : '')" type="submit" :disabled="loading">
					<span class="loading" v-if="loading"></span>
					<span v-else>{{ $t('forgotten_password_send') }}</span>
				</button>
				<span class="form-small-text">
					<router-link to="/login" class="btn">{{ $t('forgotten_password_back_to_login') }}</router-link>
				</span>
			</form>
		</div>
	</div>
</template>
<script>
	import AuthService from '../services/auth.service';

	export default {
		data() {
			return {
				email: '',
				email_error: false,
				loading: false
			}
		},

		computed: {
			isDisabled: function() {
				if (this.email != '') {
					return true;
				}

				return false;
			}
		},

		methods: {
			handleSubmit: function() {
				var error = this.checkField();

				if (!error) {
					AuthService.fogottenPassword(this.email)
						.then((response) => {
							if (response.status == 200) {
								this.$parent.showFlashMessage(this.$t('forgotten_password_sucess'));
								this.$router.push({name: 'login'}).catch(()=>{});
							}
							this.loading = false;
						}).catch((error) => {
							this.handleErrors(error.response);
							this.loading = false;
						});
				}
			},

			checkField: function() {
				var has_error = false

				if (this.email == '') {
					this.has_error = true;
					this.email_error = true;
				}

				return has_error;
			},

			handleErrors: function(response) {
				if (response.status == 400) {
					if (response.data.code == 1) {
						return this.$parent.showFlashMessage(this.$t('forgotten_password_error_empty_email'));
					}

					if (response.data.code == 2) {
						return this.$parent.showFlashMessage(this.$t('forgotten_password_error_invalid_email'));
					}

					if (response.data.code == 3) {
						return this.$parent.showFlashMessage(this.$t('forgotten_password_error_error_updatting_email'));
					}
				}

				if (response.status == 404) {
					return this.$parent.showFlashMessage(this.$t('forgotten_password_error_error_email_not_found'));
				}
			}
		},
	}
</script>