export default () => {
	const plans = [
		{
			nameKey: 'monthly_plan',
			durationCode: 'P1M',
			status: true,
			plans: [
				{
					planId: "europe_kidjo_server_plan_5_monthly_7f",
					old: "kidjo_server_plan_2_monthly_7f", 
					price: "4.99 €",
					product: "Kidjo TV", 
					status: true, 
					for: 'tv',
					promoTitle: "subscribe_page_product_tv_title",
				},
				{
					planId: "europe_kidjo_books_server_plan_6_monthly_7f",
					old: "kidjo_books_server_plan_6_monthly_7f", 
					price: "4.99 €",
					product: "Kidjo Stories", 
					status: false, 
					for: 'books',
					promoTitle: "subscribe_page_product_books_title",
				},
				{
					planId: "N/A",
					old: "N/A",
					price: "N/A",
					product: "Kidjo Games", 
					status: false, 
					for: 'games',
					promoTitle: "N/A",
				}
			],
			comboPlans: {
				'tv_books': {
					for: 'combo', 
					price: '7.99 €', 
					planId: 'europe_kidjo_tv_and_books_server_plan_12_monthly_7f' 
				},
			}
		},
		{
			nameKey: 'annual_plan',
			durationCode: 'P1Y',
			status: false,
			plans: [
				{
					planId: "europe_kidjo_server_plan_3_12months_7f",
					old: "kidjo_server_plan_3_12months_7f",
					price: "34.90 €",
					product: "Kidjo TV", 
					status: false, 
					for: 'tv',
					promoTitle: "subscribe_page_product_tv_title",
				},
				{
					planId: "europe_kidjo_books_server_plan_8_12months_7f",
					old: "kidjo_books_server_plan_8_12months_7f",
					price: "34.90 €",
					product: "Kidjo Stories", 
					status: false, 
					for: 'books',
					promoTitle: "subscribe_page_product_books_title",
				},
				{
					planId: "N/A",
					old: "N/A", 
					price: "N/A",
					product: "Kidjo Games", 
					status: false, 
					for: 'games',
					promoTitle: "N/A",
				}
			],
			comboPlans: {
				'tv_books': { 
					for: 'combo', 
					price: '55.90 €', 
					planId: "europe_kidjo_tv_and_books_server_plan_11_12months_7f",
				}
			}
		}
	];


	return plans;
}