<template>
  <div class="hub tw-mb-24">
    <template v-if="isWebPlatform">
      <div class="hub__block">
        <div class="hub__action--step-1">
          <div class="hub__title">
            <p>{{ $t("hub_apps_step_title") }}</p>
          </div>

          <div class="hub__app-list">
            <div class="tw-flex">
              <TvAppCard :isSubscribed="isSubscribedForTV" class="tw-mr-2"/>
              <StoriesAppCard :isSubscribed="isSubscribedForStories"/>
            </div>
            <GamesAppCard
                :isSubscribed="isSubscribedForGames"
                class="tw-mt-2"
            />
          </div>
        </div>
      </div>

      <div class="hub__divider">
        <div class="hub__arrow_divider">
          <svg
              width="38"
              height="94"
              viewBox="0 0 38 94"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M37.715 59.379a30.637 30.637 0 0 1-1.023 2.652c-3.948 8.898-8.25 17.63-12.789 26.251-.773 1.468-1.67 2.877-2.565 4.28-.459.718-1.426.922-2.26.61-1.201-.448-2.123-1.246-2.402-2.46-.466-2.019-1.578-3.694-2.63-5.418-1.984-3.248-3.885-6.546-5.938-9.751-1.988-3.105-4.132-6.112-6.19-9.174a22.195 22.195 0 0 1-1.26-2.14c-.24-.463.157-1.23.68-1.481.505-.242.95-.063 1.342.22.286.207.533.485.747.767 2.805 3.704 5.745 7.308 8.138 11.304 2.564 4.282 5.234 8.503 7.863 12.748.056.091.19.135.29.202.189-.082.475-.112.557-.255 1.122-1.958 2.283-3.9 3.306-5.907 3.126-6.136 6.203-12.297 9.285-18.456.796-1.592 1.513-3.223 2.32-4.81.203-.4.563-.78.95-1.013.254-.153.736-.134 1.018.003.274.133.514.488.603.792.093.318.066.716-.042 1.036zm-15.237 4.438c-.065 3.844-.282 7.687-.462 11.529-.017.342-.215.687-.373 1.014-.2.416-1.142.76-1.733.613-.524-.13-1.005-.384-1.096-.92-.1-.587-.188-1.194-.144-1.782.486-6.368.22-12.739.19-19.11-.024-5.408-.004-10.818-.004-16.227h-.152c-.19-3.601-.38-7.204-.575-10.805-.358-6.604-.272-13.224-.99-19.816-.196-1.788-.111-3.604-.106-5.406 0-.467.099-.971.32-1.384.145-.268.566-.474.91-.581.438-.137 1.218.252 1.308.593.121.463.257.933.286 1.404.327 5.277.653 10.555.942 15.834.251 4.563.383 9.132.7 13.691.285 4.079.554 8.153.555 12.245 0 3.726.13 7.452.218 11.178.063 2.644.25 5.288.206 7.93z"
                fill="#29B6F6"
                fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div class="hub__block hub__position_altered">
        <div class="hub__title">
          <p>{{ $t("hub_login_step_title") }}</p>
          <span>{{ $t("hub_login_step_lead") }}</span>
        </div>

        <div class="hub__action--step-2">
          <div class="hub__comp-step-2">
            <img
                src="@/assets/images/phone-clipped.png"
                srcset="
                @/assets/images/phone-clipped@2x.png 2x,
                @/assets/images/phone-clipped@3x.png 3x
              "
            />
          </div>
        </div>
      </div>

      <div class="hub__divider">
        <div class="hub__text-divider">
          <p>{{ $t("hub_options_divider") }}</p>
        </div>
      </div>
    </template>

    <div class="hub__block hub__position_altered">
      <div class="hub__title">
        <p>{{ $t("hub_apps_open_step_title") }}</p>
        <span>{{ $t("hub_webapp_step_lead") }}</span>
      </div>

      <div class="hub__action--step-3">
        <img
            src="@/assets/images/multi-devices-icon-alt.png"
            srcset="
            @/assets/images/multi-devices-icon-alt@2x.png 2x,
            @/assets/images/multi-devices-icon-alt@3x.png 3x
          "
        />
        <div class="tw-px-2">
          <a
            href="#"
            class="hub__btn btn--min-width btn--animated tw-mb-1.5 hub__btn--no-border tw-min-w-full"
            :class="['tw-bg-gradient-to-b tw-from-gradientLightBlue tw-via-gradientDarkBlue tw-to-gradientDarkBlue tw-pointer-events-auto'
                
            ]"
              :disabled="!isSubscribedForTV"
              @click.prevent="initiateWebApp()"
          >
            <span v-if="isWebPlatform">{{ $t("open_webapp") }}</span>
            <span v-else>{{ $t("hub_initialize_app_vewd_btn") }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoriesAppCard from "../components/StoriesAppCard";
import TvAppCard from "../components/TvAppCard.vue";
import GamesAppCard from "../components/GamesAppCard.vue";
import AuthService from "../services/auth.service";
import {BOOK_COMBOS, GAMES_COMBO, TV_COMBOS} from "../utils/constant";

export default {
  components: {
    StoriesAppCard,
    TvAppCard,
    GamesAppCard,
  },
  data() {
    return {
      isWebPlatform: !this.$parent.tvPlatform,
      appStoreList: {
        tv: {
          apple: "https://apps.apple.com/us/app/kidjo-tv-for-kids/id1095795448",
          google:
              "https://play.google.com/store/apps/details?id=net.kidjo.app.android",
          amazon:
              "https://www.amazon.com/Kidjo-Kids-Have-Fun-Learn/dp/B06X99VRWG",
          samsung: "samsungapps://ProductDetail/net.kidjo.app.samsung",
        },
        stories: {
          apple:
              "https://apps.apple.com/us/app/kidjo-stories-for-kids/id1538396684",
          google:
              "https://play.google.com/store/apps/details?id=net.kidjo.books.android.playstore",
        },
        games: {
          apple: "https://apps.apple.com/us/app/kidjo-games-kids-play-learn/id6445847220",
          google: "https://play.google.com/store/apps/details?id=net.kidjo.games.app.playstore",
        }
      },
    };
  },
  computed: {
    subscriptionTypes() {
      return this.$store.getters.getSubscriptionsList.map(
          ({subscriptionType}) => subscriptionType
      );
    },
    isSubscribedForTV() {
      return this.subscriptionTypes.some((subscription) =>
          TV_COMBOS.includes(subscription)
      );
    },
    isSubscribedForStories() {
      return this.subscriptionTypes.some((subscription) =>
          BOOK_COMBOS.includes(subscription)
      );
    },
    // DEV NOTE: mocked property until the BE logic is developed
    isSubscribedForGames() {
      return this.subscriptionTypes.some((subscription) =>
          GAMES_COMBO.includes(subscription)
      );
    },
  },
  methods: {
    initiateWebApp: function () {
      this.$parent.loading = true;

      AuthService.generateRedirectKey()
        .then(({ status, data }) => {
          if (status == 200) {
            window.location.replace(
                `${process.env.VUE_APP_WEB_APP_LINK}?key=${data.redirectKey}`
              );
            }
          })
          .catch(() => {
            this.$parent.loading = false;
          });
    },
  },
  mounted() {
    this.$parent.loading = true
    this.$store.dispatch('checkUserSubscriptions')
        .then(()=>{
      this.$parent.loading= false
    });
  }
};
</script>

<style lang="scss">
$regular-font: "Nunito-Regular", Arial, sans-serif;
$bold-font: "Nunito-ExtraBold", Arial, sans-serif;
$base-color: #221f1f;
$btn-bg-color: #29b6f6;
$btn-border-color: #6d4c41;
$divider-color: #221f1f;

$breakpoint-xl: 1024px;

.hub {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 100%;
  padding-bottom: 40px;

  & * {
    box-sizing: border-box;
  }

  @media (min-width: $breakpoint-xl) {
    flex-flow: row wrap;
    justify-content: center;
    padding: 40px 0 0;
    margin: 0 auto;

    &__position_altered {
      padding-top: 5rem;
      align-self: start;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 37px;
    padding: 0 16px;
    font-family: $bold-font;
    font-size: 1rem;
    color: white;
    background: $btn-bg-color;
    border: 3px solid $btn-border-color;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;

    &--subscribe {
      padding: 0 6px;
      font-size: 0.8rem;
    }

    &--icon-only {
      margin: 0px 2px;
      padding: 0 2px;
      min-width: 37px;
    }

    &--disabled {
      pointer-events: none;
      background: #cbd3da;
      opacity: 0.5;
    }

    &--no-border {
      border: none;
    }
  }

  &__app-platforms {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__block {
    min-width: 220px;
  }

  &__divider {
    min-width: 220px;
    margin: 30px 0;

    @media (min-width: $breakpoint-xl) {
      min-width: auto;
      margin: 64px 22px 0;
    }
  }

  &__action {
    &--step-3 {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      img {
        max-width: 250px;
        margin-bottom: 20px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;

    p,
    span {
      font-family: $bold-font;
      color: $base-color;
    }

    p {
      font-size: 1.35rem;
    }

    span {
      font-size: 0.75rem;
    }
  }

  &__comp-step-2 {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -36px;

    img {
      position: relative;
      z-index: 10;
      min-height: 275px;
      margin-bottom: 1px;
    }

    &::after {
      display: block;
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 0;
      right: 0;
      width: 200px;
      height: 200px;
      margin-left: auto;
      margin-right: auto;
      background: #ffbb00;
      border: 6px solid #6d4b41;
      border-radius: 50%;
      content: "";
    }
  }

  &__arrow_divider {
    display: flex;
    justify-content: center;
    @media (min-width: $breakpoint-xl) {
      width: 100px;
      transform: rotate(-90deg);
    }
  }

  &__text-divider {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    p {
      position: relative;
      z-index: 10;
      padding: 2px 8px;
      font-family: $regular-font;
      font-size: 1rem;
      background: white;
    }

    &::after {
      display: block;
      position: absolute;
      z-index: 9;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      margin: auto;
      background: $divider-color;
      content: "";
    }

    @media (min-width: $breakpoint-xl) {
      display: flex;
      justify-content: center;
      height: 220px;

      &::after {
        width: 1px;
        height: 100%;
      }
    }
  }
}
</style>
