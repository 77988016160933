import Vue from 'vue';
import VueRouter from 'vue-router';
import Profile from '@/views/Profile.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import Unsubscribe from '@/views/Unsubscribe.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import Register from '@/views/Register.vue';
import ForgottenPassword from '@/views/ForgottenPassword.vue';
import AddSubscription from '@/views/AddSubscription.vue';
import SevaMedia from '@/views/publishers/SevaMedia.vue';
import Intermarche from '@/views/publishers/Intermarche.vue';
import Hub from '@/views/Hub.vue';
import SubscribeV2 from "@/views/SubscribeV2.vue";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    path: '/:locale',
    component: {
        render: h => h('router-view')
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Profile,
        },
        {
            path: '/home',
            redirect: {name: 'home'}
        },
        {
            path: '/change-password',
            name: 'change-password',
            component: ChangePassword
        },
        {
            path: '/unsubscribe/:id',
            name: 'unsubscribe',
            component: Unsubscribe
        },
        {
            path: '/unsubscribe/:id?',
            name: 'unsubscribe',
            component: Unsubscribe
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout
        },
        {
            path: '/register/:partner?',
            name: 'register',
            component: Register
        },
        {
            path: '/swisscom',
            name: 'swisscom',
            component: Register
        },
        {
            path: '/intermarche',
            name: 'intermarche',
            component: Intermarche
        },
        {
            path: `${encodeURI('/intermarché')}`,
            name: 'intermarche-alt',
            component: Intermarche
        },
        {
            path: '/subscribe',
            name: 'subscribe',
            component: SubscribeV2
        },
        {
            path: '/products',
            name: 'products',
            component: Hub,
        },
        {
            path: '/forgotten-password',
            name: 'forgotten-password',
            component: ForgottenPassword
        },
        {
            path: '/add-subscription',
            name: 'add-subscription',
            component: AddSubscription
        },
        {
            path: '/resubscribe',
            name: 'resubscribe',
            component: SubscribeV2
        },
        {
            path: '/coupon',
            name: 'publisher-sevamedia',
            component: SevaMedia,
        },
        {
            path: '/changePlan',
            name: 'changePlan',
            component: SubscribeV2
        },
        {
            path: '/changePlan/:key?',
            name: 'changePlan',
            component: SubscribeV2
        },
        {
            path: '/promo/:code?',
            name: 'promo',
            component: Register
        },
    ]
});

export default router;
