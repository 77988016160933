<template>
  <div class="tw-w-full tw-relative">
    <div class="tw-bg-lightblue tw-pb-36 tw-bg-contain tw-bg-center md:tw-rounded-b-max"
         :style="{
         'background-image': `url(${require('@/assets/images/intermarche/stars.png')})`,
       }">
      <div class="tw-hidden md:tw-flex tw-flex tw-relative tw-flex-row-reverse tw-items-start tw-justify-center tw-z-20">
        <img class="tw-block tw--ml-10" src="@/assets/images/intermarche/phone-folders.png" alt="Phone" width="500"
             data-aos="fade-left" data-aos-once="true">
        <img class="tw-block tw--mr-10 tw-mt-6" src="@/assets/images/intermarche/phone-contents.png" alt="Phone" width="500"
             data-aos="fade-right" data-aos-delay="200" data-aos-once="true">
      </div>
      <div class="tw-flex md:tw-hidden tw-relative tw-flex-row-reverse tw-items-start tw-justify-between tw-z-20 tw-pt-6">
        <img class="tw-block" src="@/assets/images/intermarche/phone-folders-2.png" alt="Phone" style="width: 48%;"
             data-aos="fade-left" data-aos-once="true">
        <img class="tw-block" src="@/assets/images/intermarche/phone-contents-2.png" alt="Phone" style="width: 48%;"
             data-aos="fade-right" data-aos-delay="200" data-aos-once="true">
      </div>
      <img class="tw-hidden md:tw-block tw-mx-auto tw-mt-6" src="@/assets/images/intermarche/intermarche-logo.png" alt="Intermarche" width="420"
           data-aos="fade-down" data-aos-delay="200" data-aos-once="true">
      <img class="tw-block md:tw-hidden tw-mx-auto tw-mt-6" src="@/assets/images/intermarche/intermarche-logo-2.png" alt="Intermarche" width="160"
           data-aos="fade-down" data-aos-delay="200" data-aos-once="true">
      <div class="tw-mt-8 tw-px-6 tw-text-white tw-font-nunito-extrabold tw-text-2xl md:tw-text-3xl tw-text-center title-shadow"
           data-aos="fade-down" data-aos-delay="400" data-aos-once="true">
        Plus de <span class="tw-text-yellow">3500 vidéos, histoires et jeux éducatifs</span><br>
        dans 2 applications et sur tous vos écrans
      </div>
      <img class="tw-block tw-mx-auto tw-mt-8 tw-w-52 md:tw-w-auto" src="@/assets/images/intermarche/kidjo-tv-stories-logo.png" alt="Kidjo"
           data-aos="fade-down" data-aos-delay="600" data-aos-once="true" id="form-top">
      <div class="tw-relative">
        <form class="tw-flex tw-flex-col tw-items-center tw-gap-y-4 tw-mt-10 tw-px-6 tw-text-center tw-font-nunito"
              @submit.prevent="handleSubmit">
          <input class="tw-w-full md:tw-w-72 tw-rounded-xl tw-border-3 tw-border-darkbrown tw-text-lg tw-px-5 tw-py-2 tw-outline-none"
                 placeholder="Prénom" type="text" v-model="name" :class="(nameError ? 'has-error' : '')" required
                 data-aos="fade-down" data-aos-once="true">
          <input class="tw-w-full md:tw-w-72 tw-rounded-xl tw-border-3 tw-border-darkbrown tw-text-lg tw-px-5 tw-py-2 tw-outline-none"
                 placeholder="E-mail" type="text" v-model="email" :class="(emailError ? 'has-error' : '')" required
                 data-aos="fade-down" data-aos-delay="100" data-aos-once="true">
          <input class="tw-w-full md:tw-w-72 tw-rounded-xl tw-border-3 tw-border-darkbrown tw-text-lg tw-px-5 tw-py-2 tw-outline-none"
                 placeholder="Mot de passe" type="password" v-model="password" :class="(passwordError ? 'has-error' : '')" required
                 data-aos="fade-down" data-aos-delay="200" data-aos-once="true">
          <div class="tw-w-full md:tw-w-auto tw-relative"
               data-aos="fade-down" data-aos-delay="300" data-aos-once="true">
            <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-5">
              <img src="@/assets/images/intermarche/coupon-icon.png" alt="Coupon" width="23">
            </div>
            <input class="tw-w-full md:tw-w-72 tw-rounded-xl tw-border-3 tw-border-darkbrown tw-text-lg tw-px-5 tw-pl-14 tw-py-2 tw-outline-none"
                   placeholder="Code promo" type="text" v-model="coupon">
          </div>
          
          <div class="tw-text-center tw-text-white tw-font-nunito tw-mt-5">
            Saisissez votre code composé de chiffres et de lettres MAJUSCULES
          </div>

          <input class="tw-w-60 tw-rounded-xl tw-bg-green tw-border-3 tw-border-darkbrown tw-text-1.5xl tw-outline-none
                 tw-px-5 tw-py-2.5 tw-mt-1 tw-font-nunito-extrabold tw-text-white tw-cursor-pointer
                 hover:tw-bg-lightgreen tw-transition-colors tw-ease-in tw-duration-200"
                 value="S'enregistrer" type="submit"
                 data-aos="fade-down" data-aos-delay="400" data-aos-once="true">
        </form>
        <div class="tw-text-center tw-text-white tw-font-nunito tw-mt-5"
             data-aos="fade" data-aos-delay="600" data-aos-once="true">
          Un souci pour entrer votre code ?<br>
          Contactez <a class="tw-font-bold tw-underline" href="mailto:support@kidjo.tv">support@kidjo.tv</a>
        </div>
      </div>
    </div>
    <div>
      <img class="tw-block tw-w-full md:tw-w-510px tw-mx-auto tw-px-4 md:tw-px-0 tw--mt-36"
           src="@/assets/images/intermarche/multi-devices-characters.png" alt="Multi devices"
           data-aos="fade" data-aos-delay="600" data-aos-once="true">
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-y-16 md:tw-gap-x-12 tw-px-6 md:tw-px-36 tw-mt-16 md:tw-mt-10">
        <div class="tw-flex tw-flex-col tw-flex-1 tw-text-center"
             data-aos="fade" data-aos-once="true">
          <img class="tw-block tw-mx-auto" src="@/assets/images/intermarche/feature-listen.png" alt="Audio">
          <div class="tw-font-nunito-extrabold md:tw-font-nunito tw-text-darkblue tw-text-xl md:tw-text-2xl tw-mt-7">
            Des contenus ludo-éducatifs variés sélectionnés avec soin
          </div>
          <div class="tw-font-nunito tw-text-lightgray tw-mt-3 md:tw-mt-5 tw-text-sm md:tw-text-md">
            Des personnages et histoires emblématiques (Trotro, Sam Sam, Didou, Molang, Barbie, Sam Le Pompier,
            Les Contes de Perrault…)
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-flex-1 tw-text-center"
             data-aos="fade" data-aos-delay="200" data-aos-once="true">
          <img class="tw-block tw-mx-auto" src="@/assets/images/intermarche/feature-kids.png" alt="Audio">
          <div class="tw-font-nunito-extrabold md:tw-font-nunito tw-text-darkblue tw-text-xl md:tw-text-2xl tw-mt-7">
            2 applications pensées pour les enfants
          </div>
          <div class="tw-font-nunito tw-text-lightgray tw-mt-3 md:tw-mt-5 tw-text-sm md:tw-text-md">
            Une ergonomie conçue par des spécialistes pour les yeux et les mains des enfants.
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-flex-1 tw-text-center"
             data-aos="fade" data-aos-delay="400" data-aos-once="true">
          <img class="tw-block tw-mx-auto" src="@/assets/images/intermarche/feature-idea.png" alt="Audio">
          <div class="tw-font-nunito-extrabold md:tw-font-nunito tw-text-darkblue tw-text-xl md:tw-text-2xl tw-mt-7">
            De nombreuses fonctionnalités pédagogiques
          </div>
          <div class="tw-font-nunito tw-text-lightgray tw-mt-3 md:tw-mt-5 tw-text-sm md:tw-text-md">
            Les applications s’adaptent à tous les profils grâce à des contrôles parentaux dédiés, des mini jeux,
            de nombreuses aides à la lecture et des quizz à la fin de chaque livre.
          </div>
        </div>
      </div>
      <div class="tw-mt-10">
        <div class="auto-slider-container">
          <div class="auto-slider-row">
            <img src="@/assets/images/intermarche/row-1/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/14.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/14.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-1/14.png" alt="Contenu">
          </div>
        </div>
        <div class="auto-slider-container">
          <div class="auto-slider-row reverse">
            <img src="@/assets/images/intermarche/row-2/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-2/13.png" alt="Contenu">
          </div>
        </div>
        <div class="auto-slider-container">
          <div class="auto-slider-row">
            <img src="@/assets/images/intermarche/row-3/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/14.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/14.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/1.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/2.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/3.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/4.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/5.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/6.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/7.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/8.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/9.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/10.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/11.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/12.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/13.png" alt="Contenu">
            <img src="@/assets/images/intermarche/row-3/14.png" alt="Contenu">
          </div>
        </div>
      </div>
      <img class="tw-hidden md:tw-block tw-mx-auto tw-mt-16" src="@/assets/images/intermarche/intermarche-logo.png"
           alt="Intermarche" width="520" data-aos="fade-down" data-aos-once="true">
      <img class="tw-block md:tw-hidden tw-mx-auto tw-mt-16" src="@/assets/images/intermarche/intermarche-logo-2.png"
           alt="Intermarche" width="215" data-aos="fade-down" data-aos-once="true">
      <a class="tw-block tw-w-52 md:tw-w-48 tw-rounded-xl tw-bg-yellow tw-border-3 tw-border-darkbrown tw-text-xl
                tw-outline-none tw-px-5 tw-py-2 md:tw-py-1.5 tw-mt-14 tw-mx-auto tw-font-nunito-extrabold tw-text-white
                tw-cursor-pointer hover:tw-bg-lightyellow tw-transition-colors tw-ease-in tw-duration-200 tw-text-center"
              data-aos="fade-down" data-aos-delay="200" data-aos-once="true" href="#form-top">
        Commencer
      </a>
      <div class="tw-relative tw-mx-auto tw-my-16 md:tw-my-36 tw-px-2 md:tw-px-0" style="max-width: 900px; width: 100%;">
        <div class="tw-mx-10">
          <div class="tw-relative tw-z-10 md:tw-h-16 tw-bg-white tw-flex tw-items-center tw-justify-center
                    tw-mx-auto tw--mb-8 tw-py-2 md:tw-py-0 tw-rounded-4xl tw-border-3 tw-border-lightblue
                    tw-font-nunito-extrabold tw-text-lightblue tw-text-2.5xl md:tw-text-4xl tw-text-center"
               style="max-width: 520px; width: 100%;">
            Comment ça marche ?
          </div>
        </div>
        <div class="tw-w-full tw-bg-lightblue tw-px-6 md:tw-pl-44 md:tw-pr-24 tw-pt-24 tw-pb-40 md:tw-py-24 tw-rounded-4xl">
          <div class="tw-flex tw-flex-col tw-gap-y-10">
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center md:tw-items-start"
                 data-aos="fade-down" data-aos-once="true">
              <div class="tw-w-12 tw-h-12 tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-bg-white
                          tw-rounded-full tw-mb-4 md:tw-mb-0 md:tw-mr-8 tw-font-nunito-extrabold tw-text-lightblue
                          tw-text-2.5xl">
                1.
              </div>
              <div class="tw-font-nunito-extrabold tw-text-white tw-text-2.5xl tw-text-center md:tw-text-left">
                Une connexion simple via l’activation d’un unique code promotionnel
              </div>
            </div>
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center md:tw-items-start"
                 data-aos="fade-down" data-aos-delay="200" data-aos-once="true">
              <div class="tw-w-12 tw-h-12 tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-bg-white
                          tw-rounded-full tw-mb-4 md:tw-mb-0 md:tw-mr-8 tw-font-nunito-extrabold tw-text-lightblue
                          tw-text-2.5xl">
                2.
              </div>
              <div class="tw-font-nunito-extrabold tw-text-white tw-text-2.5xl tw-text-center md:tw-text-left">
                Accès aux deux applications Kidjo TV et Kidjo Stories avec un même compte utilisateur
                <span class="tw-font-nunito">iOS / Android</span>
              </div>
            </div>
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center md:tw-items-start"
                 data-aos="fade-down" data-aos-delay="400" data-aos-once="true">
              <div class="tw-w-12 tw-h-12 tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-bg-white
                          tw-rounded-full tw-mb-4 md:tw-mb-0 md:tw-mr-8 tw-font-nunito-extrabold tw-text-lightblue
                          tw-text-2.5xl">
                3.
              </div>
              <div class="tw-font-nunito tw-text-white tw-text-2.5xl tw-text-center md:tw-text-left">
                Après avoir <span class="tw-font-nunito-extrabold">téléchargé les applis</span>, cliquez sur un contenu
                verrouillé puis <span class="tw-font-nunito-extrabold">Se connecter</span> et connectez vous avec votre
                compte.
              </div>
            </div>
            <div class="tw-flex tw-justify-center md:tw-justify-start md:tw-items-start"
                 data-aos="fade-down" data-aos-delay="600" data-aos-once="true">
              <div class="md:tw-w-12 md:tw-h-12 tw-flex tw-flex-shrink-0 md:tw-mr-8">
              </div>
              <a class="tw-block tw-w-52 tw-rounded-xl tw-bg-yellow tw-border-3 tw-border-darkbrown tw-text-xl
                 tw-outline-none tw-px-5 tw-py-2.5 tw-font-nunito-extrabold tw-text-white tw-text-center
                 tw-cursor-pointer hover:tw-bg-lightyellow tw-transition-colors tw-ease-in tw-duration-200"
                 href="#form-top">
                Commencer
              </a>
            </div>
          </div>
        </div>
        <img class="tw-absolute tw-hidden md:tw-block tw--left-2 tw--bottom-24"
             src="@/assets/images/intermarche/garfield.png" alt="Garfield" width="220">
        <img class="tw-absolute tw-block md:tw-hidden tw-left-28 tw--bottom-28"
             src="@/assets/images/intermarche/garfield.png" alt="Garfield" width="160">
      </div>
      <footer class="tw-px-6 md:tw-px-16 tw-mb-10 md:tw-mb-20 tw-pt-12 md:tw-pt-0">
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-px-8 tw--mb-6 tw-gap-y-6 md:tw-gap-y-0">
          <img src="@/assets/images/logo.svg" alt="Kidjo" width="112">
          <div class="tw-flex tw-items-center tw-gap-x-8">
            <a href="https://www.facebook.com/Kidjotv/?fref=ts" target="_blank">
              <img src="@/assets/images/intermarche/social-facebook.png" alt="Facebook" style="height: 20px;">
            </a>
            <a href="https://twitter.com/Kidjotv" target="_blank">
              <img src="@/assets/images/intermarche/social-twitter.png" alt="Twitter" style="height: 18px;">
            </a>
          </div>
        </div>
        <div class="tw-w-full tw-h-px tw-bg-bordergray tw-mt-16 tw-mb-12 md:tw-my-12"></div>
        <div class="tw-px-8 tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-font-nunito tw-gap-y-6 md:tw-gap-y-0">
          <ul class="tw-flex tw-gap-x-12 tw-text-foreground tw-text-xs">
            <li><a :href="websiteUrl + 'terms'">Legal Notices</a></li>
            <li><a :href="websiteUrl + 'privacy'">Privacy Policy</a></li>
            <li><a :href="websiteUrl + 'cookies'">GSCs</a></li>
          </ul>
          <div>Copyright © 2022</div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

import PasswordService from "@/services/password.service";

export default {
  data() {
    return {
      name: '',
      nameError: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      coupon: '',
      loading: false,
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,
    }
  },
  mounted() {
    AOS.init();
  },
  methods: {
    handleSubmit: async function() {
      this.loading = true;
      const hasError = this.checkFields();

      if (!hasError) {
        const hash = PasswordService.SHA256(this.password);
        const data = {
          'email': this.email,
          'password': hash,
          'displayName': this.name,
          'coupon': this.coupon,
          'env': 'web',
          'countryCode': this.$parent.countryCode
        };

        const registered = await this.$store.dispatch('registerUser', data);

        this.loading = false;
        if (registered.status !== true) {
          return this.handleError(registered);
        }

        if (registered.freeCampaign === true) {
          this.removeCouponFromStorage();
          return this.$router.push('/products').catch(()=>{});
        }

        this.$router.push('/subscribe').catch(()=>{});
      }
    },
    handleError: function(response) {
      if (response.status === 400) {
        if (response.data.code === 2) {
          return this.$parent.showFlashMessage(this.$t('error_message_email_invalid'));
        }

        if (response.data.code === 3) {
          return this.$parent.showFlashMessage(this.$t('error_message_coupon_invalid'));
        }

        if (response.data.code === 5) {
          return this.$parent.showFlashMessage(this.$t('error_message_email_already_taken'));
        }

        return this.$parent.showFlashMessage(this.$t('error_message_something_went_wrong'));
      }
    },
    checkFields: function() {
      let hasError = false;

      if (this.name === '') {
        this.nameError = true;
        hasError = true;
      }

      if (this.email === '') {
        this.emailError = true;
        hasError = true;
      }

      if (this.password === '') {
        this.passwordError = true;
        hasError = true;
      }

      return hasError;
    },
    goToForm() {
      const slider = document.getElementById('slider');
      const form = document.getElementById('form');

      form.style.display = 'block';
      slider.style.display = 'none';

      if (window.innerWidth <= 340) {
        window.scrollTo(0, 30);
      }
    },
    removeCouponFromStorage: function() {
      localStorage.removeItem('coupon');
      localStorage.removeItem('couponDurationCode');
    },
  },
}
</script>

<style>
  .title-shadow {
    text-shadow: 2px 0 0 rgba(109, 76, 65, 0.5);
  }

  .auto-slider-container {
    height: 156px;
    margin: 10px 0;
    overflow: hidden;
    position: relative;
  }

  .auto-slider-row {
    animation: row-anim 60s linear infinite;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }

  .auto-slider-row.reverse {
    animation: row-anim-reverse 60s linear infinite;
    left: auto;
    right: 0;
  }

  .auto-slider-row img {
    display: inline-block;
    margin: 0 6px;
    max-width: none;
  }

  @keyframes row-anim {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 0);
    }
  }

  @keyframes row-anim-reverse {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(50%, 0);
    }
  }
</style>

<style lang="sass">
.slide-fade-enter-active
  transition: all .3s ease

.slide-fade-leave-active
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(10px)
  opacity: 0
</style>