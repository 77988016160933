<template>
  <svg
    width="19"
    height="23"
    viewBox="0 0 19 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.243 4.019C14.548 2.455 14.429 1.032 14.39.52c-1.152.068-2.486.792-3.247 1.684-.837.956-1.33 2.138-1.224 3.47 1.248.097 2.386-.55 3.324-1.655zm.75 18.116c2.325-.034 4.324-3.981 4.907-5.757-2.985-1.419-2.959-4.102-2.951-4.409v-.026c-.01-1.771.785-3.108 2.391-4.092-.899-1.299-2.257-2.013-4.05-2.153-1.698-.135-3.553 1-4.232 1-.718 0-2.363-.952-3.654-.952C3.736 5.79.9 7.894.9 12.174c0 1.265.23 2.571.689 3.919.612 1.771 2.821 6.114 5.126 6.042 1.205-.029 2.056-.864 3.625-.864 1.52 0 2.31.864 3.653.864zm-.75-18.116C14.548 2.455 14.429 1.032 14.39.52c-1.152.068-2.486.792-3.247 1.684-.837.956-1.33 2.138-1.224 3.47 1.248.097 2.386-.55 3.324-1.655zm.75 18.116c2.325-.034 4.324-3.981 4.907-5.757-2.985-1.419-2.959-4.102-2.951-4.409v-.026c-.01-1.771.785-3.108 2.391-4.092-.899-1.299-2.257-2.013-4.05-2.153-1.698-.135-3.553 1-4.232 1-.718 0-2.363-.952-3.654-.952C3.736 5.79.9 7.894.9 12.174c0 1.265.23 2.571.689 3.919.612 1.771 2.821 6.114 5.126 6.042 1.205-.029 2.056-.864 3.625-.864 1.52 0 2.31.864 3.653.864z"
      fill="#FFF"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  name: "AppleIcon",
};
</script>
