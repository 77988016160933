export default () => {
	return [
		"BE",
		"EL",
		"LT",
		"PT",
		"BG",
		"ES",
		"LU",
		"RO",
		"CZ",
		"FR",
		"HU",
		"SI",
		"DK",
		"HR",
		"MT",
		"SK",
		"DE",
		"IT",
		"NL",
		"FI",
		"EE",
		"CY",
		"AT",
		"SE",
		"IE",
		"LV",
		"PL"
	];
}
