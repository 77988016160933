import store from '@/store/index';
import { isNull, isObj } from '@/utils';
import { subscribeSevaMedia } from '@/services/publisher.service';
import PasswordService from '@/services/password.service';

const isValidInitialState = (publisher) => {
  const { id, settings } = publisher;
  return isNull(id)
    && isObj(settings);
};

const initState = initialState => {
  if (!isValidInitialState(initialState)) {
    throw Error('Invalid initial publisher state');
  }
  const { id, settings } = initialState;
  return {
    id,
    settings
  };
};

export const getters = {
  publisherId: ({ id }) => id,
  publisherSettings: ({ settings }) => settings,
};

export const actions = {
  subscribe({ commit, state }, { id, phone, coupon }) {
    return subscribeSevaMedia({ phone, coupon })
      .then((res) => {
        const { data } = res;
        const nextUser = {
          email: data.email,
          password: PasswordService.SHA256(coupon),
        };

        return nextUser;
      })
      .then((user) => store.dispatch('loginUser', user));
  },
};

export const mutations = {
  SET(state, publisher) {
    state.id = publisher.id;
    state.settings = publisher.settings;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});