const clientSideHashInfo = 'n2iciz509zQV2MRlZDSiI3DFrol'

class PasswordService {

	SHA256(password) {
		var r = password + clientSideHashInfo;
		function n(r, n) {
			var t = (65535 & r) + (65535 & n),
				o = (r >> 16) + (n >> 16) + (t >> 16);
			return (o << 16) | (65535 & t);
		}
		function t(r, n) {
			return (r >>> n) | (r << (32 - n));
		}
		function o(r, n) {
			return r >>> n;
		}
		function e(r, n, t) {
			return (r & n) ^ (~r & t);
		}
		function u(r, n, t) {
			return (r & n) ^ (r & t) ^ (n & t);
		}
		function f(r) {
			return t(r, 2) ^ t(r, 13) ^ t(r, 22);
		}
		function a(r) {
			return t(r, 6) ^ t(r, 11) ^ t(r, 25);
		}
		function c(r) {
			return t(r, 7) ^ t(r, 18) ^ o(r, 3);
		}
		function i(r) {
			return t(r, 17) ^ t(r, 19) ^ o(r, 10);
		}
		function h(r, t) {
			var o,
				h,
				C,
				g,
				d,
				v,
				A,
				S,
				b,
				p,
				y = [
					1116352408,
					1899447441,
					3049323471,
					3921009573,
					961987163,
					1508970993,
					2453635748,
					2870763221,
					3624381080,
					310598401,
					607225278,
					1426881987,
					1925078388,
					2162078206,
					2614888103,
					3248222580,
					3835390401,
					4022224774,
					264347078,
					604807628,
					770255983,
					1249150122,
					1555081692,
					1996064986,
					2554220882,
					2821834349,
					2952996808,
					3210313671,
					3336571891,
					3584528711,
					113926993,
					338241895,
					666307205,
					773529912,
					1294757372,
					1396182291,
					1695183700,
					1986661051,
					2177026350,
					2456956037,
					2730485921,
					2820302411,
					3259730800,
					3345764771,
					3516065817,
					3600352804,
					4094571909,
					275423344,
					430227734,
					506948616,
					659060556,
					883997877,
					958139571,
					1322822218,
					1537002063,
					1747873779,
					1955562222,
					2024104815,
					2227730452,
					2361852424,
					2428436474,
					2756734187,
					3204031479,
					3329325298,
				],
				B = [1779033703, 3144134277, 1013904242, 2773480762, 1359893119, 2600822924, 528734635, 1541459225],
				D = Array(64);
			(r[t >> 5] |= 128 << (24 - (t % 32))), (r[(((t + 64) >> 9) << 4) + 15] = t);
			for (var l = 0; l < r.length; l += 16) {
				(o = B[0]), (h = B[1]), (C = B[2]), (g = B[3]), (d = B[4]), (v = B[5]), (A = B[6]), (S = B[7]);
				for (var m = 0; 64 > m; m++)
					16 > m ? (D[m] = r[m + l]) : (D[m] = n(n(n(i(D[m - 2]), D[m - 7]), c(D[m - 15])), D[m - 16])),
						(b = n(n(n(n(S, a(d)), e(d, v, A)), y[m]), D[m])),
						(p = n(f(o), u(o, h, C))),
						(S = A),
						(A = v),
						(v = d),
						(d = n(g, b)),
						(g = C),
						(C = h),
						(h = o),
						(o = n(b, p));
				(B[0] = n(o, B[0])), (B[1] = n(h, B[1])), (B[2] = n(C, B[2])), (B[3] = n(g, B[3])), (B[4] = n(d, B[4])), (B[5] = n(v, B[5])), (B[6] = n(A, B[6])), (B[7] = n(S, B[7]));
			}
			return B;
		}
		function C(r) {
			for (var n = [], t = (1 << v) - 1, o = 0; o < r.length * v; o += v) n[o >> 5] |= (r.charCodeAt(o / v) & t) << (24 - (o % 32));
			return n;
		}
		function g(r) {
			r = r.replace(/\r\n/g, "\n");
			for (var n = "", t = 0; t < r.length; t++) {
				var o = r.charCodeAt(t);
				128 > o
					? (n += String.fromCharCode(o))
					: o > 127 && 2048 > o
					? ((n += String.fromCharCode((o >> 6) | 192)), (n += String.fromCharCode((63 & o) | 128)))
					: ((n += String.fromCharCode((o >> 12) | 224)), (n += String.fromCharCode(((o >> 6) & 63) | 128)), (n += String.fromCharCode((63 & o) | 128)));
			}
			return n;
		}
		function d(r) {
			for (var n = A ? "0123456789ABCDEF" : "0123456789abcdef", t = "", o = 0; o < 4 * r.length; o++) t += n.charAt((r[o >> 2] >> (8 * (3 - (o % 4)) + 4)) & 15) + n.charAt((r[o >> 2] >> (8 * (3 - (o % 4)))) & 15);
			return t;
		}
		var v = 8,
			A = 0;
		return (r = g(r)), d(h(C(r), r.length * v));
	}
}

export default new PasswordService;