<template>
  <svg
    width="64"
    height="71"
    viewBox="0 0 64 71"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-10.3%"
        y="-7.9%"
        width="120.5%"
        height="117.1%"
        filterUnits="objectBoundingBox"
        id="hcun7i4rfa"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#hcun7i4rfa)"
      transform="translate(4.719 4.531)"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M10.038 60.002 9.14 58.9l-.851.541a2.606 2.606 0 0 1-2.854-.036 2.61 2.61 0 0 1-1.114-2.627l.363-2.03a2.666 2.666 0 0 1-.063-.034C2.325 53.455.682 50.084.234 49.081a2.667 2.667 0 0 1-.191-1.57l.403-2.224v-.023l.022-.106.021-.126.011-.031L9.08 4.525a4.706 4.706 0 0 1 5.5-3.644l32.338 6.276a5.042 5.042 0 0 1 3.594 2.93 6.832 6.832 0 0 1 2.973 2.476 6.808 6.808 0 0 1 1.018 5.23l-7.136 34.06a10.987 10.987 0 0 1-4.67 6.916 10.986 10.986 0 0 1-8.176 1.677l-19.56-3.66-.324 1.987a2.614 2.614 0 0 1-2.576 2.19 2.6 2.6 0 0 1-2.023-.961z"
        stroke="#FFF"
        stroke-width="3.213"
        fill="#FFF"
      />
      <path
        d="m3.1 45.647-.423 2.34s1.545 3.456 3.232 4.38l29.105 5.448a8.371 8.371 0 0 0 9.733-6.511l7.136-34.06a4.185 4.185 0 0 0-3.337-4.973l-3.228-.595"
        fill="#2486FC"
      />
      <path
        d="m4.126 45.78 1.426 3.946 30.106 5.89a5.73 5.73 0 0 0 6.71-4.46l7.57-36.477a2.022 2.022 0 0 0-.89-2.132"
        fill="#FFF"
      />
      <path
        d="m3.1 45.647-.423 2.34s1.545 3.456 3.232 4.38l29.105 5.448a8.371 8.371 0 0 0 9.733-6.511l7.136-34.06a4.185 4.185 0 0 0-3.337-4.973l-3.228-.595"
        stroke="#6D4C43"
        stroke-width="1.343"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m3.1 45.647 32.816 6.052a4.539 4.539 0 0 0 5.277-3.585l7.017-35.576a2.353 2.353 0 0 0-1.833-2.76L15.145 3.336a3.492 3.492 0 0 0-4.13 2.735L3.1 45.647z"
        fill="#2486FC"
      />
      <path
        d="m48.21 12.539-7.017 35.576a4.54 4.54 0 0 1-5.278 3.584L3.101 45.647l1.995-9.974c.383 4.798 3.934 8.944 8.906 9.86l21.913 4.042a4.54 4.54 0 0 0 5.278-3.586l6.872-34.834c.18.421.24.9.145 1.384"
        fill="#6D4C41"
        fill-rule="nonzero"
        opacity=".29"
      />
      <path
        d="m48.21 12.539-4.052 20.546 2.544-19.626c.036-.186.05-.372.043-.554-.436.17-.967.137-1.433-.132-.773-.445-1.072-1.374-.669-2.072a.46.46 0 0 1 .027-.042L13.637 4.256a3.465 3.465 0 0 0-1.712.076 3.49 3.49 0 0 1 3.22-.996l31.232 6.442a2.353 2.353 0 0 1 1.833 2.761"
        fill-opacity=".5"
        fill="#FFF"
      />
      <path
        d="m3.1 45.647 32.816 6.052a4.539 4.539 0 0 0 5.277-3.585l7.017-35.576a2.353 2.353 0 0 0-1.833-2.76L15.145 3.336a3.492 3.492 0 0 0-4.13 2.735L3.1 45.647z"
        stroke="#6D4C43"
        stroke-width="1.343"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.496 24.114a5.706 5.706 0 1 1-11.413 0 5.706 5.706 0 0 1 11.413 0"
        fill="#FFF"
      />
      <path
        d="M26.496 24.114a5.706 5.706 0 1 1-11.413 0 5.706 5.706 0 0 1 11.413 0z"
        stroke="#6D4C43"
        stroke-width="1.074"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.48 23.533a2.879 2.879 0 1 1-2.494-2.853 1.561 1.561 0 1 0 2.34 1.927c.1.29.153.602.153.926M28.38 27.741c.287-.304.594-.599.933-.857a8.048 8.048 0 0 1 1.078-.702 8.746 8.746 0 0 1 2.452-.849 15.69 15.69 0 0 1 1.276-.188c.44-.042.89-.056 1.343-.01.903.074 1.838.394 2.571.952a.604.604 0 0 1-.648 1.014l-.006-.003a6.123 6.123 0 0 0-2.067-.683 8.429 8.429 0 0 0-1.135-.084c-.399-.005-.8.005-1.195.033a9.435 9.435 0 0 0-2.33.427c-.379.12-.748.278-1.116.45-.365.178-.718.384-1.071.603h-.002a.067.067 0 0 1-.084-.103M32.604 31.96c-.304 1.118-1.122 2.145-2.166 2.73a5.911 5.911 0 0 1-1.643.634 8.435 8.435 0 0 1-1.7.202c-1.13.02-2.235-.138-3.298-.411-1.06-.282-2.09-.666-3.033-1.21a.067.067 0 0 1 .05-.124l.002.002c1.037.27 2.078.466 3.121.579 1.04.116 2.084.15 3.092.05 1.007-.099 1.978-.346 2.811-.79.417-.223.794-.5 1.133-.828.336-.332.626-.718.89-1.145l.006-.008a.405.405 0 0 1 .735.32"
        fill="#6D4C43"
      />
      <path
        d="M30.813 31.62c.22-.191.487-.268.748-.296.262-.024.529.006.777.092.247.086.481.215.673.396.194.177.349.402.426.678-.292-.02-.516-.078-.738-.129-.215-.06-.42-.114-.617-.185a9.82 9.82 0 0 1-.603-.228 6.81 6.81 0 0 1-.666-.327M5.917 47.944c1.594-.06 3.125.205 4.657.47 1.526.29 3.043.632 4.563.96 1.52.33 3.04.654 4.556 1 1.518.339 3.033.686 4.547 1.047a.045.045 0 0 1-.017.088c-1.54-.219-3.078-.451-4.614-.692-1.538-.233-3.071-.49-4.606-.738-1.534-.252-3.07-.49-4.598-.779-1.522-.315-3.044-.629-4.504-1.269a.046.046 0 0 1 .016-.087M26.561 52.079c1.179.163 2.35.358 3.52.565l1.75.32 1.748.34c.582.114 1.162.24 1.743.358l.436.09.217.046.098.019c.033.005.064.011.098.015.27.035.552.05.83.043 1.113-.016 2.223-.376 3.045-1.239a.056.056 0 0 1 .09.064c-.274.547-.743 1.006-1.297 1.311a4.305 4.305 0 0 1-1.81.509c-.315.02-.628.013-.946-.027-.04-.004-.081-.011-.122-.017-.051-.008-.084-.017-.12-.024l-.218-.048-.435-.095c-.58-.128-1.16-.25-1.739-.383l-1.735-.4-1.73-.42a95.745 95.745 0 0 1-3.446-.918.056.056 0 0 1 .023-.109"
        fill="#6D4C43"
      />
      <path
        d="m4.126 45.78 1.426 3.946 30.106 5.89a5.73 5.73 0 0 0 6.71-4.46l7.57-36.477a2.022 2.022 0 0 0-.89-2.132h0"
        stroke="#6D4C43"
        stroke-width="1.074"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill="#FFC926"
        d="m8.405 48.768 5.083.847-1.427 8.738-2.363-2.898-2.808 1.783z"
      />
      <path
        d="m13.487 49.615-.206 1.265-2.283-.38a1.607 1.607 0 0 0-1.847 1.303l-.808 4.511-1.453.924 1.515-8.47 5.082.847z"
        fill="#6D4C41"
        fill-rule="nonzero"
        opacity=".29"
      />
      <path
        stroke="#6D4C43"
        stroke-width="1.208"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m8.405 48.768 5.083.847-1.427 8.738-2.363-2.898-2.808 1.783z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "StoriesAppDecoration",
};
</script>
