<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="one-row">
    <div class="subscription-content">
      <div class="subscription-header">
        <span class="step">{{
            $t("subscription_pages_steps_text", {step: active_step})
          }}</span>
        <span v-for="(title, index) in titles" :key="index">
          <span class="primary" v-if="title.status">{{ title.name }}</span>
        </span>
        <span class="secondary" v-if="active_step === 1 && !isChangePlan && promoMessage!==null">
          <img src="../assets/images/tag-promo.png"/>
          {{
            promoMessage
          }}
        </span>
      </div>
      <!--- Step1 -->
      <div v-show="active_step === 1">
        <div class="separator-with-text" v-show="!isChangePlan">
          <span>{{ $t("plan_select_page_then_gray_separator") }}</span>
        </div>
        <div class="plan-select">
          <button
              class="plan"
              :class="type.nameKey === currentPlanType? 'selected' : ''"
              :disabled="type.nameKey === currentPlanType"
              v-for="(type, index) in allPlans.userPlan"
              @click="onPlanTypeSelect(type.nameKey)"
              :key="index"
          >
            {{ $t(type.nameKey) }}
          </button>
          <span class="promo" v-if="activePlanPromo"
          ><img src="../assets/images/sale.svg"/>
            {{ $t("plan_select_page_promotion_text") }}</span
          >
        </div>
        <div class="selectable-features with-logos">
          <div
              class="feature is-active"
              :class="{
              'is-selected':
                (currentSelectedPlans.find(product=>plan.product ===product.product)) ,
            }"
              v-for="(plan, plan_index) in selectedTypePlans"
              :key="plan_index"
          >
            <div class="box">
              <div class="subscription-card-graphic">
                <component :is="plan.product"/>
              </div>

              <div class="subscription-card-details">
                <div class="text-wrapper">
                  <p class="title">
                    {{ plan.productLabel }}
                  </p>
                  <p class="description" v-if="featureDesktopTitle">
                    {{ $t(promoTitles[plan.product]) }}
                  </p>
                </div>
                <Checkbox class="checkbox"
                          :disabled="selectedTypePlans.length === 1"
                          :isChecked="Boolean(currentSelectedPlans.find(current=> current.product=== plan.product))"
                          @onChange="$event => onPlanSelect($event,plan)"
                />
              </div>
            </div>
          </div>
        </div>

        <p class="total">
          Total:
          <span class="price">{{ totalPrice }} {{ currentSelectedPlan.currencySymbol }}</span>
          <!--For Existing Customers-->
          <s v-show="(subscriptionTypes.length > 0 && currentSelectedPlans.length >= 1 && originalPrice!==totalPrice) || this.discountPrice>0">
            ({{
              originalPrice
            }} {{ currentSelectedPlan.currencySymbol }})
          </s>
        </p>
        <div class="feature-continue">
          <button
              @click="showPaymentOptions"
              class="green-button btn-gn animated-button"
              :class="{'disabled': !totalPrice}"
              :disabled="!totalPrice"
          >
            {{ $t("plan_select_page_start_button") }}
          </button>
          <span>{{ $t("plan_select_page_under_start_button_text") }}</span>
        </div>
      </div>
      <!--Step 2  -->
      <div v-show="active_step === 2">
        <div class="payment-methods">
          <span class="payment-header"
          ><img src="../assets/images/lock.png"/>
            {{ $t("payment_select_page_secure_payment_text") }}</span
          >
          <div
              class="cupons-check"
              v-if="has_coupon === false && showCouponError !== true && !hasSubscription"
          >
            <div class="promo-text">
              <span
              ><img src="../assets/images/tag-promo.png"/> Code promo</span
              >
            </div>
            <div class="check-form">
              <form @submit.prevent="checkCoupon">
                <input
                    :class="coupon_error === true ? 'has-error' : ''"
                    type="text"
                    name="coupon"
                    placeholder="RFZTH5"
                    v-model="coupon"
                />
                <button type="submit">
                  <img src="../assets/images/ic_check_white.png"/>
                </button>
              </form>
            </div>
          </div>
          <div class="cupons-check" v-else-if="showCouponError !== true">
            <span class="coupon">
              {{
                promoMessage
              }}
            </span>
          </div>
          <div class="cupons-check" v-if="showCouponError">
            <span v-if="this.couponFor !== 'combo'" class="coupon">{{
                $t("error_message_subscribe_coupon_product_mismatch")
              }}</span>
          </div>
          <div class="payment-methods-options">
            <div class="flex-grid payment-methods-option" @click="showCardForm">
              <span class="text">{{
                  $t("payment_select_page_card_option_text")
                }}</span>
              <span class="image"
              ><img
                  class="cards-image"
                  src="../assets/images/cards.svg"
                  alt="Credit or debit cards"
              /></span>
              <span class="arrow"
              ><img src="../assets/images/arrow-right.svg"
              /></span>
            </div>

            <div class="paypal-wrap">
              <div class="flex-grid payment-methods-option paypal">
                <span class="image"
                ><img
                    class="paypal-image"
                    src="../assets/images/pay_pal_full_color_horizontal.svg"
                    alt="PayPall"
                /></span>
                <span class="text"></span>
                <span class="arrow"
                ><img src="../assets/images/arrow-right.svg"
                /></span>
              </div>
              <div id="paypalButton" class="hide-over-button"></div>
            </div>
          </div>
        </div>
      </div>
      <!--- Step 3-->
      <div v-show="active_step === 3">
        <div class="subscription-content">
          <div class="card-details">
            <span class="details-header"
            ><img src="../assets/images/cards.svg"
            /></span>

            <div class="card-form">
              <div class="card-input" id="cardHolderName"></div>
              <div class="card-input" id="creditCardNumber"></div>
              <div class="card-input" id="expireDate"></div>
              <div class="card-input" id="cvv"></div>
            </div>

            <div class="modify-plan">
              <span>
                {{ activePlan }}
                <p>{{ totalPrice }}{{ currentSelectedPlan.currencySymbol }}</p>
              </span>
              <span
              ><a @click="showPlanSelectForm">{{
                  $t("card_details_page_modify_plan_link")
                }}</a></span
              >
            </div>
          </div>

          <div class="continue">
            <button
                @click="payWithCreditCard"
                class="green-button animated-button credit-card-button"
                :disabled="cc_button_loading"
            >
              <span
                  class="loading"
                  style="margin:auto"
                  v-if="cc_button_loading"
              ></span>
              <span v-else>{{ $t("card_details_page_activate_button") }}</span>
            </button>
            <a class="back-link" @click="showPaymentOptions">{{
                $t("card_details_page_back_to_payment_options")
              }}</a>
            <span class="small-text">{{
                $t("card_details_page_small_text")
              }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {client, dataCollector, hostedFields, paypalCheckout} from 'braintree-web';
import StoriesAppGraphic from '../graphics/StoriesAppGraphic.vue';
import TvAppGraphic from '../graphics/TvAppGraphic.vue';
import GamesAppGraphic from '../graphics/GameAppGraphic.vue'
import Checkbox from '../components/Checkbox';
import SubscriptionService from '../services/subscription.service';
import paypal from 'paypal-checkout';
import AuthService from '../services/auth.service';
import {mapGetters} from "vuex";
import {DESCRIPTION_TRANSLATION_KEY, getSubscribedProducts, PRODUCT_LABELS, PRODUCT_TYPES} from "../utils/constant";
import {validateCoupon} from "../services/subscriptions.v2.services";



export default {
  components: {
    books: StoriesAppGraphic,
    tv: TvAppGraphic,
    games: GamesAppGraphic,
    combo: TvAppGraphic,
    Checkbox
  },
  data() {
    return {
      titles: [
        {name: this.$t('plan_select_page_title'), status: true, step: 1},
        {name: this.$t('payment_select_page_title'), status: false, step: 2},
        {name: this.$t('card_details_page_title'), status: false, step: 3},
      ],
      cc_button_loading: false,
      active_step: 1,
      featureDesktopTitle: true,
      promoTitles: DESCRIPTION_TRANSLATION_KEY,
      coupon: '',
      couponDurationCode: '',
      coupon_error: false,
      has_coupon: false,
      couponProduct: '',
      showCouponError: false,
      discountPromoApplied: false,
      accessPromoApplied: false,
      discounts: 0.00,
    };
  },

  computed: {
    ...mapGetters({
      allPlans: 'getUserPlans',
      selectedTypePlans: 'getSelectedPlans',
      currentPlanType: 'getCurrentPlanType',
      currentSelectedPlan: 'getCurrentSelectedPlan',
      currentSelectedPlans: 'getCurrentSelectedPlans',
      showCurrentPlanSelected: 'getShowCurrentPlanSelected'
    }),

    isChangePlan() {
      return this.$route.path === '/changePlan'
    },
    subscriptionTypes() {
      return this.$store.getters.getSubscriptionsList.map((({subscriptionType}) => subscriptionType));
    },
    hasSubscription() {
      return this.$store.getters.getSubscriptionIsRenew
    },

    activePlanPromo: function () {
      let promo = false;

      // for (var i = 0; i < this.plans.length; i++) {
      //   if (this.plans[i].status === true) {
      //     promo = this.plans[i].promo;
      //   }
      // }

      return promo;
    },

    activePlan: function () {
      const appendPlus = this.currentSelectedPlans.length > 1;
      const plan = this.currentSelectedPlans.reduce((accumulator, product, index) => {
        const separator = (appendPlus && index < this.currentSelectedPlans.length - 1) ? '+' : '';
        return accumulator + `${PRODUCT_LABELS[product.product]}${separator} `;
      }, '');
      return `${plan}`
    },
    promoMessage: function () {
      let promo = this.$t("plan_select_page_promo_no_coupon");
      if (this.coupon || localStorage.getItem('coupon')) {
        const {duration,durationPeriod,durationPeriods}=this.getCouponDuration
        if (this.discountPromoApplied) {
          const {isBundleDiscount, planType} = JSON.parse(localStorage.getItem('discountDetails'));
          if (isBundleDiscount && planType === this.currentPlanType && this.currentSelectedPlans.length >= 2) {
            return this.$t("promo_code_message", {durationCode:durationPeriod, duration: `${duration} ${durationPeriods}`, price: this.formattedDiscountPrice})
          }
        } else if (this.accessPromoApplied) {
          return this.$t("plan_select_page_promo_with_coupon", {
            couponBonus: `${duration} ${durationPeriods}`,
          })
        }
      }
      return promo
    },
    getCoupon: function () {
      return this.coupon || localStorage.getItem('coupon');
    },
    getCouponDuration: function () {
      const duration_period = {
        day: this.$t('label_day'),
        days: this.$t('label_days'),
        month: this.$t('month'),
        months: this.$t('label_months'),
      };

      const value = this.couponDurationCode || localStorage.getItem('couponDurationCode');

      const duration = value.replace(/\D/g, '');

      let suffix = 'month';

      if (value.includes('D')) {
        suffix = 'day';
      }

      if (duration > 1) {
        suffix += 's';
      }

      return {duration,"durationPeriods":duration_period[suffix],"durationPeriod":duration_period[suffix.slice(0,-1)]};
    },

    getCouponProduct: function () {
      if (this.subscriptionFor === "tv") {
        return "- Kidjo TV";
      } else if (this.subscriptionFor === "books") {
        return "- Kidjo Stories";
      } else if (this.subscriptionFor === "games") {
        return "- Kidjo Games";
      } else {
        return "- Kidjo TV + Kidjo Stories + Kidjo Games";
      }
    },

    getCouponEligiblePlanType: function () {
      const {couponDurationCode} = this;
      if (this.couponDurationCode && this.couponDurationCode !== '') {
        const regex = /P(\d+)([DMY])/;
        const match = couponDurationCode.match(regex)
        return match[2] === 'M' ? 'monthly_plan' : match[2] === 'D' ? 'daily_plan' : 'annual_plan';
      }
      return 'monthly_plan'
    },

    totalPrice: function () {
      const {currentSelectedPlans, isChangePlan} = this;
      let total = 0.00;
      if (isChangePlan) {
        currentSelectedPlans.forEach((product, index) => {
          if (index === 0) {
            total = total + product.price
          } else if (index === 1) {
            total = product.price + product.addOns[0].price;
          } else if (index === 2) {
            total = product.price + product.addOns[1].price;
          }
        });
        total = total - this.discountPrice;
        return total.toFixed(2);
      } else {
        total = this.totalPriceWithoutModifyingPlan - this.discountPrice;
        return total.toFixed(2);
      }
    }
    ,

    totalPriceWithoutModifyingPlan: function () {
      const {currentSelectedPlans, subscriptionTypes} = this;
      let total = 0.00;
      const subscribedProducts = getSubscribedProducts(subscriptionTypes)
      currentSelectedPlans.forEach((product, index) => {
        if (index === 0) {
          total = total + (subscribedProducts.length === 0 ? product.price :
              subscribedProducts.length === 1 ? product.addOns[0].price
                  : (product.addOns[1].price - product.addOns[0].price))
        } else if (index === 1) {
          total = (subscribedProducts.length === 0 ?
              (product.price + product.addOns[0].price) :
              product.addOns[1].price);
        } else if (index === 2) {
          total = product.price + product.addOns[1].price;
        }
      });
      return total;
    }
    ,

    originalPrice: function () {
      const {currentSelectedPlans,isChangePlan} = this
      let total = 0.00;
      if(!isChangePlan){
        currentSelectedPlans.forEach((product, index) => {
          if (index === 0) {
            total = total + product.price
          } else if (index === 1) {
            total = product.price + product.addOns[0].price;
          } else if (index === 2) {
            total = product.price + product.addOns[1].price;
          }
        });
      }else {
        currentSelectedPlans.forEach((product) => {
          total = total + product.price;
        });
      }
      return total.toFixed(2);
    },
    discountPrice: function () {
      if (localStorage.getItem('discountDetails') != null) {
        const {discountPrice, isBundleDiscount, planType} = JSON.parse(localStorage.getItem('discountDetails'));
        if (isBundleDiscount && this.currentSelectedPlans.length < 2) {
          return 0.00;
        }
        if (this.currentPlanType !== planType) {
          return 0.00
        }

        return !discountPrice ? 0.00 : discountPrice;
      }
      return 0.00
    },
    formattedDiscountPrice: function () {
      if (localStorage.getItem('discountDetails') != null) {
        const {discountPrice} = JSON.parse(localStorage.getItem("discountDetails"));
        return `${discountPrice}${this.currentSelectedPlan.currencySymbol}`
      }
      return ''
    },
    applyCoupon: function () {
      if (localStorage.getItem('discountDetails') != null) {
        const {isBundleDiscount, planType} = JSON.parse(localStorage.getItem('discountDetails'));
        if (planType !== this.currentPlanType) {
          return '';
        }
        if (isBundleDiscount && this.currentSelectedPlans.length < 2) {
          return '';
        }
      }
      return this.getCoupon;
    },
  },

  methods: {
    onPlanSelect: function (event, plan) {
      if (event) {
        this.$store.commit('setCurrentSelectedPlan', plan);
        this.$store.commit('setCurrentSelectedPlans', plan);
      } else {
        this.$store.commit('removeFromSelectedPlan', plan);
      }
      if (this.coupon || localStorage.getItem('coupon')) {
        this.setDiscountPromo(localStorage.getItem("discountDetails") !== null);
        if (this.discountPromoApplied) {
          const {isBundleDiscount, planType} = JSON.parse(localStorage.getItem('discountDetails'));
          if (isBundleDiscount && planType === this.currentPlanType && this.currentSelectedPlans.length < 2) {
            this.$parent.showFlashMessage(this.$t('only_two_products'));
            this.setDiscountPromo(false);
          }
        }
      }

    },
    setDiscountPromo: function (value) {
      this.discountPromoApplied = value
    },

    setAccessPromo:function(value){
      this.accessPromoApplied=value
    },

    setHasCoupon: function (value) {
      this.has_coupon = value
    },
    checkCoupon: function () {
      if (this.coupon !== '') {
        validateCoupon(this.coupon || localStorage.getItem('coupon'))
            .then((response) => {
              if (response.status === 201) {
                this.saveCoupon(response.data);
                const {couponType} = response.data
                if (couponType === "FREE_ACCESS_COUPON" || couponType === "UNIQUE_ACCESS_COUPON") {
                  this.removeCouponFromStorage();
                  return this.$router.push('/products');
                }

                if(couponType === "DISCOUNT_COUPON" || couponType === "UNIQUE_DISCOUNT_COUPON"){
                  const{discountDetails}=response.data;
                  const {isBundleDiscount,planType}=discountDetails
                  if(this.currentPlanType!==planType){
                    this.removeCoupon();
                    return this.$parent.showFlashMessage(this.$t('monthly_only'));
                  }
                  if(isBundleDiscount && this.currentSelectedPlans.length<2){
                    this.removeCoupon();
                    return this.$parent.showFlashMessage(this.$t('only_two_products'));
                  }
                  this.discountPromoApplied = true
                }
                if (couponType === "STANDARD_COUPON" || couponType === "UNIQUE_COUPON") {
                  this.accessPromoApplied = true
                }
                this.has_coupon = true;
              }
            }).catch((error) => {
          this.removeCoupon();
          if (error.response.data.code === 3) {
            return this.$parent.showFlashMessage(this.$t('error_message_coupon_invalid'));
          }


          if (error.response.data.code === 4 || error.response.data.code === 5 || error.response.data.code === 6) {
            var product = '';
            if (error.response.data.couponProduct === "KIDJO_TV") {
              product = "- Kidjo TV";
            } else if (error.response.data.couponProduct === "KIDJO_BOOKS") {
              product = "- Kidjo Stories";
            } else {
              product = "- Kidjo TV + Kidjo Stories + Kidjo Games";
            }

            return this.$parent.showFlashMessage(this.$t('error_message_subscribe_coupon_product_mismatch') + product);
          }

          if (error.response.data.code === 7) {
            return this.$parent.showFlashMessage(this.$t('error_coupon_invalid_for_this_coupon'));
          }
        });
      } else {
        this.coupon_error = true;
      }
    },

    saveCoupon: function (data) {
      this.coupon =  data.couponSerialNumber || localStorage.getItem('coupon');
      this.couponDurationCode = data.couponDurationCode;
      this.couponProduct = data.couponProduct;
    },

    removeCouponFromStorage: function () {
      localStorage.removeItem('coupon');
      localStorage.removeItem('couponDurationCode');
      localStorage.removeItem('couponProduct');
      localStorage.removeItem('discountDetails');
    },

    removeCoupon: function () {
      this.coupon = '';
      this.couponDurationCode = '';
      this.couponProduct = '';
      this.has_coupon = false
    },

    showPlanSelectForm: function () {
      this.active_step = 1;
      this.changeActiveTitle();
    },

    showPaymentOptions: function () {
      if (this.currentSelectedPlans.length > 0) {
        this.active_step = 2;
        this.changeActiveTitle();
        if (!this.paypal_instaled) {
          this.$parent.loading = true;
        }
      } else {
        this.$parent.showFlashMessage(this.$t('select_plan_please_select_plan'));
      }
      if(!this.discountPromoApplied && !this.accessPromoApplied){
        this.removeCoupon()
      }

    },

    showCardForm: function () {
      this.active_step = 3;
      this.changeActiveTitle();
      this.scrollDown();

      if (!this.braintree_instaled) {
        this.$parent.loading = true;
      }
    },

    changeActiveTitle: function () {
      for (var i = 0; i < this.titles.length; i++) {
        this.titles[i].status = false;

        if (this.titles[i].step === this.active_step) {
          this.titles[i].status = true;
        }
      }
    },

    onPlanTypeSelect: function (nameKey) {
      this.$store.commit('resetCurrentSelectedPlans');
      this.$store.commit('setCurrentPlanType', nameKey);
      this.$store.commit('setSelectedPlans', this.allPlans.userPlan.find(type => type.nameKey === nameKey).plans);
      this.$store.commit('setCurrentSelectedPlans', this.allPlans.userPlan.find(type => type.nameKey === nameKey).plans[0]);
      this.$store.commit('setCurrentSelectedPlan', this.allPlans.userPlan.find(type => type.nameKey === nameKey).plans[0]);
      if (this.coupon || localStorage.getItem('coupon')) {
        const discountDetails = localStorage.getItem('discountDetails');
        if (this.discountPromoApplied) {
          const {isBundleDiscount,planType} = JSON.parse(discountDetails);
          // Bundle Discount so more than one product will be selected by default
          if (planType !== nameKey) {
            this.$parent.showFlashMessage(this.$t('monthly_only'));
          }
          if(isBundleDiscount){
            this.$store.commit('setCurrentSelectedPlans', this.allPlans.userPlan.find(type => type.nameKey === nameKey).plans[1]);
            this.$store.commit('setCurrentSelectedPlan', this.allPlans.userPlan.find(type => type.nameKey === nameKey).plans[1]);
          }
        }
        this.setDiscountPromo(discountDetails!==null);
      }
    },
    payWithCreditCard: function () {
      this.cc_button_loading = true
      var self = this;
      this.hostedFieldsInstance.tokenize().then(payload => {
        self.braintreeNonce = payload.nonce;
        self.selected_device_data = self.device_data_braintree;
        self.sendNonce(payload.nonce, payload.details.cardHolderName);
      }).catch(error => {
        self.handleBraintreeErrors(error.code);
      });
    },

    buildProduct: function () {
      const {currentSelectedPlans} = this
      return 'kidjo_' + currentSelectedPlans
          .map(product => product.product)
          .join('_')
    },

    buildProductForSubscription: function () {
      const {subscriptionTypes, currentSelectedPlans} = this
      let product = 'kidjo_'
      if (subscriptionTypes.length > 0 && !this.isChangePlan) {
        product = product + this.subscriptionTypes.map(product => {
          return PRODUCT_TYPES[product]
        }).join('_');
        product = `${product}_` + currentSelectedPlans
            .map(product => product.product)
            .join('_')
        return product;
      } else {
        return this.buildProduct();
      }
    },

    sendNonce: function (nonce, name) {
      const {currentSelectedPlan, currentSelectedPlans, subscriptionTypes} = this
      let planId = currentSelectedPlan.iap;
      let addOns = null;
      let mainProduct = this.buildProductForSubscription()
      if (this.isChangePlan || subscriptionTypes.length === 0) {
        if (currentSelectedPlans.length === 2) {
          addOns = currentSelectedPlan.addOns[0]
        } else if (currentSelectedPlans.length === 3) {
          addOns = currentSelectedPlan.addOns[1]
        }
      } else {
        if (subscriptionTypes.length === 1 && currentSelectedPlans.length === 1) {
          addOns = currentSelectedPlan.addOns[0]
        } else if (subscriptionTypes.length === 1 && currentSelectedPlans.length === 2) {
          addOns = currentSelectedPlan.addOns[1]
        } else if (subscriptionTypes.length === 2 && currentSelectedPlans.length === 1) {
          addOns = currentSelectedPlan.addOns[1]
        }
      }
      const plan = {
        planId: planId,
        product: mainProduct,
        addOns: addOns
      };
      const request = {
        paymentNonce: nonce,
        plans: plan,
        isBundle: (this.subscriptions !== null || this.currentSelectedPlans.length > 1),
        coupon: this.applyCoupon,
        deviceData: this.selected_device_data,
        billingName: name
      };
      this.$store.dispatch('subscribe', request).then((response) => {
        if (response.status === 200) {
          this.removeCouponFromStorage();
          this.$store.dispatch('checkUserSubscriptions');

          // Check if the we have to go to tv app folder
          if (localStorage.getItem('tvAppFolderId') && (this.subscriptionFor === 'tv' || this.subscriptionFor === 'combo')) {
            var id = localStorage.getItem('tvAppFolderId');

            // remove the id from local Storage
            localStorage.removeItem('tvAppFolderId');

            // go to tv app folder
            AuthService.generateRedirectKey()
                .then((response) => {
                  if (response.status === 200) {
                    window.location.replace(process.env.VUE_APP_WEB_APP_URL + 'folder/' + id + '?key=' + response.data.redirectKey);
                  }
                }).catch((error) => {
              this.$parent.showFlashMessage(this.$t('error_generic'))
            });
          } else {
            if (this.checkStorageForSwisscomeCampaign()) {
              localStorage.removeItem('swissComeCampaign');
            }
          }
          this.cc_button_loading = false;
          if(localStorage.getItem('app')) {
            var appName = localStorage.getItem('app')
            localStorage.removeItem('app') 
            AuthService.generateRedirectKey()
            .then((response) => {
              if (response.status === 200) {
                this.$parent.loading = false;
                window.location.replace(this.routeBackToWebApp(appName) + '?key='+response.data.redirectKey);
              }
            }).catch((error) => {
              this.$parent.loading = false;
            });
        } else {
          this.$router.push({name: 'home'});
        }

        }
      }).catch((error) => {
        this.cc_button_loading = false;
        this.$parent.loading = false;
        if (error.response.status === 400) {
          this.$parent.showFlashMessage(error.response.data.message);
        } else {
          this.$parent.showFlashMessage(this.$t('error_generic'))
        }
      });

    },
    routeBackToWebApp: function(appName) {
				const actionMap = {
					['games']: process.env.VUE_APP_GAMES_APP_URL,
					['stories']: process.env.VUE_APP_STORIES_APP_URL,
					['tv']: process.env.VUE_APP_WEB_APP_URL,
					['kidjo-world']: process.env.VUE_APP_WEB_APP_LINK
				};
				return actionMap[appName];		
			},
    handleBraintreeErrors: function (code) {
      if (code === "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR") {
        this.$parent.showFlashMessage(this.$t('braintree_error_message_network_error'));
      }
      if (code === "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE") {
        this.$parent.showFlashMessage(this.$t('braintree_error_message_duplicated_payment_error'));
      }

      if (code === "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED") {
        this.$parent.showFlashMessage(this.$t('braintree_error_message_cvv_verification_failed'));
      }

      if (code === "HOSTED_FIELDS_FAILED_TOKENIZATION") {
        this.$parent.showFlashMessage(this.$t('braintree_error_message_faled_tokenization'));
      }

      if (code === "HOSTED_FIELDS_FIELDS_EMPTY") {
        this.$parent.showFlashMessage(this.$t('braintree_error_message_empty_fields'));
      }

      if (code === "HOSTED_FIELDS_FIELDS_INVALID") {
        this.$parent.showFlashMessage(this.$t('braintree_error_message_invalid_fields'));
      }
      this.cc_button_loading = false;
    },

    generateClientToken: function () {
      SubscriptionService.generateToken()
          .then((response) => {
            if (response.status === 200) {
              this.client_token = response.data;
              this.initPaypal();
              this.initBraintree();
            }
          }).catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.code === 1) {
            this.$parent.showFlashMessage(this.$t('braintree_error_message_problem_generate_client_token'));
          }
        }
      });
    },

    initBraintree: function () {
      var self = this;
      // by braintree sdk client method
      client.create({
        authorization: self.client_token
      }, function (err, clientInstance) {
        if (err) {
          console.error(err);
          return;
        }
        dataCollector.create({
          client: clientInstance,
          kount: true
        }, function (err, dataCollectorInstance) {
          if (err) {
            return;
          }
          self.device_data_braintree = dataCollectorInstance.deviceData;
        });
        hostedFields.create({
          client: clientInstance,
          styles: {
            'color': '#a7948d',
            'font-family': 'BreeRegular',
            'font-weight': 600,
            'font-size': '18px',
            'opacity': 1,
            'font-variant-numeric': 'tabular-nums'
          },
          fields: {
            cardholderName: {
              selector: '#cardHolderName',
              placeholder: 'Card Holder Name'
            },
            number: {
              selector: '#creditCardNumber',
              placeholder: 'Card number'
            },
            cvv: {
              selector: '#cvv',
              placeholder: 'Cryptogram (CVV)'
            },
            expirationDate: {
              selector: '#expireDate',
              placeholder: 'Expiration date (MM / YY)'
            }
          }
        }, function (err, hostedFieldsInstance) {
          self.hostedFieldsInstance = hostedFieldsInstance;
          self.braintree_instaled = true;
          self.$parent.loading = false;
        });
      });
    },

    initPaypal: function () {
      var self = this;
      client.create({
        authorization: self.client_token
      }, function (clientErr, clientInstance) {
        if (clientErr) {
          console.error('Error creating client:', clientErr);
          return;
        }

        paypalCheckout.create({
          client: clientInstance
        }, function (paypalCheckoutErr, paypalCheckoutInstance) {
          dataCollector.create({
            client: clientInstance,
            paypal: true
          }, function (err, dataCollectorInstance) {
            if (err) {
              return;
            }
            var myDeviceData = dataCollectorInstance.deviceData;
            self.device_data_paypal = myDeviceData;
          });
          if (paypalCheckoutErr) {
            console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
            return;
          }
          paypal.Button.render({
            env: process.env.VUE_APP_BRAINTREE_ENV, // 'production' or 'sandbox'
            style: {
              label: 'paypal',
              size: 'responsive',
              shape: 'rect',
              color: 'white',
              tagline: false
            },
            payment: function () {
              return paypalCheckoutInstance.createPayment({
                flow: 'vault',
                currency: 'CHF'
              });
            },
            onAuthorize: function (data, actions) {
              return paypalCheckoutInstance.tokenizePayment(data)
                  .then(function (payload) {
                    self.selected_device_data = self.device_data_paypal;
                    self.paypalNonce = payload.nonce;
                    self.$parent.loading = true;
                    self.sendNonce(payload.nonce, '')
                  });
            },
            onCancel: function (data) {
              console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
            },
            onError: function (err) {
              this.$parent.showFlashMessage(this.$t('error_generic'));
            }
          }, '#paypalButton').then(function () {
            self.paypal_instaled = true;
            self.$parent.loading = false;
          });

        });

      });
    },

    scrollDown: function () {
      if (window.innerWidth <= 768) {
        setTimeout(function () {
          window.scrollTo(0, 120);
        }, 1000)
      }
    },

    checkStorageForCoupon: function () {
      const coupon = localStorage.getItem('coupon');
      return coupon != null;
    },

    checkStorageForSwisscomeCampaign: function () {
      var coupon = localStorage.getItem('swissComeCampaign');

      if (coupon == null) {
        return false;
      }

      return true;
    },
  },

  mounted: function () {
    if (localStorage.getItem('storeId') !== 'switch' && (this.subscriptionTypes.includes('kidjo_tv_books_games')
        && this.$route.path !== '/changePlan'
        && this.$route.path !== '/resubscribe'
        || (this.subscriptionTypes.includes('kidjo_tv')
            && this.subscriptionTypes.includes('kidjo_books')
            && this.subscriptionTypes.includes('kidjo_games')))) {
      return this.$router.push({name: 'home'}).catch(() => {
      });
    } else if (this.$parent.tvPlatform) {
      localStorage.removeItem('storeId')
      this.$store.dispatch('logout');
      this.$parent.showLoginQR = true;
      this.$parent.showFlashMessage(this.$t('tv_login_header_subscription_expired'));
      return this.$router.push({name: 'login'}).catch(() => {
      });
    } else if (localStorage.getItem('storeId') === 'switch') {
      localStorage.removeItem('storeId')
    }

    /*
      - Check if coupon is saved in storage
      - If we have coupon in storage, save the coupon in local varibles and remove it from storage
    */
    if (this.checkStorageForCoupon()) {
      this.coupon = localStorage.getItem('coupon');
      this.couponDurationCode = localStorage.getItem('couponDurationCode');
      this.couponProduct = localStorage.getItem('couponProduct');
      const discountDetails = localStorage.getItem('discountDetails');
      this.setDiscountPromo(discountDetails !== null);
      this.setAccessPromo(discountDetails === null);
      this.has_coupon = true;
    }

    this.$store.commit('resetCurrentSelectedPlans');
    this.$store.commit('removeCurrentSelectedPlan');
    this.$store.dispatch('getUserEligiblePlans',
        {
          showAll: this.$route.path === '/changePlan',
          currentSubscription: this.subscriptionTypes,
          countryCode: localStorage.getItem('cc')
        });

    // Show plan title based on device width
    if (window.innerWidth <= 768) {
      this.featureDesktopTitle = false;
    }

    this.generateClientToken();
    this.scrollDown();

    // Swisscome free campaign
    if (this.checkStorageForSwisscomeCampaign()) {
      this.coupon = "YAJKM0";
      this.couponDurationCode = "P2M";
    }

    // Change Background color and color of footer and header
    if (this.$parent.appStyle === 'profile') {
      this.$parent.changeAppStyle('subscribe');
    }
  }
}
</script>
<style lang="sass">
.hide-over-button
  opacity: 0
  margin-top: -74px

  @media (max-width: 768px)
    margin-top: -60px

  @media (max-width: 768px)
    margin-top: -55px

.subscription-card-graphic
  margin-top: -80px

  svg
    width: 100px
    height: 100px

.subscription-card-details
  margin-top: 25px
  display: flex
  flex: 1
  flex-direction: column
  justify-content: space-between
  align-items: center

.text-wrapper
  margin-bottom: 12px

.content
  margin-bottom: 0px

.disabled
  opacity: 0.5

</style>
