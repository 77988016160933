<template>
	<div class="one-row">
		<div class="box unsubscribe-box" v-if="!showSuccessBlock">
			<span class="box-title">{{ $t('are_you_sure_page_box_title') }}</span>
			<span class="box-subtitle">{{ $t('are_you_sure_page_box_subtitle') }}</span>
			<div class="unsubcribe-container">
				<span class="unsubscribe-text">
					{{ $t('are_you_sure_page_box_text') }} {{ plan.endDate }}
				</span>
				<button class="green-button animated-button" @click="backToProfile" :disabled="loading">
					<span class="loading" v-if="loading"></span>
					<span v-else>{{ $t('are_you_sure_page_its_mistake_button') }}</span>
				</button>
				<a @click="!loading ? handleUnsubscribe() : ''" class="unsubscribe-btn">{{ $t('are_you_sure_page_cancel_subscription_link') }}</a>
			</div>
		</div>
		<div class="box unsubscribe-box" v-if="showSuccessBlock">
			<span class="box-title">{{ $t('are_you_sure_page_success_unsubscribe_box_title') }}</span>
			<span class="box-subtitle">{{ $t('are_you_sure_page_success_unsubscribe_box_subtitle') }}</span>
			<div class="unsubcribe-container">
				<button class="green-button animated-button" @click="backToProfile" :disabled="loading">
					<span class="loading" v-if="loading"></span>
					<span v-else>{{ $t('are_you_sure_page_back_to_profile_page') }}</span>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
	import SubscriptionService from '../services/subscription.service';

	export default {
		data() {
			return {
				showSuccessBlock: false,
				loading: false,
				type: 'kidjo',
				plan: ''
			}
		},

		methods: {
			backToProfile: async function() {
				const hasPremium = await this.$store.dispatch('checkUserSubscriptions');
				this.loading = true;
				if (localStorage.getItem('storeId') === 'switch') {
					this.$router.push({ path: '/', query: { from: 'unsubscribe' }}).catch(()=>{});
				} else {
					this.loading = false;
					if (hasPremium != true) {
						this.subscription_has_error = true;
						if (hasPremium.status == 404) {
							this.$parent.showFlashMessage(this.$t('profile_page_error_finding_subscription'));
						}

						if (hasPremium.status == 400) {
							this.$parent.showFlashMessage(this.$t('error_message_something_went_wrong'));
						}
					} else {
						this.$router.push('/').catch(()=>{});
					} 
				}
			},
			unsubscribeUser: function() {
				const params = new URLSearchParams(window.location.search);
				var id = this.$route.params.id ? this.$route.params.id : params.get('id')
				SubscriptionService.unsubscribe(this.type, id)
					.then((response) => {
						if (response.status == 200) {
							this.showSuccessBlock = true;
							this.loading = false;
						}
					}).catch((error) => {
						this.handleError(error.response);
						this.loading = false;
					});

			},
			handleUnsubscribe: function() {
				this.loading = true;
				const params = new URLSearchParams(window.location.search);
				if (params.has('id') && params.has('type') && params.get('type') === 'switch') {
					SubscriptionService.notifyUnSubscribeSwitchUser(params.get('id')).then(() => {
						this.showSuccessBlock = true;
						this.loading = false;
						window.location.replace('https://ufonekidjo.com');
					}).catch((error) => {
						this.handleError(error.response);
						this.loading = false;
					})
				} else {
					this.unsubscribeUser()
				}
			},
			handleError: function(response) {
				if (response.status == 404) {
					return this.$parent.showFlashMessage(this.$t('error_message_unsubscibe_subscription_not_found'));
				}
				if (response.status == 400) {
					if (response.data.code == 3) {
						this.$parent.showFlashMessage(this.$t('error_message_unsubscibe_subscription_already_canceled'));
					}
					this.$parent.showFlashMessage(this.$t('error_message_something_went_wrong'));	
				}
			},
		},

		mounted: function() {
			var subscriptions = this.$store.getters.getSubscriptionsList;
			for (var i = 0; i < subscriptions.length; i++) {
				if (subscriptions[i].id == this.$route.params.id) {
					this.plan = subscriptions[i];
				}
			}
			const params = new URLSearchParams(window.location.search);
			if (params.has('type') && params.get('type') == 'swisscom') {
				this.type = 'swisscom';
			}
		}
	}
</script>