<template>
  <div class="splash-screen">
    <transition
        name="fade-animate"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
    >
      <div class="splash-screen-cover">
        <img src="../assets/images/logo.svg" alt="Kidjo"/>
      </div>
    </transition>
  </div>
</template>
<script>

export default {
  props: {
    autoHide: {
      type: Boolean,
      value: false
    }
  },

  data() {
    return {
      displayed: true,
    }
  },

  methods: {
    hide: function () {
      this.displayed = false;
    }
  },

  mounted: function () {
    if (this.autoHide) {
      setTimeout(() => {
        this.hide();
      }, 2000);
    }
  }
}
</script>
<style scoped lang="sass">
.splash-screen
  pointer-events: none
  z-index: 100

  .splash-screen-cover
    display: block
    width: 100%
    height: 102%
    background: url('../assets/backgrounds/blue-background.svg')
    background-size: cover
    background-position: center
    position: fixed
    z-index: 101
    background-color: #29B6F6
    margin-top: -20px

  img
    width: 350px
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    margin: auto
    z-index: 101

    @media (max-width: 375px)
      width: 200px

    @media (max-width: 375px)
      width: 80%

    animation: pulse_button 1s infinite

    @keyframes pulse_button
      0%
        transform: scale(0.85)

      70%
        transform: scale(1)

      100%
        transform: scale(0.85)
</style>
