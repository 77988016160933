export default () => {
      const promo_plans = [
            {
                  nameKey: 'monthly_plan',
                  durationCode: "P1M",
                  plans: [
                        {
                              planId: "swiss_kidjo_server_plan_discount_4_monthly_7f",
                              old: "kidjo_server_plan_discount_4_monthly_7f",
                              price: "3.90 CHF",
                              product: "Kidjo TV Promo", 
                              status: false, 
                              for: 'tv', 
                              planType: 'kidjo_tv',
                        },
                        {
                              planId: "swiss_kidjo_books_server_plan_discount_7_monthly_7f",
                              old: "kidjo_books_server_plan_discount_7_monthly_7f", 
                              price: "3.90 CHF",
                              product: "Kidjo Stories Promo", 
                              status: false, 
                              for: 'books', 
                              planType: 'kidjo_books',
                        },
                  ]
            },
            {
                  nameKey: 'annual_plan',
                  durationCode: "P1Y",
                  plans: [
                        {
                              planId: "swiss_kidjo_server_plan_discount_10_12months_7f",
                              old: "kidjo_server_plan_discount_10_12months_7f", 
                              price: "29.90 CHF",
                              product: "Kidjo TV Promo", 
                              status: false, 
                              for: 'tv', 
                              planType: 'kidjo_tv',
                        },
                        {
                              planId: "swiss_kidjo_books_server_plan_discount_9_12months_7f",
                              old: "kidjo_books_server_plan_discount_9_12months_7f",
                              price: "29.90 CHF",
                              product: "Kidjo Stories Promo", 
                              status: false, 
                              for: 'books', 
                              planType: 'kidjo_books',
                        }
                  ]
            }
      ];

      return promo_plans;
}