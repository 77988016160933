import axios from "axios";
import {GAMES_PROMO_CARDS_URL, STORIES_PROMO_CARDS_URL, TV_PROMO_CARDS_URL} from "../utils/constant";

const GAMES_BASE_URL = process.env.VUE_APP_GAMES_API_BASE_URL;
const STORIES_BASE_URL = process.env.VUE_APP_STORIES_API_BASE_URL;
const TV_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const getPromoCards = (product,countryCode="fr",language="en",limit="20") => {
    let url = "";
    let params=null;
    if (product === "stories") {
        url = STORIES_BASE_URL + STORIES_PROMO_CARDS_URL;
    } else if (product === "games") {
        url = GAMES_BASE_URL + GAMES_PROMO_CARDS_URL;
    } else {
        url = TV_BASE_URL + TV_PROMO_CARDS_URL;
        params={
            countryCode,
            language,
            count:limit,
        }
    }
    const config = {
        method: "get",
        headers: {
            'Content-Type': 'application/json; Charset=UTF-8',
        },
        url,
        params
    };
    return axios(config)
}
