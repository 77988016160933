import axios from 'axios';
import { isObj, isNonEmptyStr } from '@/utils';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

class LocationService {

	getLocationContryCode() {
		return axios.get(baseUrl + '/country')
			.then((response) => {
				const { data } = response;
				// if call not failed but not received data
				return isObj(data) && isNonEmptyStr(data.geoplugin_countryCode) ? data.geoplugin_countryCode : 'FR';
			}).catch(() => 'FR');
	}
}

export default new LocationService;