import Vue from 'vue';
import App from '@/App';

import '@/components/index';

import '@/plugins/vuelidate';

import store from '@/store/index';
import router from '@/router/index';
import i18n from '@/i18n';

import './assets/styles/tailwind.css';

Vue.config.productionTip = false;

new Vue({
	store,
	router,
	i18n,
	render: h => h(App),
}).$mount('#app')
