export default () => {
	const plans = [
		{
			nameKey: 'monthly_plan',
			durationCode: 'P1M',
			status: true,
			plans: [
				{
					planId: "swiss_kidjo_server_plan_5_monthly_7f",
					old: "kidjo_server_plan_2_monthly_7f",
					price: "6.90 CHF",
					product: "Kidjo TV", 
					status: true, 
					for: 'tv',
					promoTitle: "subscribe_page_product_tv_title" 
				},
				{
					planId: "swiss_kidjo_books_server_plan_6_monthly_7f",
					old: "kidjo_books_server_plan_6_monthly_7f",
					price: "6.90 CHF",
					product: "Kidjo Stories", 
					status: false, 
					for: 'books',
					promoTitle: "subscribe_page_product_books_title" 
				},
				{
					planId: "N/A",
					old: "N/A",
					price: "N/A",
					product: "Kidjo Games", 
					status: false, 
					for: 'games',
					promoTitle: "N/A",
				}
			],
			comboPlans: {
				'tv_books': {
					for: 'combo',
					price: '10.90 CHF', 
					planId: 'swiss_kidjo_tv_and_books_server_plan_12_monthly_7f' 
				},
			} 
		},
		{
			nameKey: 'annual_plan',
			durationCode: 'P1Y',
			status: false,
			plans: [
				{
					planId: "swiss_kidjo_server_plan_3_12months_7f",
					old: "kidjo_server_plan_3_12months_7f",
					price: "49.90 CHF",
					product: "Kidjo TV", 
					status: false, 
					for: 'tv',
					promoTitle: "subscribe_page_product_tv_title" 
				},
				{
					planId: "swiss_kidjo_books_server_plan_8_12months_7f",
					old: "kidjo_books_server_plan_8_12months_7f", 
					price: "49.90 CHF",
					product: "Kidjo Stories", 
					status: false, 
					for: 'books',
					promoTitle: "subscribe_page_product_books_title" 
				},
				{
					planId: "N/A",
					old: "N/A",
					price: "N/A",
					product: "Kidjo Games", 
					status: false, 
					for: 'games',
					promoTitle: "N/A",
				}
			],
			comboPlans: {
				'tv_books': {
					for: 'combo',
					price: '79.90 CHF', 
					planId: "swiss_kidjo_tv_and_books_server_plan_11_12months_7f"
				}
			}
		}
	];

	return plans;
}