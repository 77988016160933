<template>
  <svg
    width="74"
    height="69"
    viewBox="0 0 74 69"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-8.8%"
        y="-8.9%"
        width="117.5%"
        height="118.3%"
        filterUnits="objectBoundingBox"
        id="066j83tx0a"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#066j83tx0a)"
      transform="translate(4.938 4.281)"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M37.42 58.801 4.488 44.716a7.342 7.342 0 0 1-3.959-4.042 7.268 7.268 0 0 1 .08-5.637l8.474-19.24c-.7-.653-.885-1.414-.555-2.282.839-2.21 1.466-2.929 2.215-5.087a32.456 32.456 0 0 0 1.345-5.121A3.577 3.577 0 0 1 15.971.356L60.2 4.815a4.318 4.318 0 0 1 2.907 1.62 4.256 4.256 0 0 1 .892 3.189l-.474 3.794a4.273 4.273 0 0 1-2.04 3.139 2.13 2.13 0 0 1-.138.084 4.336 4.336 0 0 1-2.04.553l-.017.001h-.072c-.169 0-.34-.01-.508-.029l-26.35-3.084 9.98 4.269.007.002 5.694 2.435.015.007 4.453 1.905.056.023L57.888 25l.032.016c.09.042.177.086.26.132l.053.03.025.016a3.933 3.933 0 0 1 1.657 1.921c.39.972.374 2.036-.043 2.997l-1.542 3.552a3.936 3.936 0 0 1-2.917 2.303L47.17 54.952a7.422 7.422 0 0 1-6.822 4.45 7.404 7.404 0 0 1-2.929-.6z"
        stroke="#FFF"
        stroke-width="3.213"
        fill="#FFF"
      />
      <path
        d="m54.185 33.322-42.643-18.24a1.31 1.31 0 0 1-.691-1.724l1.542-3.553a1.326 1.326 0 0 1 1.738-.686l42.643 18.239c.67.287.98 1.059.691 1.725l-1.542 3.553a1.326 1.326 0 0 1-1.738.686"
        fill="#6D7A8A"
      />
      <path
        fill="#FFF"
        d="M25.696 14.066 17.59 17.67l-5.523-2.363 8.106-3.602zM36.155 18.54l-8.108 3.603-5.522-2.364 8.107-3.603zM46.979 23.168 38.87 26.77l-5.522-2.362 8.106-3.604h.002zM57.012 27.49l-8.071 3.59-5.524-2.363 8.108-3.605h.002l5.247 2.246c.086.037.165.08.238.133"
      />
      <path
        d="m53.782 33.15-9.019 20.772c-1.048 2.416-3.87 3.531-6.306 2.49L5.525 42.326a4.766 4.766 0 0 1-2.745-3.173 4.706 4.706 0 0 1 .236-3.085l8.139-18.745.772-1.779.108-.25 33.258 14.224 8.489 3.631z"
        fill="#FE5722"
      />
      <path
        d="M40.335 56.803a4.813 4.813 0 0 1-1.878-.39L5.525 42.325c-2.434-1.041-3.557-3.843-2.508-6.258l9.018-20.774 1.306.56-8.876 20.443c-1.049 2.416.073 5.219 2.509 6.26l32.932 14.085c.142.06.285.114.429.16"
        fill-opacity=".5"
        fill="#FFF"
      />
      <path
        d="m12.288 38.825-2.244-.96a1.593 1.593 0 0 1-.84-2.098l3.317-7.642a1.614 1.614 0 0 1 2.115-.835l2.243.96a1.593 1.593 0 0 1 .84 2.098l-3.317 7.642a1.614 1.614 0 0 1-2.114.835"
        fill-opacity=".5"
        fill="#FFF"
      />
      <path
        d="m53.782 33.15-9.019 20.772c-1.048 2.416-3.87 3.531-6.306 2.49L5.525 42.326a4.766 4.766 0 0 1-2.745-3.173l12.827 5.381c10.734 3.318 20.895 3.342 28.715-4.666 2.315-2.37 1.578-6.306-1.432-7.713L11.155 17.323l.772-1.779 33.366 13.974 8.489 3.631z"
        fill-opacity=".4"
        fill="#6D4C43"
      />
      <path
        d="M28.086 31.177c0 3.641-2.973 6.592-6.641 6.592s-6.641-2.951-6.641-6.592c0-3.64 2.973-6.592 6.64-6.592 3.669 0 6.642 2.952 6.642 6.592"
        fill="#FFF"
      />
      <path
        d="M28.086 31.177c0 3.641-2.973 6.592-6.641 6.592s-6.641-2.951-6.641-6.592c0-3.64 2.973-6.592 6.64-6.592 3.669 0 6.642 2.952 6.642 6.592z"
        stroke="#6D4C43"
        stroke-width="1.105"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.922 30.706c0 1.909-1.56 3.457-3.483 3.457s-3.482-1.548-3.482-3.457c0-1.909 1.56-3.455 3.482-3.455.298 0 .587.037.864.107-.63.236-1.078.84-1.078 1.549 0 .915.747 1.655 1.668 1.655.842 0 1.538-.618 1.651-1.422.242.47.378 1.003.378 1.566M29.609 36.562c.679-.71 1.506-1.265 2.4-1.707a9.102 9.102 0 0 1 2.907-.873 8.54 8.54 0 0 1 1.558-.045 8.185 8.185 0 0 1 1.55.264 7.71 7.71 0 0 1 2.765 1.408c.327.263.378.74.112 1.065a.767.767 0 0 1-.957.19l-.018-.01c-.775-.42-1.552-.782-2.371-1.025a9.922 9.922 0 0 0-1.251-.277 10.038 10.038 0 0 0-1.295-.1c-.876-.008-1.77.112-2.662.317-.89.214-1.783.514-2.654.903l-.005.001a.07.07 0 0 1-.093-.034.07.07 0 0 1 .014-.077M33.71 41.845a4.215 4.215 0 0 1-1.088 1.718c-.497.487-1.107.846-1.74 1.09a7.24 7.24 0 0 1-1.95.44c-.656.06-1.308.044-1.948-.01a15.112 15.112 0 0 1-3.709-.846 14.649 14.649 0 0 1-1.728-.755 9.675 9.675 0 0 1-1.584-1.006.069.069 0 0 1-.01-.097.07.07 0 0 1 .083-.02c1.119.508 2.286.86 3.464 1.124a18.56 18.56 0 0 0 3.55.459c.59.017 1.175-.016 1.744-.089a8.078 8.078 0 0 0 1.643-.378c.52-.183 1-.434 1.428-.759.425-.328.783-.74 1.09-1.215l.006-.008a.42.42 0 0 1 .577-.125.414.414 0 0 1 .172.477"
        fill="#6D4C43"
      />
      <path
        d="M31.87 41.436c.234-.19.513-.26.783-.28.273-.015.547.025.8.122.253.096.491.237.683.43.195.188.347.424.417.71-.3-.03-.531-.098-.758-.158-.22-.068-.43-.131-.633-.21a10.304 10.304 0 0 1-.614-.256 7.103 7.103 0 0 1-.678-.358"
        fill="#6D4C43"
      />
      <path
        d="M38.458 56.412 5.525 42.326c-2.434-1.04-3.557-3.843-2.508-6.26l9.018-20.772 41.747 17.855-9.018 20.773c-1.049 2.416-3.872 3.531-6.306 2.49z"
        stroke="#6D4C43"
        stroke-width="1.105"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m57.465 29.083-.36.83-42.61-18.225a1.325 1.325 0 0 0-1.738.685l-1.183 2.723-.032-.013a1.308 1.308 0 0 1-.69-1.725l1.541-3.553a1.325 1.325 0 0 1 1.738-.685l42.643 18.238c.67.287.98 1.059.69 1.725"
        fill-opacity=".5"
        fill="#FFF"
      />
      <path
        d="m54.185 33.322-42.643-18.24a1.31 1.31 0 0 1-.691-1.724l1.542-3.553a1.326 1.326 0 0 1 1.738-.686l42.643 18.239c.67.287.98 1.059.691 1.725l-1.542 3.553a1.326 1.326 0 0 1-1.738.686z"
        stroke="#6D4C43"
        stroke-width="1.105"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m56.217 31.957-.295.678a1.327 1.327 0 0 1-1.738.687l-42.642-18.24a1.308 1.308 0 0 1-.69-1.724l1.541-3.553c.089-.203.223-.374.386-.503l-1.247 2.873c-.289.666.02 1.438.69 1.725l42.645 18.24c.464.199.98.112 1.35-.183"
        fill="#6D4C43"
      />
      <path
        d="M59.015 14.582 12.688 9.159l.578-4.624a2.607 2.607 0 0 1 2.897-2.255L59.892 7.4c.947.11 1.622.965 1.504 1.904l-.474 3.794a1.716 1.716 0 0 1-1.907 1.485"
        fill="#6D7A8A"
      />
      <path
        stroke="#6D4C43"
        stroke-width="1.105"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M25.696 14.066 17.59 17.67l-5.523-2.363 8.106-3.602zM36.155 18.54l-8.108 3.603-5.522-2.364 8.107-3.603z"
      />
      <path
        d="m61.405 8.984-43.29-5.068a2.607 2.607 0 0 0-2.897 2.255l-.405 3.237-2.125-.249.578-4.624a2.607 2.607 0 0 1 2.897-2.255L59.892 7.4a1.712 1.712 0 0 1 1.513 1.585"
        fill-opacity=".5"
        fill="#FFF"
      />
      <path
        stroke="#6D4C43"
        stroke-width="1.105"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M46.979 23.168 38.87 26.77l-5.522-2.362 8.106-3.604h.002z"
      />
      <path
        d="m26.673 10.796-5.724-.67-5.354-7.851a2.62 2.62 0 0 1 .568.005l5.11.599 5.4 7.917zM38.009 12.123l-5.725-.67-5.4-7.917 5.725.67zM48.841 13.39l-5.725-.67-5.4-7.917 5.725.67zM60.058 14.375c-.306.17-.667.25-1.042.206l-4.44-.519h-.001l-5.4-7.917 5.726.67 5.157 7.56z"
        fill="#FFF"
      />
      <path
        d="m57.012 27.49-8.071 3.59-5.524-2.363 8.108-3.605h.002l5.247 2.246c.086.037.165.08.238.133zM59.015 14.582 12.688 9.159l.578-4.624a2.607 2.607 0 0 1 2.897-2.255L59.892 7.4c.947.11 1.622.965 1.504 1.904l-.474 3.794a1.716 1.716 0 0 1-1.907 1.485z"
        stroke="#6D4C43"
        stroke-width="1.105"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m26.673 10.796-5.724-.67-5.354-7.851a2.62 2.62 0 0 1 .568.005l5.11.599 5.4 7.917zM38.009 12.123l-5.725-.67-5.4-7.917 5.725.67zM48.841 13.39l-5.725-.67-5.4-7.917 5.725.67zM60.058 14.375c-.306.17-.667.25-1.042.206l-4.44-.519h-.001l-5.4-7.917 5.726.67 5.157 7.56z"
        stroke="#6D4C43"
        stroke-width="1.105"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.573 7.127S50.892 11.84 33.48 9.733L12.688 7.127V9.16l46.226 5.435s1.789.26 2.07-1.983c.28-2.243.426-3.6.426-3.6s.037-1.315-1.518-1.612c-1.555-.298-2.319-.272-2.319-.272"
        fill-opacity=".4"
        fill="#6D4C43"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "TvAppDecoration",
};
</script>
