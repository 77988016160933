import axios from 'axios';

const baseUrl = process.env.VUE_APP_API_BASE_URL;
const BASE_URL = process.env.VUE_APP_API_ACCOUNT_API_BASE_URL;
const API_VERSION = process.env.VUE_APP_API_ACCOUNT_VERSION_V5;
const userToken = 'kidj_ut';

class AuthService {

	generateRedirectKey() {
		var config = {
			headers: {'Authorization': "Bearer " + localStorage.getItem(userToken)}
		};
		return axios.get(baseUrl + '/users/redirect/generate-key', config);
	}


	verifyKey(key) {
		return axios.get(baseUrl + '/users/redirect/verify-key?redirectKey=' + key)
	}

	register(data) {
		var config = {
			method: 'post',
			url:  BASE_URL + API_VERSION + '/accounts',
			headers: {
			'Content-Type': 'application/json; Charset=UTF-8', 
			},
			data : data
		};

		return axios(config);
	}

	login(data) {
		var config = {
			method: 'post',
			url: baseUrl + '/accounts/login',
			headers: { 
			'Content-Type': 'application/json; Charset=UTF-8', 
			},
			data : data
		};
		
		return axios(config);
	}

	fogottenPassword(email) {
		return axios.get(baseUrl + '/users/forgotten-pass?email=' + email);
	}

	changePasswordPartners(email) {
		return axios.get(BASE_URL + API_VERSION  + '/users/forgotten-pass?email=' + email);
	}

	changePassword(oldPassword, newPassword) {
		var config = {
				method: 'post',
				url:  baseUrl + '/users/change-pass',
				headers: {'Authorization': "Bearer " + localStorage.getItem('kidj_ut')},
				data: {'oldPass':oldPassword,'newPass':newPassword}
			};

		return axios(config);
	}

	changeProfileInfo(type, value) {
		var url = "";
		var data = {};
		
		if (type == 'email') {
			url = baseUrl + "/users/change-email";
			data.email = value;
		} else {
			url = baseUrl + "/users/change-name";
			data.name = value;
		}

		var config = {
				method: 'post',
				url:  url,
				headers: {'Authorization': "Bearer " + localStorage.getItem('kidj_ut')},
				data: data
			};

		return axios(config);
	}
}

export default new AuthService;