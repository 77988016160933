import AuthService from '../../services/auth.service';
import {userSubscriptionsInfo} from "../../services/subscriptions.v2.services";

const storage_token_name = 'kidj_ut';
const user_token = localStorage.getItem(storage_token_name);

const state = {
	token: (user_token ? user_token : false),
	hasPremium: false,
	email: '',
	name: '',
	subscriptionsList: [],
	isEmailConfirmed: false,
	isEmailFake: false,
	partner: null,
	showEmailModal: true,
	// isPromoAccepted: 'lagacy Promo'
};

const getters = {
	getToken(state) {
		return state.token;
	},

	getPremium(state) {
		return state.hasPremium;
	},
  
	getAccountDetails(state) {
		return {
			name: state.name,
			email: state.email,
			subscriptions: state.subscriptionsList,
		}
	},
	getCurrentAccountEmail(state) {
		return state.email
	},
	getEmailStatus(state) {
		return state.isEmailConfirmed;
	},
	getFakeEmailStatus(state) {
		return state.isEmailFake;
	},

	getPartnerDetails(state) {
		return state.partner;
	},
    getshowEmailModal(state) {
		return state.showEmailModal;
	},
	getSubscriptionsList(state) {
		var list = [];

		for (var i = 0; i < state.subscriptionsList.length; i++) {
			list.push({
				durationCode: state.subscriptionsList[i].durationCode,
				subscriptionType: state.subscriptionsList[i].subscriptionType,
				paymentPlanPrice: state.subscriptionsList[i].paymentPlanPrice,
				isRenewing: state.subscriptionsList[i].isRenewing,
				paymentPlanId: state.subscriptionsList[i].paymentPlanId,
				id: state.subscriptionsList[i].id,
				endDate: state.subscriptionsList[i].toLocalString,
				paymentStateId: state.subscriptionsList[i].paymentStateId,
				platformPurchadeId: state.subscriptionsList[i].platformPurchadeId,
				storeId: state.subscriptionsList[i].storeId,
			});
		}

		return list;
	},
	getSubscriptionsStoreId(state,getters) {
		return (storeId) => {
			return getters.getSubscriptionsList.filter(item => item.storeId === storeId).length > 0;
		}
	},
	getShowEmailChecks(state,getters) {
		return (
			(!getters.getEmailStatus && getters.getSubscriptionsStoreId('virgo')) || 
			(getters.getFakeEmailStatus && 
				( getters.getSubscriptionsStoreId('switch') || getters.getSubscriptionsStoreId('mondia'))
			) 
		);
	}
}

const actions = {
	checkUserKey({commit, dispatch}, key) {
		return AuthService.verifyKey(key)
			.then((response) => {
				if (response.status == 200) {
					dispatch('setToken', response.data.token);

					return true;
				}
			}).catch((error) => {
				return error.response;
			});
	},

	checkUserSubscriptions({commit, dispatch}) {
		return userSubscriptionsInfo()
			.then((response) => {
				if (response.status === 200) {
					commit('setAccountEmail', response.data.email);
					commit('setAccountName', response.data.name);
					commit('setEmailStatus', response.data.emailIsConfirmed);
					commit('setEmailFakeStatus', response.data.isEmailFake);
					commit('setShowEmailModal', response.data.isEmailFake);

					if (response.data.subscriptions.length > 0) {
						return dispatch('checkSubscriptionsForPremium', response.data.subscriptions)
							.then((list) => {
								commit('setUserSubsbscriptionList', list);
								return true;
							});
					} else {
						commit('setUserSubsbscriptionList', []);
						return false;
					}
				}
			}).catch((error) => {
				return error.response;
			});
	},

	checkSubscriptionsForPremium({commit}, subscriptions) {
		const subscriptionsList = subscriptions.filter((s) => !s.isExpired);
		commit('setUserPremium', !!subscriptionsList.length);
		return subscriptionsList;
	},

	loginUser({commit, dispatch}, data) {
		return AuthService.login(data)
			.then((response) => {
				return dispatch('setToken', response.data.token)
					.then((res) => {return res});
			}).catch((error) => {
				return error.response;
			});
	},

	registerUser({ getters, dispatch }, data) {
		const partnerDetails = getters.getPartnerDetails;

		if (partnerDetails) {
			data.externalPartner = partnerDetails;
		}

		return AuthService.register(data)
			.then((response) => {
			
				const { userCoupon, hasSubscription } = response.data;
				if (userCoupon) {
					localStorage.setItem('coupon', userCoupon.couponSerialNumber);
					localStorage.setItem('couponDurationCode', userCoupon.couponDurationCode);
					localStorage.setItem('couponProduct', userCoupon.couponProduct);
					if(userCoupon.discountDetails){
						localStorage.setItem('discountDetails',JSON.stringify(userCoupon.discountDetails))
					}
				}

				let type = false;


				if (userCoupon && userCoupon.couponType) {
					type = userCoupon.couponType === 'FREE_ACCESS_COUPON' || userCoupon.couponType === 'UNIQUE_ACCESS_COUPON'
						? true
						: false;
				}

				if (hasSubscription) {
					type = true
				}

				return dispatch('setToken', response.data.token)
					.then((res) => {
						return {
							status: res,
							freeCampaign: type,
						}
					});
			})
			.catch((error) => error.response);
	},

	setToken({commit}, token) {
		localStorage.setItem(storage_token_name, token);
		commit('setUserToken', token);
		return true;
	},

	removeToken({commit}) {
		localStorage.removeItem(storage_token_name);
		commit('removeUserToken');
		return true;
	},

	setPartnerData({ commit }, data) {
		commit('setPartnerData', data);
		return true;
	},

	logout({commit}) {
		localStorage.removeItem(storage_token_name);
		commit('removeUserToken');
		commit('setUserPremium', false);
		commit('removePartnerData');
		commit('resetSubscriptionList');
		commit('changeEmail', '');
		commit('changeName', '');
		commit('setShowEmailModal', true);
		return true;
	},

	removePremium({commit}) {
		commit('setUserPremium', false);
	},

	resetSubscriptionList({commit}) {
		commit('resetSubscriptionList');
	},

	changeEmail({commit}, email) {
		commit('changeEmail', email);
	},

	changeName({commit}, name) {
		commit('changeName', name);
	},

	setEmailStatus({commit}, val) {
		commit('setEmailStatus', val);
	},
	setEmailFakeStatus({commit}, val) {
		commit('setEmailFakeStatus', val);
	},
	setShowEmailModal({commit}, val) {
		commit('setShowEmailModal', val);
	},
	// setIsPromoAccepted({commit}, val) {
	// 	commit('setIsPromoAccepted', val);
	// }
}

const mutations = {
	changeEmail(state, email) {
		state.email = email;
	},

	changeName(state, name) {
		state.name = name;
	},

	setUserToken(state, token) {
		state.token = token;
	},

	removeUserToken(state) {
		state.token = false;
	},

	setAccountEmail(state, email) {
		state.email = email;
	},

	setEmailStatus(state, confirmed) {
		state.isEmailConfirmed = confirmed;
	},
	setEmailFakeStatus(state, confirmed) {
		state.isEmailFake = confirmed;
	},

	setAccountName(state, name) {
		state.name = name;
	},

	setUserPremium(state, payload) {
		state.hasPremium = payload;
	},

	setUserSubsbscriptionList(state, list) {
		state.subscriptionsList = list;
	},

	resetSubscriptionList(state) {
		state.subscriptionsList = [];
	},

	setPartnerData(state, details) {
		state.partner = details;
	},

	removePartnerData() {
		state.partner = null;
	},
	setShowEmailModal(state, val) {
		state.showEmailModal = val
	},
	// setIsPromoAccepted(state, val) {
	// 	state.isPromoAccepted = val;
	// }
}

export default {
	state,
	getters,
	actions,
	mutations
}
