var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
			'app',
			{
				'full-width': _vm.isWhiteBackgroundSet,
				'with-background': !_vm.isWhiteBackgroundSet && !_vm.hasNoLayout,
				'with-no-overflow': _vm.isRegisterOrLogin,
			}
		],attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.displayEmailModal && _vm.showEmailModal)?_c('update-email-overlay',{on:{"toggleModalDisplay":_vm.toggleModalDisplay}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showFlash)?_c('flash-message',{attrs:{"message":_vm.message,"type":_vm.messageType}}):_vm._e()],1),(_vm.loading)?_c('loading-screen'):_vm._e(),(!_vm.hasNoLayout)?_c('navigation-header'):_vm._e(),_c('div',{class:{
				'tv-platfrom': _vm.tvPlatform,
				'container': !_vm.hasNoLayout,
			}},[_c('div',{class:!_vm.hasNoLayout ? 'content' : ''},[(_vm.initialized)?_c('router-view'):_vm._e()],1)]),(!_vm.tvPlatform && !_vm.hasNoLayout)?_c('footer',{class:{
					'light': !_vm.isWhiteBackgroundSet,
					'dark': _vm.isWhiteBackgroundSet,
					'login-register-footer': _vm.isRegisterOrLogin
				},attrs:{"id":"footer"}},[_c('div',{staticClass:"separator"}),_c('div',{staticClass:"footer-content"},[_c('div',{staticClass:"footer-heading"},[_c('a',{attrs:{"href":_vm.websiteUrl + '/contact-us'}},[_vm._v(_vm._s(_vm.$t('footer_section_have_questions'))+" "+_vm._s(_vm.$t('footer_section_contact_us')))])]),_c('div',{staticClass:"footer-links"},[_c('a',{staticClass:"link",attrs:{"href":_vm.websiteUrl + '/faq'}},[_vm._v(_vm._s(_vm.$t('footer_section_help_link')))]),_c('a',{staticClass:"link",attrs:{"href":_vm.websiteUrl + '/terms'}},[_vm._v(_vm._s(_vm.$t('footer_section_legal_notice_link')))]),_c('a',{staticClass:"link",attrs:{"href":_vm.websiteUrl + '/privacy'}},[_vm._v(_vm._s(_vm.$t('footer_section_privacy_link')))]),_c('a',{staticClass:"link",attrs:{"href":_vm.websiteUrl + '/cookies'}},[_vm._v(_vm._s(_vm.$t('footer_section_gscs_link')))])])])]):_vm._e(),_c('cookie-consent',{attrs:{"href":_vm.websiteUrl + '/privacy',"message":_vm.$t('cmp_text'),"linkLabel":_vm.$t('cmp_more'),"cookieExpiryDays":_vm.expire}},[_c('template',{slot:"button"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"cookie-consent-compliance deny",attrs:{"type":"submit","tabindex":"1"},on:{"click":function($event){_vm.expire = 0.01}}},[_vm._v(_vm._s(_vm.$t('cmp_deny')))]),_c('button',{staticClass:"cookie-consent-compliance",attrs:{"type":"submit","tabindex":"1"}},[_vm._v(_vm._s(_vm.$t('cmp_allow')))])])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }