import Vue from 'vue';
import Vuex from 'vuex';
import initialState from '@/store/initialState';
import legacy from '@/store/modules/legacy';
import publisher from '@/store/modules/publisher';
import plans from "@/store/modules/plans";
import licenses from "@/store/modules/promotionalContent";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        legacy,
        publisher: publisher(initialState.publisher),
        plans,
        licenses
    },
    plugins: [createPersistedState({
        paths: ['legacy.showEmailModal'],
      })]
});
