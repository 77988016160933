<template>
	<div class="one-row" id="main-content">
		<div class="box qr-box" v-if="!displayForm">
			<span class="box-title">{{ $t('qr_code_box_title') }}</span>

			<img class="qrcode" src="../assets/images/qrcode-login.png" v-if="$parent.showLoginQR">
			<img class="qrcode" src="../assets/images/qrcode.png" v-else>

			<span class="box-subtitle">https://account.kidjo.tv/ {{ $parent.showLoginQR ? "login" : "register" }}</span>

			<button class="green-button submit-button animated-button qr-button" @click="displayForm = true">
				<span>{{ $t('register_page_form_login_link') }}</span>
			</button>
		</div>

		<div class="box form-box" v-if="displayForm">
			<span class="box-title">{{ $t('login_page_box_title') }}</span>
			<span class="box-subtitle">{{ $t('login_page_box_subtitle') }}</span>
			<form class="form" @submit.prevent="handleSubmit">
				<input class="form-item"
					type="text"
					name="email"
					:placeholder="$t('login_page_box_form_email')"
					v-model="email"
					:class="(email_error ? 'has-error': '')"
					required
					>

				<input class="form-item"
					type="password"
					name="password"
					:placeholder="$t('login_page_box_form_password')"
					v-model="password"
					:class="(password_error ? 'has-error': '')"
					required
					>
				<button class="green-button submit-button animated-button" type="submit" :disabled="loading">
					<span class="loading" v-if="loading"></span>
					<span v-else>{{ $t('login_page_box_form_submit') }}</span>
				</button>
				<router-link class="forgot-password" to="/forgotten-password" v-if="!$parent.tvPlatform">{{ $t('login_page_box_form_forgotten_password_link') }}</router-link>
				<span class="form-small-text" v-if="!$parent.tvPlatform">
					{{ $t('login_page_box_form_no_account_text') }} <br>
					<router-link to="/register" class="btn">{{ $t('login_page_box_form_create_account') }}</router-link>
				</span>

				<a href="" class="form-small-text qr-link" @click.prevent="displayForm = false" v-if="$parent.tvPlatform">{{ $t('qr_code_go_to_code') }}</a>
			</form>

		</div>
	</div>

</template>
<script>
	import AuthService from '../services/auth.service';
	import PasswordService from '../services/password.service';

	export default {
    data() {
			return {
				email: '',
				password: '',
				email_error: false,
				password_error: false,
				loading: false,
				displayForm: true
			}
		},

		computed: {
			isDisabled: function() {
				var email, password;

				if (this.email !== '') {
					email = true;
				}

				if (this.password !== '') {
					password = true;
				}

				if (email && password) {
					return true;
				}
				return false;
			}
		},

		methods: {
			handleSubmit: async function() {
				this.loading = true;
				var has_error = this.checkFields();

				if(!has_error) {
					var hesh = PasswordService.SHA256(this.password);
					var data = {'email': this.email, 'password': hesh, 'countryCode': this.$parent.countryCode};

					const logged = await this.$store.dispatch('loginUser', data);

					if (logged !== true) {
						this.loading = false
						return this.handleError(logged);
					}

					this.$parent.hasPremium = await this.$store.dispatch('checkUserSubscriptions');
					if (this.$parent.hasPremium !== true) {
						if (this.$parent.hasPremium.status === 404) {
							this.$parent.showFlashMessage(this.$t('profile_page_error_finding_subscription'));
						}

						if (this.$parent.hasPremium.status === 400) {
							this.$parent.showFlashMessage(this.$t('error_message_something_went_wrong'));
						}
					}
					this.$parent.checkPremium();
					this.loading = false;

				}
			},

			handleError: function(response) {
				if(response.status == 404) {
					this.$parent.showFlashMessage(this.$t('error_message_user_not_found'));
				}

				if (response.status == 400 && response.data.code == 1) {
					this.$parent.showFlashMessage(this.$t('error_message_wrong_password'));
				}
			},

			checkFields: function() {
				var has_error = false;

				if (this.email === '') {
					this.email_error = true;
					has_error = true;
				}

				if (this.password === '') {
					this.password_error = true;
					has_error = true;
				}

				return has_error;
			},
			routeBackToWebApp: function(appName) {
				const actionMap = {
					['games']: process.env.VUE_APP_GAMES_APP_URL,
					['stories']: process.env.VUE_APP_STORIES_APP_URL,
					['tv']: process.env.VUE_APP_WEB_APP_URL,
					['kidjo-world']: process.env.VUE_APP_WEB_APP_LINK
				};
				return actionMap[appName];		
			}
		},

		mounted: function() {
			if (this.$store.getters.getToken !== false) {
				if (this.$store.getters.getPremium === false) {
					
						this.$router.push({name: 'subscribe'}).catch(()=>{});
					
				} else {
					if(localStorage.getItem('app')) { 
						var appName = localStorage.getItem('app')
						localStorage.removeItem('app')
						AuthService.generateRedirectKey()
						.then((response) => {
							if (response.status === 200) {
								this.$parent.loading = false;
								window.location.replace(this.routeBackToWebApp(appName) + '?key='+response.data.redirectKey);
							}
						}).catch((error) => {
							this.$parent.loading = false;
						});
					} else {
						this.$router.push({name: 'home'}).catch(()=>{});
					}
				}
			}

			// If coming from tv platform, show QR first
			if (this.$parent.tvPlatform) {
				this.displayForm = false;
			}

			if (this.$parent.appStyle == 'subscribe') {
				this.$parent.changeAppStyle('profile');
			}
		}
	}
</script>
