<template>
	<div class="one-row" id="main-content">
        <loading-screen v-if="loading"></loading-screen>
    </div>

</template>
<script>	
import LoadingScreen from '../components/LoadingScreen.vue';
export default {
    data() {
		return {
			loading: false,
		}
    },
	components: {
		"loading-screen": LoadingScreen,
	},
	methods: {
		clearLocalStorage: function() {
			localStorage.removeItem('coupon');
			localStorage.removeItem('couponDurationCode');
		},
		handleLogout: async function() {
			localStorage.removeItem('storeId')
			await this.$store.dispatch('logout');
			this.clearLocalStorage();
			this.$router.replace({name:'login'}).then(() => {
				this.loading = false
			}).catch((e)=>{
				this.loading = false
				this.$parent.showFlashMessage(this.$t('error_message_something_went_wrong'));
				this.$router.replace({name: 'login'}).catch(()=>{});
			});
		}
	},

	mounted: function() {
		if (this.$store.getters.getToken !== false || localStorage.getItem('kidj_ut')) {
			this.loading = true
			this.handleLogout()
		} else if (this.$store.getters.getToken === false || !localStorage.getItem('kidj_ut')) {
			this.loading = false
          this.$router.push({name: 'login'});
        }
	}
}
</script>
