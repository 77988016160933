<template>
  <AppCard
    :graphicBgColor="graphicBgColor"
    :title="$t('hub_apps_step_title_stories')"
    :description="$t('hub_apps_step_lead_stories')"
  >
    <template v-slot:graphic>
      <StoriesAppGraphic />
    </template>

    <template v-slot:actions>
      <template v-if="isSubscribed">
        <a
          :href="appStoreList.apple"
          class="hub__btn hub__btn--icon-only btn--animated"
        >
          <AppleIcon />
        </a>

        <a
          :href="appStoreList.google"
          class="hub__btn hub__btn--icon-only btn--animated"
        >
          <AndroidIcon />
        </a>
      </template>

      <a
        v-else
        href="/subscribe"
        class="hub__btn hub__btn--subscribe  tw-bg-green"
      >
        <span>{{ $t("hub_apps_step_sub_btn") }}</span>
      </a>
    </template>
  </AppCard>
</template>

<script>
import AppCard from "./AppCard.vue";
import StoriesAppGraphic from "../graphics/StoriesAppGraphic.vue";
import AppleIcon from "../graphics/AppleIcon.vue";
import AndroidIcon from "../graphics/AndroidIcon.vue";

export default {
  name: "StoriesAppCard",
  components: {
    AppCard,
    StoriesAppGraphic,
    AppleIcon,
    AndroidIcon,
  },
  props: {
    isSubscribed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appStoreList: {
        apple:
          "https://apps.apple.com/us/app/kidjo-stories-for-kids/id1538396684",
        google:
          "https://play.google.com/store/apps/details?id=net.kidjo.books.android.playstore",
      },
      graphicBgColor: {
        from: "gradientLightOrange",
        via: "gradientDarkOrange",
        to: "gradientDarkOrange",
      },
    };
  },
};
</script>
