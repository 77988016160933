import {getPlans} from "../../services/plans.service";
import {subscribeToKidjo} from "../../services/subscriptions.v2.services";

const state = {
    userPlan: [],
    selectedTypePlans: [],
    currentPlanType: '',
    currentSelectedPlan: {},
    currentSelectedPlans: [],
    changePlan: {}
}

const getters = {
    getUserPlans(state) {
        return state.userPlan
    },
    getSelectedPlans(state) {
        return state.selectedTypePlans
    },
    getCurrentPlanType(state) {
        return state.currentPlanType
    },
    getCurrentSelectedPlan(state) {
        return state.currentSelectedPlan
    },
    getCurrentSelectedPlans(state) {
        return state.currentSelectedPlans
    },

    getShowCurrentPlanSelected(state) {
        return state.changePlan.showAll
    }
}

const actions = {
    async getUserEligiblePlans({commit}, data) {
        await getPlans({showAll: data.showAll, countryCode: data.countryCode}).then(response => {
            commit('setShowCurrentPlan', {showAll: data.showAll})
            commit('setPlans', {userPlan: response.data})
            commit('setCurrentPlanType', 'monthly_plan')
            commit('setSelectedPlans', response.data.find(plan => plan.nameKey === 'monthly_plan').plans);

            const discounts = JSON.parse(localStorage.getItem("discountDetails"));

            // All selected plans
            if (data.showAll) {
                response.data
                    .find(plan => plan.nameKey === 'monthly_plan')
                    .plans
                    .filter(p => p.subscribed)
                    .forEach((plan) => {
                        commit('setCurrentSelectedPlans', plan)
                    });
                commit('setCurrentSelectedPlan', response.data.find(plan => plan.nameKey === 'monthly_plan').plans[0])
            } else if (discounts != null && discounts.isBundleDiscount) {
                commit('setCurrentSelectedPlans', response.data.find(plan => plan.nameKey === 'monthly_plan').plans[0])
                commit('setCurrentSelectedPlans', response.data.find(plan => plan.nameKey === 'monthly_plan').plans[1])
                commit('setCurrentSelectedPlan', response.data.find(plan => plan.nameKey === 'monthly_plan').plans[0])
            } else {
                commit('setCurrentSelectedPlans', response.data.find(plan => plan.nameKey === 'monthly_plan').plans[0])
                // latest selected plan
                commit('setCurrentSelectedPlan', response.data.find(plan => plan.nameKey === 'monthly_plan').plans[0])

            }
        }).catch(error => {
            console.log(error);
        });
    },

    async subscribe({commit}, data) {
        return subscribeToKidjo(data);
    }
}

const mutations = {
    setPlans(state, allPlans) {
        state.userPlan = allPlans;
    },

    setSelectedPlans(state, plan) {
        state.selectedTypePlans = plan;
    },

    setCurrentPlanType(state, planType) {
        state.currentPlanType = planType;
    },

    resetCurrentSelectedPlans(state) {
        state.currentSelectedPlans = [];
    },
    removeCurrentSelectedPlan(state) {
        state.currentSelectedPlan = {}
    },
    removeFromSelectedPlan(state, plan) {
        const indexToRemove = state.currentSelectedPlans.findIndex(current => current.product === plan.product);
        state.currentSelectedPlans.splice(indexToRemove, 1);
    },

    setCurrentSelectedPlan(state, plan) {
        state.currentSelectedPlan = plan;
    },
    setCurrentSelectedPlans(state, plans) {
        state.currentSelectedPlans.push(plans);
    },
    setShowCurrentPlan(state, plans) {
        state.changePlan = plans
    }


}

export default {
    state,
    getters,
    actions,
    mutations
};
