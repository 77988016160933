import {BRAINTREE_SUBSCRIPTION_URL, USER_SUBSCRIPTIONS, VALIDATE_COUPON} from "../utils/constant";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_API_ACCOUNT_API_BASE_URL;
const API_VERSION = process.env.VUE_APP_API_ACCOUNT_VERSION_V5

export const subscribeToKidjo = function (subscriptionRequest) {
    const config = {
        method: 'post',
        url: BASE_URL + API_VERSION + BRAINTREE_SUBSCRIPTION_URL,
        headers: {
            'Content-Type': 'application/json; Charset=UTF-8',
            'Authorization': "Bearer " + localStorage.getItem('kidj_ut')
        },
        data: subscriptionRequest,
    };

    return axios(config);
}


export const userSubscriptionsInfo = function () {
    const config = {
        method: 'get',
        url: BASE_URL + API_VERSION + USER_SUBSCRIPTIONS,
        headers: {
            'Content-Type': 'application/json; Charset=UTF-8',
            'Authorization': "Bearer " + localStorage.getItem('kidj_ut')
        },
    };
    return axios(config);
}

export const validateCoupon = function (couponCode) {
    const headers = () => {
        if (localStorage.getItem('kidj_ut') !== null) {
            return {
                'Content-Type': 'application/json; Charset=UTF-8',
                'Authorization': "Bearer " + localStorage.getItem('kidj_ut')
            }
        } else {
            return {
                'Content-Type': 'application/json; Charset=UTF-8',
            }
        }
    };
    const config = {
        method: 'get',
        url: `${BASE_URL}${API_VERSION}${VALIDATE_COUPON.replace(':couponCode', couponCode || localStorage.getItem('coupon'))}`,
        headers: headers(),
    }
    return axios(config);
}
