// Get plans
import default_plans from '../paymenthPlans/default-plans.js';
import eu_plans from '../paymenthPlans/eu-plans.js';
import swiss_plans from '../paymenthPlans/swiss-plans.js';
// Get Promo plans
import default_promo_plans from '../paymenthPlans/default-promo-plans.js';
import eu_promo_plans from '../paymenthPlans/eu-promo-plans.js';
import swiss_promo_plans from '../paymenthPlans/swiss-promo-plans.js';
// Get EU countries
import eu_cc from '../eu-cc.js';

// Get the country code (cc) from localStorage
const cc = (localStorage.getItem('cc') ? localStorage.getItem('cc') : false);
// Get all eu ccountry codes
const euCountiryCodes = eu_cc();

class PaymentPlansService {

    // Get plans based on country code
    getPlans() {
        // Get plans based on Country Code
        if (euCountiryCodes.includes(cc)) {
            return new eu_plans;
        } else if (cc === "CH") {
            return new swiss_plans;
        } else {
            return new default_plans;
        }
    }

    searchPlanById(id, durationCode) {
        let allPlans = [
            new default_plans,
            new default_promo_plans,
            new eu_plans,
            new eu_promo_plans,
            new swiss_plans,
            new swiss_promo_plans,
        ];

        // go true all plans instases
        for (let i = 0; i < allPlans.length; i++) {

            for (let x = 0; x < allPlans[i].length; x++) {

                if (allPlans[i][x].durationCode === durationCode) {

                    for (var y = 0; y < allPlans[i][x].plans.length; y++) {

                        if (allPlans[i][x].plans[y].planId === id) {
                            return allPlans[i][x].plans[y].product
                        }
                    }

                }
            }
        }
    }

    searchPlanByType(type) {
        if (type === 'kidjo_books') {
            return 'Kidjo Stories';
        } else if (type === 'kidjo_games') {
            return 'Kidjo Games'
        } else if (type === 'kidjo_tv_books') {
            return 'TV + Stories';
        } else if (type === 'kidjo_tv_books_games') {
            return "TV + Stories +Games"
        }else if(type === 'kidjo_tv_games'){
           return "Tv + Games"
        }else if(type === 'kidjo_books_games'){
            return  "Stories + Games"
        }else {
            return 'Kidjo TV';
        }
    }

    // Propouse second plan on promo price
    getPromoPlan(durationCode, key) {
        var promoPlans = '';
        if (euCountiryCodes.includes(cc)) {
            promoPlans = new eu_promo_plans;
        } else if (cc === "CH") {
            promoPlans = new swiss_promo_plans;
        } else {
            promoPlans = new default_promo_plans;
        }

        for (var i = 0; i < promoPlans.length; i++) {

            if (promoPlans[i].durationCode == durationCode) {

                for (var x = 0; x < promoPlans[i].plans.length; x++) {

                    if (promoPlans[i].plans[x].planType == key) {

                        return promoPlans[i].plans[x];
                    }
                }
            }
        }
    }

    findResubscribePLan(id, durationCode, type) {
        var plan = false;

        var allPlnas = [
            new default_plans,
            new default_promo_plans,
            new eu_plans,
            new eu_promo_plans,
            new swiss_plans,
            new swiss_promo_plans,
        ];


        // go true all plans instases
        for (let i = 0; i < allPlnas.length; i++) {

            for (let x = 0; x < allPlnas[i].length; x++) {

                for (let y = 0; y < allPlnas[i][x].plans.length; y++) {

                    if (allPlnas[i][x].plans[y].planId == id) {
                        plan = allPlnas[i][x].plans[y];
                    }
                }

            }
        }

        if (plan != false) {

            return plan;
        }

        // Search old plans and return corespondin new
        var oldPlans = '';

        if (euCountiryCodes.includes(cc)) {
            oldPlans = [
                new eu_plans,
                new eu_promo_plans
            ]
        } else if (cc == "CH") {
            oldPlans = [
                new swiss_plans,
                new swiss_promo_plans
            ]
        } else {
            oldPlans = [
                new default_plans,
                new default_promo_plans
            ]
        }

        for (let i = 0; i < oldPlans.length; i++) {

            for (let x = 0; x < oldPlans[i].length; x++) {

                if (oldPlans[i][x].durationCode == durationCode) {

                    for (let y = 0; y < oldPlans[i][x].plans.length; y++) {

                        if (oldPlans[i][x].plans[y].old == id) {

                            return oldPlans[i][x].plans[y];
                        }
                    }
                }
            }
        }
    }
}

export default new PaymentPlansService;
