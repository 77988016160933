import {getPromoCards} from "../../services/licensev2.service";

const IMAGES_URL=process.env.VUE_APP_IMAGES_CLOUD_FRONT_URL;

const state = {
    tvPromotionalContent: [],
    storiesPromotionalContent: [],
    gamesPromotionalContent: [],
}

const getters = {
    getTvPromotionalContent(state) {
        return state.tvPromotionalContent;
    },
    getStoriesPromotionalContent(state) {
        return state.storiesPromotionalContent;
    },
    getGamesPromotionalContent(state) {
        return state.gamesPromotionalContent;
    },
    getContents(state) {
        const result = [];
        const colour = [
            {card_color: 'blue'},
            {card_color: 'green'},
            {card_color: 'orange'},
            {card_color: 'red'},
            {card_color: 'yellow'},
            {card_color: 'purple'}
        ];
        let colourCounter = 0;
        const maxLength = Math.max(
            state.tvPromotionalContent.length,
            state.storiesPromotionalContent.length,
            state.gamesPromotionalContent.length);
        for (let i = 0; i < maxLength; i++) {
            if (i < state.tvPromotionalContent.length) {
                result.push({...state.tvPromotionalContent[i], ...colour[colourCounter++]});
            }
            if (i < state.storiesPromotionalContent.length) {
                let license = state.storiesPromotionalContent[i];
                result.push({...license,image:IMAGES_URL + license.image, ...colour[colourCounter++]});
            }
            if (i < state.gamesPromotionalContent.length) {
                let license=state.gamesPromotionalContent[i];
                result.push({...license,image:IMAGES_URL + license.image, ...colour[colourCounter++], type: "games"});
            }
            if (colourCounter >= colour.length) {
                colourCounter = 0;
            }
        }
        return result;
    }
}

const mutations = {
    setTvPromotionalContent(state, content) {
        state.tvPromotionalContent = content;
    },

    setStoriesPromotionalContent(state, content) {
        state.storiesPromotionalContent = content;
    },

    setGamesPromotionalContent(state, content) {
        state.gamesPromotionalContent = content;
    },
}

const actions = {
    async getPromotion({commit}, query) {
        return await getPromoCards(query.product, query.countryCode, query.lang)
            .then(response => {
                const {data} = response;
                if (query.product === "tv") {
                    commit("setTvPromotionalContent", data.licenses)
                } else if (query.product === "stories") {
                    commit("setStoriesPromotionalContent", data.licenses)
                } else {
                    commit("setGamesPromotionalContent", data.licenses)
                }
            })
    }
}


export default {
    state,
    getters,
    actions,
    mutations,
};
