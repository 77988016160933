<template>
  <div class="two-rows">
    <div class="half-row half-slider-row" id="slider">
      <div class="promotion-logos">
        <div class="logo-containers">
          <img class="logo" src="../assets/logo/TV.svg" alt="Kidjo TV">
          <span class="primary">TV</span>
        </div>
        <img class="plus" src="../assets/images/plus_1.svg">
        <div class="logo-containers">
          <img class="logo" src="../assets/logo/Stories.svg" alt="Kidjo Stories">
          <span class="primary">Stories</span>
        </div>
        <img class="plus" src="../assets/images/plus_1.svg">
        <div class="logo-containers">
          <img class="logo" src="../assets/logo/Games.svg" alt="Kidjo Games">
          <span class="primary">Games</span>
        </div>
      </div>
      <div class="promotion-box">
        <mini-slider :lang="$parent.lang" :countryCode="$parent.countryCode"  :border="4" :width="222" :height="256" class="carousel-3d-slider"></mini-slider>
        <div class="promotion-text">
          <span class="primary" v-html="$t('register_page_slider_section_big_text_v2')"></span>
          <span class="secondary">
						{{ $t('register_page_slider_section_small_text_v2') }}
					</span>
        </div>
        <div class="promotion-links">
          <div class="line-one">
            <a href="https://itunes.apple.com/us/app/kidjo-tv-for-kids/id1095795448" class="link"><img
                src="../assets/images/apple.png" alt="Apple"></a>
            <a href="https://play.google.com/store/apps/details?id=net.kidjo.app.android" class="link"><img
                src="../assets/images/google-play.png" alt="Google play"></a>
            <a href="https://www.amazon.com/Kidjo-Kids-Have-Fun-Learn/dp/B06X99VRWG" class="link amazon"><img
                src="../assets/images/amazon.png" alt="Amazon"></a>
          </div>
          <div class="line-two">
            <a href="samsungapps://ProductDetail/net.kidjo.app.samsung" class="link"><img
                src="../assets/images/galaxy.png" alt="Samsung store"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="half-row" id="form">
      <div class="box form-box form-box-register">
        <span class="box-title line-height-lg">{{ $t('register_page_box_title') }}</span>
        <span v-if="!isOrangeRegister" class="box-subtitle regular text-md">
					{{ subTitle }}
				</span>
        <form class="form" @submit.prevent="handleSubmit">
          <input class="form-item"
                 type="text"
                 name="name"
                 :placeholder="$t('register_page_form_name')"
                 v-model="name"
                 :class="(name_error ? 'has-error' : '')"
                 required>
          <input class="form-item"
                 type="text"
                 name="email"
                 :placeholder="$t('register_page_form_email')"
                 v-model="email"
                 :class="(email_error ? 'has-error' : '')"
                 required>
          <input class="form-item"
                 type="password"
                 name="password"
                 :placeholder="$t('register_page_form_password')"
                 v-model="password"
                 :class="(password_error ? 'has-error' : '')"
                 required>
          <div class="input-with-icon" v-if="!swissComeCampaign && !disablePromoBox">
            <img src="../assets/images/ic_tag.png" class="input-tag">
            <input class="form-item with-tag" type="text" name="coupon" :placeholder="$t('register_page_form_coupon')"
                   :disabled="disablePromoBox"
                   v-model="coupon">
          </div>

          <div class="check-box-wrapper">
            <input type="checkbox" v-model="isPromoAccepted">
            <label>{{ $t('accept_promotional_content') }}</label>
          </div>

          <div class="check-box-wrapper">
            <input type="checkbox" required v-model="isTcAccepted">
            <label v-html="$t('accept_terms', {link:'https://www.kidjo.tv/terms'})"></label>
          </div>
          
          <button class="green-button submit-button animated-button" type="submit" :disabled="loading">
            <span class="loading" v-if="loading"></span>
            <span v-else>{{ $t('register_page_form_submit') }}</span>
          </button>
          <span class="form-small-text">
						{{ $t('register_page_form_have_an_accout_text') }} <br>
						<router-link to="/login" class="btn">{{ $t('register_page_form_login_link') }}</router-link>
					</span>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordService from '../services/password.service';
import partnerOrangeMixin from '@/mixins/orange';

// Get EU countries
import eu_cc from '../eu-cc.js';
import {validateCoupon} from "../services/subscriptions.v2.services";
// import Carousel from "../components/Carousel.vue";
import MiniSlider from "../components/MiniSlider.vue";
import { getErrorString } from '../utils/constant.js';


export default {
  components: {'mini-slider' : MiniSlider},
  mixins: [partnerOrangeMixin],
  data() {
    return {
      name: '',
      name_error: false,
      email: '',
      email_error: false,
      password: '',
      password_error: false,
      coupon: '',
      isPromoAccepted: false,
      isTcAccepted: false,
      loading: false,
      swissComeCampaign: false,
      disablePromoBox: false,
      discountPromoApplied: false,
      couponDurationCode: '',
      discountPrice: '',
    }
  },

  computed: {
    isDisabled: function () {
      var name, email, password;
      if (this.name !== '') {
        name = true;
      }
      if (this.email !== '') {
        email = true;
      }
      if (this.password !== '') {
        password = true;
      }
      if (name && email && password) {
        return true;
      }
      return false;
    },

    subTitle: function () {
      let euCountiryCodes = eu_cc();
      let price = '';
      let cc = (localStorage.getItem('cc') ? localStorage.getItem('cc') : false);


      if (euCountiryCodes.includes(cc)) {
        price = "4.99€";
      } else if (cc === "CH") {
        price = "6.90CHF";
      } else {
        price = "4.99$";
      }

      let discounts = ''
      if (this.discountPrice !== '' && this.discountPrice !== 0.00) {
        if (euCountiryCodes.includes(cc)) {
          discounts = `${this.discountPrice}€`;
        } else if (cc === "CH") {
          discounts = `${this.discountPrice}CHF`;
        } else {
          discounts = `${this.discountPrice}$`;
        }
      }


      if (this.swissComeCampaign) {
        return this.$t('register_page_box_swisscom_subtitle', {bonus: '2', price: price});
      } else if (this.discountPromoApplied) {
        const {duration, durationPeriods, durationPeriod} = this.getCouponDuration();
        return this.$t('promo_code_message', {
          durationCode: durationPeriod,
          duration: `${duration} ${durationPeriods}`,
          price: discounts
        })

      } else {
        return this.$t('register_page_box_subtitle', {price: price});
      }
    }

  },

  methods: {
    handleSubmit: async function () {
      this.loading = true;
      var has_error = this.checkFields();

      var coupon = (this.swissComeCampaign ? 'YAJKM0' : this.coupon);
      if (!has_error) {
        var hesh = PasswordService.SHA256(this.password);
        var data = {
          'email': this.email,
          'password': hesh,
          'displayName': this.name,
          'coupon': coupon,
          'isPromoAccepted' : this.isPromoAccepted,
          'isTcAccepted' : this.isTcAccepted,
          'env': "web",
          'countryCode': this.$parent.countryCode
        };
        const registered = await this.$store.dispatch('registerUser', data);
        this.loading = false;
        if (registered.status !== true) {
          return this.handleError(registered);
        }
        if (registered.freeCampaign === true) {
          this.removeCouponFromStorage();
          return this.$router.push('/products').catch(() => {
          });
        }
        this.$router.push('/subscribe').catch(() => {
        });
      }
    },

    handleError: function (response) {
      return this.$parent.showFlashMessage(this.$t(getErrorString(response.status, response?.data?.code)));
    },

    checkFields: function () {
      var has_error = false;

      if (this.name === '') {
        this.name_error = true;
        has_error = true;
      }

      if (this.email === '') {
        this.email_error = true;
        has_error = true;
      }

      if (this.password === '') {
        this.password_error = true;
        has_error = true;
      }

      return has_error;
    },

    setMobileLayout() {
      var slider = document.getElementById('slider');
      var form = document.getElementById('form');

      form.style.display = 'block';
      slider.style.display = 'none';

      if (window.innerWidth <= 340) {
        window.scrollTo(0, 30);
      }
    },

    removeCouponFromStorage: function () {
      localStorage.removeItem('coupon');
      localStorage.removeItem('couponDurationCode');
    },

    checkForSwisscom: function () {
      if (this.$route.name === 'swisscom') {
        this.swissComeCampaign = true;
      } else {
        localStorage.removeItem('swissComeCampaign');
      }
    },

    getCouponDuration: function () {
      const duration_period = {
        day: this.$t('label_day'),
        days: this.$t('label_days'),
        month: this.$t('month'),
        months: this.$t('label_months'),
      };

      const value = this.couponDurationCode;

      const duration = value.replace(/\D/g, '');
      let suffix = 'month';

      if (value.includes('D')) {
        suffix = 'day';
      }

      if (duration !== '1') {
        suffix += 's';
      }

      return {
        duration,
        "durationPeriods": duration_period[suffix],
        "durationPeriod": duration_period[suffix.slice(0, -1)]
      };
    }
  },

  mounted: function () {

    //---I want this code to store isPromoAccepted value from API response, don't delete this---//

    // this.$store.dispatch('setIsPromoAccepted', 'hello')
    // this.promo = this.$store.getters.getIsPromoAccepted;
    // console.log('Promo value: ',this.promo);
    // this.$store.dispatch('setIsPromoAccepted', false)

    // this.promo = this.$store.getters.getIsPromoAccepted;
    // console.log('Promo value: ',this.promo);

    // Promo by link
    if (this.$route.params['code'] != null) {
      this.coupon = this.$route.params['code'].toUpperCase();
      this.disablePromoBox = true;
      validateCoupon(this.coupon).then((response) => {
        const couponDetails = response.data;
        localStorage.setItem("coupon", this.coupon);
        this.couponDurationCode = couponDetails?.couponDurationCode
        localStorage.setItem("couponDurationCode", this.couponDurationCode);
        localStorage.setItem("discountDetails", JSON.stringify(couponDetails?.discountDetails));
        this.discountPromoApplied = true;
        this.discountPrice = couponDetails?.discountDetails.discountPrice;
      }).catch((error) => {
        this.$parent.showFlashMessage(this.$t('error_message_coupon_invalid'));
        this.coupon = '';
        this.$router.push({name: 'register'});
      })
    }
    // If the user is from vewl platform go to login
    if (this.$parent.tvPlatform === true) {
      this.$router.push({name: 'login'}).catch(() => {
      });
    }

    if (this.$store.getters.getToken !== false) {
      if (this.$store.getters.getPremium === false) {
        this.$router.push({name: 'subscribe'}).catch(() => {
        });
      } else {
        this.$router.push({name: 'home'}).catch(() => {
        });
      }
    }

    if (this.$parent.appStyle === 'subscribe') {
      this.$parent.changeAppStyle('profile');
    }

    // Swisscome campaign method
    this.checkForSwisscom();

    if (window.innerWidth <= 1024) {
      this.setMobileLayout();

      setTimeout(function () {
        window.scrollTo(0, 120);
      }, 1000)
    }
  },
}
</script>

<style lang="sass">
.slide-fade-enter-active
  transition: all .3s ease

.slide-fade-leave-active
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(10px)
  opacity: 0
.loader
    border: 7px solid #ffac01
    border-left-color: #ffffff
    animation: spin 1s linear infinite
    width: 37px
    height: 37px
    border-radius: 50%
    text-align: center
.loader-container
    display: flex
    justify-content: center
    align-items: center
    position: relative
.loader-section
    top: 50%
    left: 50%
    margin: 15%
    @keyframes spin
      0%
        transform: rotate(0deg)
      100%
        transform: rotate(360deg)
</style>
