<template>
  <div class="popup-form-cover">
    <div class="popup-form">
      <button :disable="loading" class="close" @click="close"><img src="../assets/images/close.png"></button>
      <span class="title">{{ getTitle() }}</span>
      <span class="sub-title" v-if="!showForm">{{ getSuccessTitle() }}</span>
      <span class="sub-title error" v-if="showError">{{ $t('popup_form_change_email_error_email_is_taken') }}</span>
      <form @submit.prevent="save" v-if="showForm">
        <input type="text" v-model="newValue" class="change-input" id="form-input" v-if="type == 'name'" required>
        <input type="email" v-model="newValue" class="change-input" id="form-input" v-if="type == 'email'" required>
        <button :disable="loading" class="save" type="submit">{{ $t('popup_form_save_button') }}</button>
      </form>
    </div>
  </div>
</template>
<script>
import AuthService from '../services/auth.service';

export default {
  props: ['value', 'type'],

  data: function () {
    return {
      newValue: "",
      loading: false,
      showForm: true,
      showError: false
    }
  },

  methods: {
    close: function () {
      this.$emit("closePopUp");
    },

    getTitle: function () {
      if (this.type == 'email') {
        return this.$t('popup_form_change_email_title');
      } else {
        return this.$t('popup_form_change_name_title');
      }
    },

    save: function () {
      this.loading = true;
      var hasError = this.checkForm();

      if (!hasError) {
        AuthService.changeProfileInfo(this.type, this.newValue)
            .then((response) => {
              this.changeStore();
              this.showError = false;
              this.showForm = false;
              this.loading = false;

              /* Close the form */
              setTimeout(() => {
                this.close();
              }, 1000);

            }).catch((error) => {
          if (error.response.data.code == 5) {
            this.showError = true;
          }

          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },

    checkForm: function () {
      var hasError = false;

      if (this.newValue == '') {
        hasError = true;
      }

      return hasError;
    },

    changeStore: function () {
      if (this.type == 'email') {
        this.$store.dispatch('changeEmail', this.newValue);
      } else {
        this.$store.dispatch("changeName", this.newValue);
      }
    },

    getSuccessTitle: function () {
      if (this.type == 'email') {
        return this.$t('popup_form_change_email_success');
      } else {
        return this.$t('popup_form_change_name_success');
      }
    }
  },

  mounted: function () {
    this.newValue = this.value;

    // auto focus when created
    document.getElementById('form-input').focus();
  }
}
</script>
<style scoped lang="sass">
.popup-form-cover
  position: fixed
  top: 0
  bottom: 0
  right: 0
  left: 0
  background: rgba(0, 0, 0, 0.4)
  z-index: 1000

.popup-form
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  height: fit-content
  display: inline-block
  z-index: 1001
  border: 2px solid #6d4c41
  border-radius: 10px
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 1)
  background: url('../assets/backgrounds/blue-background.svg')
  background-size: cover
  background-position: center
  text-align: center
  padding: 60px 80px
  max-width: 400px
  background-color: #29B6F6

  @media (max-width: 768px)
    padding: 50px 20px
    width: 90%

  .close
    position: absolute
    top: 10px
    right: 10px
    outline: none
    background-color: #f00000
    padding: 5px
    border: 2px solid #6d4c41
    border-radius: 10px
    cursor: pointer

    &:hover, &:focus
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)
      transform: scale(1)
      animation: pulse_button 2s infinite

    img
      width: 20px
      height: 20px
      vertical-align: middle

  .change-input
    display: block
    width: 100%
    box-sizing: border-box
    border: 3px solid #6d4c41
    border-radius: 10px
    padding: 17px 25px
    margin: 10px 0
    font-family: "Nunito-Regular"
    font-size: 18px
    color: #6d4c41
    outline: none


  .title
    color: #ffffff
    font-family: "Nunito-Regular"
    font-size: 30px
    text-align: center
    display: block
    margin-bottom: 20px

    @media (max-width: 768px)
      font-size: 22px

    @media (max-width: 425px)
      font-size: 16px

  .sub-title
    color: #ffffff
    font-family: "Nunito-ExtraBold"
    font-size: 26px
    text-align: center
    display: block
    margin-bottom: 20px

    @media (max-width: 768px)
      font-size: 22px

    @media (max-width: 425px)
      font-size: 16px

    &.error
      font-size: 20px
      color: #f00000

  .save
    color: #ffffff
    font-family: "Nunito-Regular"
    font-size: 14px
    text-align: center
    outline: none
    border: 2px solid #ffffff
    border-radius: 10px
    background-color: #8bc34a
    padding: 10px
    width: 90%
    max-width: 150px
    cursor: pointer
    display: inline-block

    &:hover, &:focus
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)
      transform: scale(1)
      animation: pulse_button 2s infinite

@keyframes pulse_button
  0%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7)

  70%
    transform: scale(1)
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)

  100%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
</style>
