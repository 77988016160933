<template>
  <div class="flash-message-cover">
    <div class="flash-message" :class="type">
      <span class="message">{{ getMessage() }}</span>
      <button class="close" @click="close" id="close-btn">{{ $t('flash_message_close_button') }}</button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['message', 'type'],

  methods: {
    close: function () {
      this.$parent.hideFlashMessage();
    },
    getMessage: function () {
      return this.message.replace(/-/g, '‑');
    }
  },

  mounted: function () {
    // auto focus when created
    document.getElementById('close-btn').focus();
  }
}
</script>
<style scoped lang="sass">
.flash-message-cover
  position: fixed
  top: 0
  bottom: 0
  right: 0
  left: 0
  background: rgba(0, 0, 0, 0.4)
  z-index: 1000

.flash-message
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

  display: inline-block
  z-index: 1001
  border: 2px solid #6d4c41
  border-radius: 10px
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 1)
  background: url('../assets/backgrounds/blue-background.svg')
  background-size: cover
  background-position: center
  background-color: #29B6F6
  text-align: center
  padding: 60px 80px
  max-width: 400px

  @media (max-width: 600px)
    width: 200px
    padding: 50px 20px


  .message
    color: #ffffff
    font-family: 'BreeRegular'
    font-size: 30px
    text-align: center
    display: block
    margin-bottom: 20px

    @media (max-width: 768px)
      font-size: 22px

    @media (max-width: 425px)
      font-size: 16px

  .close
    color: #ffffff
    font-family: 'BreeRegular'
    font-size: 14px
    text-align: center
    outline: none
    border: 2px solid #ffffff
    border-radius: 10px
    background-color: #8bc34a
    padding: 10px
    width: 90%
    cursor: pointer

    &:hover, &:focus
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)
      transform: scale(1)
      animation: pulse_button 2s infinite

@keyframes pulse_button
  0%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7)

  70%
    transform: scale(1)
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)

  100%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
</style>
