<template>
  <div class="loader-container bg-transparent" v-if="showCarousal">
    <div class="loader-section loader"></div>
  </div>
  <div id="carousel3d" v-else>
    <client-only>
      <carousel-3d
        :space="70"
        :width="width"
        :height="height"
        :border="border"
        :perspective="0"
        :scaling="100"
        :animationSpeed="1000"
        :autoplay="true"
        :autoplayTimeout="1500"
        :count="licences.length"
      >
        <slide
          v-for="(item, index) in licences"
            :key="index"
            :index="index"
            :class="[
              `card-${item.card_color}`,
              item.type === 'games' ? 'games-slider' : '',
            ]"
          >
          <img :src="item.image ? item.image : ''" />
        </slide>
      </carousel-3d>
    </client-only>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {Carousel3d, Slide} from 'vue-carousel-3d';
export default {
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      showCarousal: true,
    }
  },
  props: {
    width: {
      type: Number,
      default: 272
    },
    height: {
      type: Number,
      default: 302
    },
    border: {
      type: Number,
      default: 4
    },
    language: {
      type: String,
      default: 'fr',
    },
    countryCode: {
      type: String,
      default: 'fr',
    },
    display:{
      type:Number,
      default:5
    }
  },
  
  computed: {
    ...mapGetters({
      licences: 'getContents',
    })
  },
  mounted() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      this.height = 182;
      this.width = 162;
      this.border = 2;
    }
    this.showCarousal = true
    Promise.resolve(this.$store.dispatch('getPromotion', {
      product: "tv",
      countryCode: this.countryCode,
      lang: this.language,
      count:20
    })).then(
        () =>  {
          Promise.resolve(this.$store.dispatch('getPromotion', {
            product: "stories",
            countryCode: this.countryCode,
            lang: this.language,
          })).then(() => {
            Promise.resolve(this.$store.dispatch('getPromotion', {
              product: "games",
              countryCode: this.countryCode,
              lang: this.language,
            })).then(() => {
               this.showCarousal = false;
            });
          })
    });
  }
};
</script>
<style scoped="true" lang="sass">
.card-blue
	background: radial-gradient(ellipse at center, #7bbfff 0, #0074e1 100%)

.card-green
	background: radial-gradient(ellipse at center, #6beb65 0, #1ba215 100%)

.card-orange
	background: radial-gradient(ellipse at center, #ffb182 0, #e85800 100%)

.card-purple
	background: radial-gradient(ellipse at center, #ed50c3 0, #950f71 100%)

.card-red
	background: radial-gradient(ellipse at center, #ff868a 0, #ec0008 100%)

.card-yellow
	background: radial-gradient(ellipse at center, #ffde9a 0, #ffac01 100%)

.carousel-3d-slide
	border-color: #fff !important
	border-radius: 20px !important
	padding: 40px
	cursor: pointer

	@media (max-width: 768px)
		padding: 10px

	img
		transform: scale(1.3)
		margin: 15px 0

	&.right-1
		transform: translateX(50px) translateZ(-150px) rotateY(0deg) translateY(0px) !important

	&.right-2
		transform: translateX(110px) translateZ(-370px) rotateY(0deg) translateY(0px) !important

	&.left-1
		transform: translateX(-50px) translateZ(-150px) rotateY(0deg) translateY(0px) !important

	&.left-2
		transform: translateX(-110px) translateZ(-370px) rotateY(0deg) translateY(0px) !important
.games-slider
	img
		transform: scale(1.8)
</style>
