import axios from "axios";
import {GET_PLANS_URL} from "../utils/constant";

const BASE_URL = process.env.VUE_APP_API_ACCOUNT_API_BASE_URL;
const API_VERSION = process.env.VUE_APP_API_ACCOUNT_VERSION_V5


export const getPlans = function (queryParams) {
    const config = {
        method: 'get',
        url: BASE_URL + API_VERSION + GET_PLANS_URL,
        headers: {
            'Content-Type': 'application/json; Charset=UTF-8',
            'Authorization': "Bearer " + localStorage.getItem('kidj_ut')
        },
        params:queryParams,
    };
    return axios(config);
}
